import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import App from './App';

import './styles/main.sass';

ReactDOM.render(<App />, document.getElementById('root'));

// handleSubmit
// handleInputChange
