import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ReleasesActions }  from '../../store/ducks/releases';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, InputGroup, InputGroupAddon, Input, CustomInput, Spinner } from 'reactstrap';

import CurrencyInput from 'react-currency-input';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import { Release, ReleaseTypeOptions, ReleasePaymentOptions, ReleaseAttachmentTypeOptions } from '../../class/release';

class CreateRelease extends Component {
   state = {
      release: new Release()
   }

   componentDidUpdate() {
      if(this.props.releases.loading.modal && this.props.status) {
         this.props.releasesModalUpdate();
         this.setState({ 
            release: new Release()
         });
         this.props.toogle();
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();

      this.props.releasesCreate(this.state.release);
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ 
         release: {
            ...this.state.release,
            [name]: value 
         }
      });
   }

   handleFileChange = (event) => {
      const { name } = event.target;
      this.setState({ 
         release: {
            ...this.state.release,
            [name]: event.target.files[0]
         }
      });
   }

   handleSelectChange = (name, event) => {
      this.setState({ 
         release: {
            ...this.state.release,
            [name]: event.value
         }
      });
   }

   handleDayPickerInputChange = (event) => {
      this.setState({ 
         release: {
            ...this.state.release,
            date: event
         }
      });
   }

   handleCurrencyChange = (name, event, maskedvalue, floatvalue) => {
      event.preventDefault();
      this.setState({ 
         release: {
            ...this.state.release,
            [name]: floatvalue
         }
      });
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">Lançar novo documento</h3>
               <p className="m-0">Preencha abaixo com os dados de seu lançamento.</p>
            </div>
            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  
                  <FormGroup>
                     <Input 
                        type="text"
                        name="title"
                        placeholder="Titulo"
                        value={this.state.release.title}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>

                  <FormGroup>
                     <Select 
                        options={ReleaseTypeOptions}
                        placeholder="Selecione" 
                        value={ReleaseTypeOptions.filter(event => (event.value === this.state.release.type))}
                        onChange={this.handleSelectChange.bind(this, 'type')} 
                     />
                  </FormGroup>

                  {this.state.release.type === 'contabil' && 
                     <div>
                        <FormGroup>
                           <DayPickerInput
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder="Data" 
                              value={this.state.release.date}
                              onDayChange={this.handleDayPickerInputChange}
                              dayPickerProps={{
                                 locale: 'pt-br',
                                 localeUtils: MomentLocaleUtils,
                              }}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <Select 
                              options={ReleaseAttachmentTypeOptions}
                              placeholder="Tipo de documento" 
                              value={ReleaseAttachmentTypeOptions.filter(event => (event.value === this.state.release.attachment_type))}
                              onChange={this.handleSelectChange.bind(this, 'attachment_type')} 
                           />
                        </FormGroup>
                     </div>
                  }

                  <FormGroup>
                     <CustomInput 
                        type="file" 
                        id="file" 
                        accept=".png, .jpeg, .jpg, .gif, .doc, .docx, .xlsx, .xls, .zip, .rar, .pdf, .ppt, .pptx, .csv, .txt"
                        name="attachment"
                        className={this.state.release.attachment ? 'active' : ''}
                        onChange={this.handleFileChange}
                        label={this.state.release.attachment ? this.state.release.attachment.name : 'Anexe um arquivo de seu computador'}
                     />
                  </FormGroup>

                  {this.state.release.type === 'contabil' && 
                     <div>
                        <FormGroup>
                           <Select 
                              options={ReleasePaymentOptions}
                              placeholder="Tipo de pagamento" 
                              value={ReleasePaymentOptions.filter(event => (event.value === this.state.release.payment))}
                              onChange={this.handleSelectChange.bind(this, 'payment')} 
                           />
                        </FormGroup>

                        <FormGroup>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">Valor</InputGroupAddon>
                              <CurrencyInput 
                                 className="form-control"
                                 locale="pt-BR"
                                 currency="EUR"
                                 decimalSeparator=","
                                 thousandSeparator="."
                                 value={this.state.release.value}
                                 onChangeEvent={this.handleCurrencyChange.bind(this, 'value')}
                              />
                           </InputGroup>
                        </FormGroup>
                     </div>
                  }

                  <FormGroup>
                     <Input type="textarea"
                        name="description"
                        rows="3"
                        placeholder="Descrição"
                        value={this.state.release.description}
                        onChange={this.handleInputChange}
                     />
                  </FormGroup>
               
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.props.toogle} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.props.releases.loading.create && <Spinner color="light" size="sm" className="mr-2" />}
                     Adicionar
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      )
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   releases: state.releases
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(ReleasesActions, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(CreateRelease);