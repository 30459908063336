import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AccountingEntriesActions }  from '../../store/ducks/accountingEntries';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { Button, Card, CardBody, CardHeader, Spinner, Row, Col, Alert, UncontrolledPopover, PopoverBody, Table } from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import AccountingEntry from '../../components/AccountingEntry';

class SystemAccountEntries extends Component {
   state = {
      start: moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      limit: 10
   }

   componentDidMount() {
      this.props.accountingEntriesFetchRequested(this.state.start, this.state.stop, this.props.userSelected.data.id, this.state.limit);
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      this.props.accountingEntriesFetchRequested(this.state.start, this.state.stop, this.props.userSelected.data.id, this.state.limit);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   loadMore() {
       let length = this.props.accountingEntries && this.props.accountingEntries.data && this.props.accountingEntries.data.length,
            limit = this.state.limit === length ? this.state.limit + 10 : this.state.limit;

       this.setState({
           limit: limit
       });

       this.props.accountingEntriesFetchRequested(this.state.start, this.state.stop, this.props.userSelected.data.id, limit);
   }

   render() {
      const { accountingEntries } = this.props;
      return (
         <Card className="panel">
            <CardBody>
                <Row className="mb-2">
                   <Col className="text-right">
                        <span className="pull-right" id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                        <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                           <PopoverBody>
                              <strong className="title mb-3 d-block">Selecione a competência</strong>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">De</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.start).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">A</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.stop).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <div className="text-center">
                                 <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                              </div>
                           </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                </Row>

               {!accountingEntries.loading &&
                 <Card className="panel-header">
                 <CardBody>
                    <Row >
                        <div  className="px-1 width-10"><strong>Grupo</strong></div>
                        <div  className="px-1 width-12"><strong>Conta</strong></div>
                        <div  className="px-1 width-17"><strong>Código Conta</strong></div>
                        <div  className="px-1 width-12"><strong>Contrap</strong></div>
                        <div  className="px-1 width-17"><strong>Código Contrap</strong></div>
                        <div  className="px-1 text-center table-small"><strong>Seq</strong></div>
                        <div  className="px-1 width-15 "><strong>Valor</strong></div>
                        <div  className="px-1 width-12"><strong>Data</strong></div>
                    </Row>
                    </CardBody>
                </Card>
               }
               {accountingEntries.loading &&
                   <div className="text-center py-5">
                       <Spinner style={{ width: '3rem', height: '3rem' }} />
                   </div>
               }

               {(accountingEntries.data.length === 0 && !accountingEntries.loading && !accountingEntries.error) &&
                   <Alert color="light" className="text-center my-5" >
                       Você ainda não lançou nem um documento.
                   </Alert>
               }

               {accountingEntries.error &&
                   <Alert color="light" className="text-center my-5" >
                       Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                   </Alert>
               }
               {!accountingEntries.loading && accountingEntries.data && accountingEntries.data.length
                   ? <div>{accountingEntries.data.map(item => <AccountingEntry {...this.props} key={item.id} data={item} />)}</div>
                   : undefined
                }
                {!accountingEntries.loading && accountingEntries.data && (this.state.limit === accountingEntries.data.length) && <div className="text-center mt-3"><Button onClick={this.loadMore.bind(this)} color="primary" outline>Carregar mais</Button></div>}
            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   accountingEntries: state.accountingEntries,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...AccountingEntriesActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemAccountEntries);
