import React from 'react';
import { Provider } from 'react-redux';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Routes from './routes';
import store from './store';

const App = () => (
   <div>
      <Provider store={store}>
         <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl pauseOnVisibilityChange draggable={false} pauseOnHover/>
         <Routes />
      </Provider>
   </div>
);

export default App;