import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as UserActions } from '../../store/ducks/user';

import { Container } from 'reactstrap';

import Header from '../../components/Header/Station';
import Footer from '../../components/Footer';

class Station extends Component {

   componentDidMount() {
      if(this.props.user.error) {
         this.props.userFetchRequested();
      }
   }

   render() {
      return (
         
         <div>
            <Header />
            <Container>
               {this.props.children}
            </Container>
            <Footer />
         </div>
      )
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   user: state.user
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(UserActions, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(Station);

