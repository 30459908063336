import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as releasesActions }  from '../../store/ducks/releases';

import { Alert, Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardFooter, CardHeader, CardTitle, Spinner, Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import Release from '../../components/Release';
import CreateRelease from '../../modals/CreateRelease';

class StationReleases extends Component {
   state = {
      start: moment().add(-1, 'month').format('YYYY-MM-DD'),
      stop: moment().add(+1, 'day').format('YYYY-MM-DD'),
      modal: false,
   }

   componentDidMount() {
      if(this.props.releases.data.length === 0) {
         this.props.releasesFetchRequested(this.state.start, this.state.stop);
      }
   }

   toggleModal = () => {
      this.setState({
         modal: !this.state.modal
      });
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      this.props.releasesFetchRequested(this.state.start, this.state.stop);
   }

   handlePageChange = (page) => {
      this.props.releasesPage(this.state.start, this.state.stop, page);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { releases } = this.props;
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Lançamentos</BreadcrumbItem>
            </Breadcrumb>
            <Card className="panel is-marge">
               <CardHeader>
                  <CardTitle className="heading">Lançamentos</CardTitle>
                  <p>Envie os documentos fiscais e contábeis para que possamos elaborar sua contabilidade. <br/> Notas fiscais, boleto, contas de luz, água, aluguel, internet, extrato bancário, entre outros.</p>

                  <Button color="primary" size="lg" onClick={this.toggleModal}>
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"></path></svg>
                     Lançar documentos
                  </Button>

                  <Row>
                     <Col>
                        <div className="header-content">
                           Documentos enviados
                        </div>
                     </Col>
                     <Col xs="auto">
                        <div className="header-content">
                           <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                           <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                              <PopoverBody>
                                 <strong className="title mb-3 d-block">Selecione a competência</strong>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">De</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.start).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">A</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.stop).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <div className="text-center">
                                    <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                                 </div>
                              </PopoverBody>
                           </UncontrolledPopover>
                        </div>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
                  {releases.loading.request &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(releases.data.length === 0 && !releases.loading.request && !releases.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Você ainda não lançou nem um documento.
                     </Alert>
                  }

                  {releases.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {!releases.loading.request && releases.data.map(release => <Release key={release.id} {...release} />)}

               </CardBody>
               <CardFooter>
                  <Pagination
                     onChange={this.handlePageChange}
                     current={releases.current_page}
                     pageSize={releases.per_page}
                     total={releases.total}
                     showLessItems
                     showTitle={true}
                  />
               </CardFooter>
            </Card>
            <CreateRelease status={this.state.modal} toogle={this.toggleModal}/>
         </div>
      );
   }
}


// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   releases: state.releases
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(releasesActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(StationReleases);
