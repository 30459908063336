import api from './api';
import moment from 'moment';

export const userCreate = (data) => {
   const teste = new FormData();
   // Data
   data.payment_date = data.payment_date ? moment(data.payment_date).format('YYYY-MM-DD') : data.payment_date;
   data.contract_start = data.contract_start ? moment(data.contract_start).format('YYYY-MM-DD') : data.contract_start;
   data.contract_finish = data.contract_finish ? moment(data.contract_finish).format('YYYY-MM-DD') : data.contract_finish;
   data.contract_term = data.contract_term ? moment(data.contract_term).format('YYYY-MM-DD') : data.contract_term;

   Object.entries(data).forEach(([key, value]) => {
      teste.append(key, value);    
	});

   if(data.avatar != '') {
      teste.append('avatar', data.avatar, data.avatar.name);
   }

   return api.post('/api/users', teste, {
      headers: {
      'Content-Type': 'multipart/form-data'
      }
   });
}

export const userUpdate = (data, id) => {
   const teste = new FormData();
   // Data
   data.payment_date = data.payment_date ? moment(data.payment_date).format('YYYY-MM-DD') : data.payment_date;
   data.contract_start = data.contract_start ? moment(data.contract_start).format('YYYY-MM-DD') : data.contract_start;
   data.contract_finish = data.contract_finish ? moment(data.contract_finish).format('YYYY-MM-DD') : data.contract_finish;
   data.contract_term = data.contract_term ? moment(data.contract_term).format('YYYY-MM-DD') : data.contract_term;

   Object.entries(data).forEach(([key, value]) => {
      teste.append(key, value);    
	});

   if(data.avatar != '') {
      teste.append('avatar', data.avatar, data.avatar.name);
   }

   return api.post(`/api/users/${id}`, teste, {
      headers: {
      'Content-Type': 'multipart/form-data'
      }
   });
}


export const notificationsRequest = () => {
   return api.get('/api/notifications');
}

export const fetchUserById = (id) => {
   return api.get('/api/users/'+id);
}

export const fetchUsers = () => {
   return api.get('/api/users');
}