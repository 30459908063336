import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated, isStation } from "../services/auth";

const StationCleanRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() && isStation() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/", state: { from: props.location } }} />
			)
		}
	/>
);

export default StationCleanRoute;