export function Report() {

   this.account_id = '';
   this.contrap_id = '';
   this.account_code = '';
   this.contrap_code = '';
   this.sequence = '';
   this.date = '';
   this.branch = '';
   this.company = '';
   this.value = '';
   this.historic = '';
   this.complement = '';
   
}