import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ChatActions }  from '../../store/ducks/chat';

import { Alert, Modal, ModalBody, ModalFooter, Button, Form,  FormGroup, Input, Spinner } from 'reactstrap';
import ScrollToBottom from 'react-scroll-to-bottom';

import 'moment/locale/pt-br';

import { Message } from '../../class/message';

import './style.sass';

class Chat extends Component {
   state = {
      message: new Message()
   }

   componentDidMount() {
      if(this.props.shett) {
         this.props.messagesFetchSheetRequested(this.props.id);
      } else {
         this.props.messagesFetchRequested(this.props.id);
      }
   }

   handleOnKeyPress = (event) => {
      if(event.key === 'Enter'){
         this.sendMenssage();
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();
      this.sendMenssage();
   }

   sendMenssage = () => {
      if(this.props.shett) {
         this.props.messagesSheetCreate(this.state.message.message, this.props.id);
      } else {
         this.props.messagesCreate(this.state.message.message, this.props.id);
      }
      this.setState({ message: new Message() });
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ 
         message: { 
            ...this.state.tax,
            [name]: value 
         }
      });
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered chat">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">Comentários</h3>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  <ScrollToBottom className={(this.props.messages.loading.request) ? ('chat-content is-loading') : ('chat-content')}>
                     {this.props.messages.loading.request && <Spinner style={{ width: '3rem', height: '3rem' }} />}
                     
                     {(this.props.messages.data.length === 0 && !this.props.messages.loading.request && !this.props.messages.error) && 
                        <Alert color="light" className="text-center" >
                           Envie seu primeiro comentário.
                        </Alert>
                     }
                        
                     {!this.props.messages.loading.request && this.props.messages.data.map(message => 
                        <div key={message.id} className={(message.user_id === this.props.user.data.id) ? ("message is-your") : ("message")}>
                           <span className="message-name">{(message.user_id === this.props.user.data.id) ? ("Você") : (message.name)} disse:</span>
                           <p className="message-content">
                              {message.message}
                              <Moment className="message-time" format="DD-MM-YYYY" date={message.created_at} />
                           </p>
                        </div>
                     )}
                  </ScrollToBottom>
                  <FormGroup>
                     <Input 
                        type="textarea"
                        name="message"
                        rows="3"
                        placeholder="Escreva aqui seu comentário"
                        value={this.state.message.message}
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleOnKeyPress}
                     />
                  </FormGroup>
               </ModalBody>   
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.props.toogle} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     Enviar mensagem
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      )
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   messages: state.chat,
   user: state.user
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(ChatActions, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(Chat);
