import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as SwingActions }  from '../../store/ducks/swing';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Spinner, Row, Col, Alert, UncontrolledPopover, PopoverBody } from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import ReportNavbarSystem from '../../components/ReportNavbarSystem';
import Balancete from '../../components/Balancete';

class SystemSwing extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.swingFetchRequested(this.state.start, this.state.stop, id);

   }

   handleFilter = () => {
      localStorage.setItem('filter-date-start', this.state.start);
      localStorage.setItem('filter-date-stop', this.state.stop);
      document.getElementById('filter').click();
      const id = this.props.userSelected.data.id;
      this.props.swingFetchRequested(this.state.start, this.state.stop, id);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { swing } = this.props;
      return (
         <Card className="panel">
            <CardHeader className="pb-0">
               <Row>
                  <Col>
                     <ReportNavbarSystem />
                  </Col>
                  <Link to='/system/print/swing'>
                     <Button color="primary">Imprimir</Button>
                  </Link>
                  <Col xs="auto">
                     <div className="header-content">
                        <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                        <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                           <PopoverBody>
                              <strong className="title mb-3 d-block">Selecione a competência</strong>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">De</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.start).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">A</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.stop).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <div className="text-center">
                                 <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                              </div>
                           </PopoverBody>
                        </UncontrolledPopover>
                     </div>
                  </Col>
               </Row>
            </CardHeader>
            <CardBody>
               {swing.loading &&
                  <div className="text-center py-5">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(swing.data.length === 0 && !swing.loading && !swing.error) &&
                  <Alert color="light" className="text-center my-5" >
                     Você ainda não lançou nem um documento.
                  </Alert>
               }

               {swing.error &&
                  <Alert color="light" className="text-center my-5" >
                     Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {!swing.loading &&
                  <div>
                     {swing.data.filter(item => item.accounts.length !== 0).map(item => <Balancete key={item.id} {...item} />)}
                  </div>
               }
            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   swing: state.swing,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...SwingActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemSwing);
