import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authentication from './authentication';
import user from './user';
import users from './users';
import releases from './releases';
import chat from './chat';
import taxes from './taxes';
import userSelected from './userSelected';
import calendar from './calendar';
import documentation from './documentation';
import sheetReport from './sheetReport';
import sheetDocument from './sheetDocument';
import account from './account';
import swing from './swing';
import dre from './dre';
import balancete from './balancete';
import daily from './daily';
import revenues from './revenues';
import reason from './reason';
import notifications from './notifications';
import accountingEntries from './accountingEntries';

export default (history) => combineReducers({
   router: connectRouter(history),
   authentication,
   releases,
   taxes,
   calendar,
   chat,
   user,
   users,
   balancete,
   reason,
   notifications,
   daily,
   revenues,
   account,
   swing,
   dre,
   userSelected,
   documentation,
   sheetReport,
   sheetDocument,
   accountingEntries,
});
