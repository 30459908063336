import api from './api';

export const messagesCreate = (message, id) => {
	const params = new FormData();
	params.append('message', message); 
	return api.post('/api/releases/status/'+id+'/messages', params);
}

export const messagesSheetCreate = (message, id) => {
	const params = new FormData();
	params.append('message', message); 
	return api.post('/api/sheet/document/status/'+id+'/messages', params);
}

export const messagesRequested = (id) => {
	return api.get('/api/releases/status/'+id+'/messages');
}

export const messagesSheetRequested = (id) => {
	return api.get('/api/sheet/document/status/'+id+'/messages');
}