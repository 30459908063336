import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { Card, CardBody, Collapse,  Badge, Row, Col, Table } from 'reactstrap';

class Balancete extends Component {
   state = {
      collapse: false
   }

   componentDidMount() {
      if(this.props.collapse) {
         this.setState({ collapse: this.props.collapse });
      }
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   render() {
      const { title, previous_total, debit_total, credit_total, current_total, accounts } = this.props;

      return (
         <Card className="taxe card-carton">
            <CardBody>
               <Row>
                  <Col xs="4" className="pr-0"><strong>{title}</strong></Col>
                  <Col xs="8" className="p-0 d-flex text-right justify-content-end cols-width-23">
                     <Col xs="3" className="p-0 "><strong>{previous_total}</strong></Col>
                     <Col xs="3" className="p-0 "><strong>{debit_total}</strong></Col>
                     <Col xs="3" className="p-0"><strong>{credit_total}</strong></Col>
                     <Col xs="3" className="pl-0 ">
                        <strong className="mr-2 align-top">{current_total}</strong>
                        <button className={(this.state.collapse) ? ('btn-toggle active') : ('btn-toggle')} onClick={this.toggleCollapse}></button>
                     </Col>
                  </Col>
               </Row>
            </CardBody>

            <Collapse isOpen={this.state.collapse}>
               <CardBody>
                  <Table className="mytable">
                     <thead>
                        <tr className="bg-light">
                           <th className="table-small py-2">Conta</th>
                           <th className="table-medium py-2">Clas.</th>
                           <th className="table-verysmall py-2">S</th>
                           <th className="table-medium py-2">Desc.</th>
                           <th className="table-medium py-2 text-right">Saldo ant.</th>
                           <th className="table-medium py-2 text-right">Débito</th>
                           <th className="table-medium py-2 text-right">Crédito</th>
                           <th className="table-medium py-2 text-right">Saldo Atual</th>
                        </tr>
                     </thead>
                     <tbody>
                        {accounts.map(item =>
                           <tr className={item.show} key={item.id}>
                              <td className="table-small">{item.account}</td>
                              <td className="table-medium">{item.code}</td>
                              <td className="table-verysmall">{item.synthetic ? 'S' : ''}</td>
                              <td className="table-medium">{item.title}</td>
                              <td className="table-medium text-right">{item.previous}</td>
                              <td className="table-medium text-right">{item.value_debit}</td>
                              <td className="table-medium text-right">{item.value_credit}</td>
                              <td className="table-medium text-right">{item.current}</td>
                           </tr>
                        )}
                     </tbody>
                  </Table>
               </CardBody>
            </Collapse>
         </Card>
      );
   }
}

export default Balancete;
