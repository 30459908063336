import React, { Component } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CalendarActions }  from '../../store/ducks/calendar';

import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import Calendar from '../../components/Calendar';
import ReleasesNotify from '../../components/ReleasesNotify';
import UpdateReceipt from '../../modals/UpdateReceipt';

class StationDashboard extends Component {
   state = {
      day: null,
      modal: false
   }

   componentDidMount() {
      if(this.props.calendar.data.length === 0) {
         this.props.calendarFetchRequested();
      }
   }

   handleDayClick = (day) => {
      let date = this.props.calendar.calendar.filter(date => moment(date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'))[0];

      if(date) {
         this.setState({ day: date });
      }
   }

   toggleModal = () => {
      this.setState({
         modal: !this.state.modal
      });
   }

   dayToogle = () => {
      this.setState({ day: null });
   }

   render() {
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Página inicial</BreadcrumbItem>
            </Breadcrumb>
            <Row>
               <Col xs="7">
                  <Card className="panel is-marge height-100">
                     <CardHeader>
                        <CardTitle className="heading">Meu calendário</CardTitle>
                        <p>Confira as suas obrigações mês a mês, seus documentos a enviar, impostos a pagar e demais obrigações.</p>
                     </CardHeader>
                     <CardBody className="calendar">
                        {this.state.day !== null && 
                           <Card className="calendar-panel panel">
                              <button className="close" onClick={event => (this.setState({ day: null }))}>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                              </button>
                              <CardBody>
                                 <h3 className="heading"><Moment format="DD \d\e MMMM \d\e YYYY" date={this.state.day} /></h3>

                                 {this.props.calendar.data.filter(item => 
                                    (moment(item.maturity).format('YYYY-MM-DD') === moment(this.state.day).format('YYYY-MM-DD')) &&
                                    (item.status === 'pending')
                                 ).map(item => 
                                    <div key={item.id} className="calendar-panel-item py-1">
                                       <span>
                                          {item.type === 'reminder' && <span className="reminder">Lembrete:</span>}
                                          {item.title}
                                       </span>
                                       {item.attachment && 
                                          <div className="group">
                                             <div>
                                                <UpdateReceipt id={item.id} maturity={item.maturity} status={this.state.modal} dayToogle={this.dayToogle} toogle={this.toggleModal} />
                                                <a href={item.attachment} className="btn btn-primary btn-sm mr-1" target="_black">Ver boleto</a>
                                                <a className="btn btn-primary btn-sm" onClick={this.toggleModal}>Paguei</a>
                                             </div>
                                          </div>
                                       }
                                    </div>
                                 )}

                              </CardBody>
                           </Card>
                        }

                        <Calendar datas={this.props.calendar.calendar} handleDayClick={this.handleDayClick} />
                     </CardBody>
                  </Card>
               </Col>
               <Col xs="5">
                  <ReleasesNotify />
               </Col>
            </Row>
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   calendar: state.calendar
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(CalendarActions, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(StationDashboard);