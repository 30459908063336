import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { Card, CardBody, Collapse,  Badge, Row, Col, Table } from 'reactstrap';

class Dre extends Component {
   state = {
      collapse: false
   }

   componentDidMount() {
      if(this.props.collapse) {
         this.setState({ collapse: this.props.collapse });
      }
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   render() {
      const { title, start_value, stop_value, accounts, year1, year2, result_name, start_group_value, stop_group_value, result_code, result_account, result_show    } = this.props;

      return (
         <Card className="taxe card-carton">
            <CardBody>
               <Row>
                  <Col xs="8" className="pr-0"><strong>{title}</strong></Col>
                  <Col xs="2" className="p-0 text-right pr-4"><strong>{start_group_value}</strong></Col>
                  <Col xs="2" className="pl-0 d-flex align-items-center justify-content-end dre-result-margin">
                     <strong className="mr-2">{stop_group_value}</strong>
                     <button className={(this.state.collapse) ? ('btn-toggle active') : ('btn-toggle')} onClick={this.toggleCollapse}></button>
                  </Col>
               </Row>
            </CardBody>

            <Collapse isOpen={this.state.collapse}>
               <CardBody>
                  <Table className="mytable fixed">
                     <thead>
                        <tr className="bg-light">
                           <th className="table-almostbig-dre">Classificação</th>
                           <th className="table-medium-dre">Conta</th>
                           <th className="table-big-dre">Descrição</th>
                           <th className="table-medium-dre text-center">{year1}</th>
                           <th className="table-medium-dre text-right">{year2}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {accounts.map(item =>
                           <tr className={item.show} key={item.id}>
                              <td className="table-almostbig-dre">{item.code}</td>
                              <td className="table-medium-dre">{item.account}</td>
                              <td className="table-big-dre description">{item.title}</td>
                              <td className="table-medium-dre text-center">{item.start}</td>
                              <td className="table-medium-dre text-right">{item.stop}</td>
                           </tr>
                        )}
                       <tr className={result_show}>
                           <td className="table-almostbig-dre">{result_code}</td>
                           <td className="table-medium-dre">{result_account}</td>
                           <td className="table-big-dre description">{result_name}</td>
                           <td className="table-medium-dre text-center">{start_value}</td>
                           <td className="table-medium-dre text-right">{stop_value}</td>
                        </tr>

                     </tbody>
                  </Table>
               </CardBody>
            </Collapse>
         </Card>
      );
   }
}

export default Dre;
