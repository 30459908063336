import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { Card, CardBody, Collapse, Row, Col, Table } from 'reactstrap';

class Daily extends Component {
   state = {
      collapse: false
   }

   componentDidMount() {
      if(this.props.collapse) {
         this.setState({ collapse: this.props.collapse });
      }
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   render() {
      const { date, data, total } = this.props;
      return (
         <Card className="taxe card-carton">
            <CardBody>
               <Row>
                  <Col xs="2" className="pr-0"><strong><Moment format="DD-MM-YYYY" date={date} /></strong></Col>
                  <Col xs="2" className="p-0"></Col>
                  <Col xs="6" className="p-0"></Col>
                  <Col xs="2" className="pl-0 d-flex align-items-center justify-content-end">
                     <strong className="mr-2">{total}</strong>
                     <button className={(this.state.collapse) ? ('btn-toggle active') : ('btn-toggle')} onClick={this.toggleCollapse}></button>
                  </Col>
               </Row>
            </CardBody>

            <Collapse isOpen={this.state.collapse}>
               <CardBody>
                  <Table className="mytable">
                     <thead>
                        <tr className="bg-light">
                           <th className="table-medium-daily">Conta débito</th>
                           <th className="table-medium-daily">Conta crédito</th>
                           <th className="table-big-daily">Histórico</th>
                           <th className="table-medium-daily text-right">Débito</th>
                           <th className="table-medium-daily text-right">Crédito</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.map(item =>
                           <tr key={item.id}>
                              <td className="table-medium-daily">{item.contrap_number}</td>
                              <td className="table-medium-daily">{item.account_number}</td>
                              <td className="table-big-daily">{item.historic}</td>
                              <td className="table-medium-daily text-right">{item.value}</td>
                              <td className="table-medium-daily text-right">{item.value}</td>
                           </tr>
                        )}
                        <tr>
                           <th colSpan="4">Total dia:</th>

                           <th className=" text-right">{total}</th>
                        </tr>
                     </tbody>
                  </Table>
               </CardBody>
            </Collapse>
         </Card>
      );
   }
}

export default Daily;
