import React from 'react';

import { Container, Col, Row } from 'reactstrap';

const Footer = () => (
   <footer className="footer">
      <Container>
         <Row>
            <Col xs="3">
               <h3 className="footer-title">Fale conosco</h3>
               <a href="#linklink" className="footer-email">contato@contabilizaja.com</a>
               <a href="#link" className="footer-phone">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                  (49) 2020–4048
               </a>
               <ul className="list-inline">
                  <li className="list-inline-item">
                     <a href="https://www.facebook.com/contabilizaja/" target="_blank" className="list-inline-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path fill="currentColor" d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"></path></svg></a>
                  </li> 
                  <li className="list-inline-item">
                     <a href="https://www.instagram.com/contabilizaja/" target="_blank" className="list-inline-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.063 169.063" fill="currentColor"><path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"/><path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 0 0-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"/></svg></a>
                  </li>
               </ul>
            </Col>
            <Col xs="2">
               <h3 className="footer-title">Sobre nós</h3>
               <ul className="list-unstyled">
                  <li><a href="https://contabilizaja.com/quem-somos/" target="_blank">Quem somos</a></li>
                  <li><a href="https://contabilizaja.com/noticias/" target="_blank">Blog</a></li>
               </ul>
            </Col>
            <Col xs="3">
               <h3 className="footer-title">Legal</h3>
               <ul className="list-unstyled">
                  <li><a href="#link">FAQ</a></li>
                  <li><a href="https://contabilizaja.com/politica-de-privacidade" target="_blank">Política de privacidade</a></li>
                  <li><a href="https://contabilizaja.com/termos-de-uso-contabilizaja/" target="_blank">Termos de Uso</a></li>
               </ul>
            </Col>
            <Col xs="4">
               <p>A contabilidade para o seu negócio de um jeito simples e prático. A Contabiliza Já é um escritório contábil diferente de tudo que você já viu.</p>
            </Col>
         </Row>
         <span className="footer-copyright">© 2018 Direitos reservados</span>
      </Container>
   </footer>
);

export default Footer;