import { createActions, createReducer} from 'reduxsauce';
import { toast } from "react-toastify";

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   authentication: ['email', 'password'],
   authenticationSucceeded: [],
   authenticationFailed: [],
   logout: []
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   loading: false,
   error: false
}

const authentication = (state) => ({
   ...state,
   loading: true
});

const succeeded = (state, {}) => ({
   ...state,
   loading: false,
   error: false
});

const failed = (state) => {
   toast.error("Email e/ou senha incorretos!");
   return {
      ...state,
      loading: false,
      error: true
   }
};

const logout = () => {
   return INITIAL_STATE;
}

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.AUTHENTICATION]: authentication,
   [Types.AUTHENTICATION_SUCCEEDED]: succeeded,
   [Types.AUTHENTICATION_FAILED]: failed,
   [Types.LOGOUT]: logout
});