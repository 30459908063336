import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   selectUser: ['id']
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: {},
   loading: true,
   error: true
}

const requested = (state) => ({
   ...state,
   loading: true,
   error: false
});

const succeeded = (state, {data}) => ({
   data: data,
   loading: false,
   error: false
});

const failed = (state) => ({
   data: {},
   loading: false,
   error: true
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.SELECT_USER]: requested,
   ['SELECT_USER_SUCCEEDED']: succeeded,
   ['SELECT_USER_FAILED']: failed
});