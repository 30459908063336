import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AccountActions }  from '../../store/ducks/account';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import api from '../../services/api';

import { Button, Card, CardBody, CardHeader, Spinner, Input, Form, Row, Col, Collapse, Alert } from 'reactstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';

import { Group } from '../../class/group';
import AccountGroup from '../../components/AccountGroup';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
 option: (provided, state) => ({
    ...provided,
    width: 220,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: 185,
  }),
}

class SystemAccounts extends Component {
   state = {
      group: new Group(),
      collapse: false ,
      users: [],
      user_list: [],
      user: 0
   }

   submitCopy = () => {
    confirmAlert({
      title: 'Copiar grupos e contas',
      message: 'Tem certeza que deseja apagar todos os grupos e contas atuais e copiar os planos e contas do usuário selecionado?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            this.handleSubmitCopy();
          }
        },
        {
          label: 'Não',
          onClick: () => {
            console.log("não");
          }
        },
      ]
    });
  };

  submitSuccess = () => {
    confirmAlert({
      title: 'Sucesso!',
      message: 'Os grupos e contas foram copiados do usuário selecionado com sucesso!',
      buttons: [
        {
          label: 'Fechar',
          onClick: () => {
            console.log("fechar");
          }
        }
      ]
    });
  };

  submitError = () => {
    confirmAlert({
      title: 'Erro!',
      message: 'Não é possível remover as contas atuais pois já foram usadas em lançamentos contábeis!',
      buttons: [
        {
          label: 'Fechar',
          onClick: () => {
            console.log("fechar");
          }
        }
      ]
    });
  };

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.accountFetchRequested(id);
      api.get(`/api/users`).then(response => {
         let user_list = [];
         response.data.map(item => {
            let label = `${item.name}`;
            user_list = [...user_list, { value: item.id, label }]
         });
         this.setState({
            users: response.data,
            user_list,
            loading: false,
         });
      }).catch(error => {});
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   handleSubmitCopy = (event) => {
      var user_id_copy = this.props.userSelected.data.id;
      var user_id = this.state.user_id;
      api.post(`/api/account/user/${user_id}/copyacc/${user_id_copy}`).then(response => {
         this.submitSuccess();
      }).catch(error => {
         this.submitError();
      });
   }

   handleSubmit = (event) => {
      event.preventDefault();

      const id = this.props.userSelected.data.id;
      this.props.accountCreateGroup(this.state.group, id);

      this.setState({
         group: new Group(),
         collapse: false
      });
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({
         group: {
            ...this.state.group,
            [name]: value
         }
      });
   }

   handleSelectChange = (name, event) => {
      this.setState({
         user_id: event.value
      });
   }

   render() {
      const { account } = this.props;
      return (

         <Card className="panel">

            <CardHeader className="">
            <Form onSubmit={this.handleSubmit}>
             <Row>
               <Col xs="6" className="py-2">
                  <Select
                     className='d-inline-block bigger-select'
                     styles={customStyles}
                     options={this.state.user_list}
                     placeholder="Copiar plano de contas de"
                     value={this.state.user_list.filter(event => (event.value === this.state.user_id))}
                     onChange={this.handleSelectChange.bind(this, 'user_id')}
                  />
                  <div className='d-inline-block ml-2'>
                    <button onClick={this.submitCopy} className='btn-pill btn btn-primary btn-sm'>Copiar</button>
                  </div>
               </Col>
               <Col xs="6" className="d-flex justify-content-end py-2">
                   <Button color="link" onClick={this.toggleCollapse}>
                     {/* {this.props.documentation.loading.create && <Spinner size="sm" className="mr-1" color="primary" />} */}
                     ADICIONAR GRUPO
                   </Button>
               </Col>
              </Row>
             </Form>

            </CardHeader>
            <CardBody>
               <Collapse isOpen={this.state.collapse}>
                  <Card>
                     <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                           <Row>
                              <Col xs="2">
                                 <InputMask
                                    mask="9.9.9.9999"
                                    name="code"
                                    className="form-control is-border pb-0 pt-1 px-0"
                                    placeholder="0.0.0.0000"
                                    maskChar={null}
                                    value={this.state.group.code}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </Col>
                              <Col xs="2">
                                 <InputMask
                                    mask="9999"
                                    name="ranking"
                                    className="form-control is-border pb-0 pt-1 px-0"
                                    placeholder="0000"
                                    maskChar={null}
                                    value={this.state.group.ranking}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </Col>
                              <Col xs="6">
                                 <Input
                                    type="text"
                                    name="title"
                                    className="is-border pb-0 pt-1 px-0"
                                    placeholder="Nome do grupo de contas"
                                    value={this.state.group.title}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </Col>
                              <Col xs="auto">
                                 <Button type="submit" color="primary">Salvar</Button>
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Collapse>

               {account.loading.request &&
                  <div className="text-center py-5">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(account.data.length === 0 && !account.loading.request && !account.error) &&
                  <Alert color="light" className="text-center my-5" >
                     Usuário não relizou nem um lançamento.
                  </Alert>
               }

               {account.error &&
                  <Alert color="light" className="text-center my-5" >
                     Por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {!account.loading.request && account.data.map(item => <AccountGroup key={item.id} {...item} />)}


            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   account: state.account,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...AccountActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemAccounts);
