import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as DocumentationActions } from '../../store/ducks/documentation';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { 
   Button,
   Card,
   CardBody,
   CardHeader,
   CardFooter,
   Spinner,
   Input,
   Form,
   Row,
   Col,
   Collapse,
   Alert
} from 'reactstrap';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import Documentation from '../../components/Documentation';

class SystemDocumentation extends Component {
   state = { 
      collapse: false ,
      groupName: '',
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.documentationFetchByIdRequested(id);
   }

   handleSubmit = (event) => {
      event.preventDefault();
      const id = this.props.userSelected.data.id;
      this.props.documentationByIdCreate(this.state.groupName, id);
      this.setState({ collapse: false, groupName: '' });
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
   }

   handlePageChange = (page) => {
      const id = this.props.userSelected.data.id;
      this.props.documentationPageById(page, id);
   }

   render() {
      const { documentation } = this.props;
      return (
         <Card className="panel is-taxes">
            <CardHeader className="d-flex justify-content-end py-4">
               <Button color="link" onClick={this.toggleCollapse}>
                  {/* {this.props.documentation.loading.create && <Spinner size="sm" className="mr-1" color="primary" />} */}
                  ADICIONAR GRUPO
               </Button>
            </CardHeader>
            <CardBody>
               <Collapse isOpen={this.state.collapse}>
                  <Card>
                     <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                           <Row>
                              <Col>
                                 <Input type="text" name="groupName" className="is-border" placeholder="Nome do grupo de documentos" value={this.state.groupName} onChange={this.handleInputChange} required />
                              </Col>
                              <Col xs="auto">
                                 <Button type="submit" color="primary">Salvar</Button>
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Collapse>

               {documentation.loading.request && 
                  <div className="text-center py-5">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(documentation.data.length === 0 && !documentation.loading.request && !documentation.error) && 
                  <Alert color="light" className="text-center my-5" >
                     Não há nem um grupo criado.
                  </Alert>
               }

               {documentation.error && 
                  <Alert color="light" className="text-center my-5" >
                     Encontramos dificuldades em recuperar seus documentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {!documentation.loading.request && documentation.data.map(item => <Documentation key={item.id} {...item} />)}
            </CardBody>
            <CardFooter>
               <Pagination
                  onChange={this.handlePageChange}
                  current={documentation.current_page}
                  pageSize={documentation.per_page}
                  total={documentation.total}
                  showLessItems
                  showTitle={true}
               />
            </CardFooter>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   documentation: state.documentation,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...DocumentationActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(SystemDocumentation);
