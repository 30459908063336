import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   reasonFetchRequested: ['start', 'stop', 'id', 'user'],
   reasonFetchSucceeded: ['data'],
   reasonFetchRequestedAccounts: ['id'],
   reasonFetchSucceededAccounts: ['data'],
   reasonFetchFailed: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   accounts: [],
   data: [],
   loading: false,
   error: false
};

// Request
const requested = (state) => ({
   ...state,
   loading: true,
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data,
   loading: false,
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: false,
   error: true
});

const accounts = (state, {data}) => ({
   ...state,
   accounts: data,
   loading: false,
   error: false
});

const accountrequested = (state) => ({
   ...state,
   data: [],
   loading: false,
   error: false
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.REASON_FETCH_REQUESTED_ACCOUNTS]: accountrequested,
   [Types.REASON_FETCH_REQUESTED]: requested,
   [Types.REASON_FETCH_SUCCEEDED]: succeeded,
   [Types.REASON_FETCH_FAILED]: failed,
   [Types.REASON_FETCH_SUCCEEDED_ACCOUNTS]: accounts,
});