import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';


// Mi
import StationRoute from './StationRoute';
import StationCleanRoute from './StationCleanRoute';
import SystemCleanRoute from './SystemCleanRoute';
import SystemRoute from './SystemRoute';
import LoggedRoute from './LoggedRoute';

// Páginas
import Login from '../pages/Login';
import Recover from '../pages/Recover';

// Usuário
import StationDashboard from '../pages/Station/StationDashboard';
import StationReleases from '../pages/Station/StationReleases';
import StationTaxes from '../pages/Station/StationTaxes';
import StationPayroll from '../pages/Station/StationPayroll';
import StationDocumentation from '../pages/Station/StationDocumentation';
import StationReports from '../pages/Station/StationReports';
import StationDre from '../pages/Station/StationDre';
import StationSwing from '../pages/Station/StationSwing';
import StationDaily from '../pages/Station/StationDaily';
import StationReason from '../pages/Station/StationReason';
import StationRevenues from '../pages/Station/StationRevenues';

import PrintBalance from '../pages/Station/print/PrintBalance';
import PrintDre from '../pages/Station/print/PrintDre';
import PrintSwing from '../pages/Station/print/PrintSwing';
import PrintDaily from '../pages/Station/print/PrintDaily';
import PrintReason from '../pages/Station/print/PrintReason';
import PrintRevenues from '../pages/Station/print/PrintRevenues';

// Contador
import SystemDashboard from '../pages/System/SystemDashboard';
import SystemReleases from '../pages/System/SystemReleases';
import SystemPayroll from '../pages/System/SystemPayroll';
import SystemDocumentation from '../pages/System/SystemDocumentation';
import SystemAccounts from '../pages/System/SystemAccounts';
import SystemReports from '../pages/System/SystemReports';
import SystemDre from '../pages/System/SystemDre';
import SystemSwing from '../pages/System/SystemSwing';
import SystemDaily from '../pages/System/SystemDaily';
import SystemReason from '../pages/System/SystemReason';
import SystemRevenues from '../pages/System/SystemRevenues';
import SystemAccountingEntries from '../pages/System/SystemAccountingEntries';
import history from './history';

import SystemPrintBalance from '../pages/System/print/SystemPrintBalance';
import SystemPrintDre from '../pages/System/print/SystemPrintDre';
import SystemPrintSwing from '../pages/System/print/SystemPrintSwing';
import SystemPrintDaily from '../pages/System/print/SystemPrintDaily';
import SystemPrintReason from '../pages/System/print/SystemPrintReason';
import SystemPrintRevenues from '../pages/System/print/SystemPrintRevenues';


const Routes = () => (
   <ConnectedRouter history={history}>
      <Switch>
         <Redirect exact path="/" to="/account/login"/>
         <LoggedRoute path="/account/login" component={Login} />
         <LoggedRoute path="/account/recover" component={Recover} />

         <StationRoute path="/station/dashboard" component={StationDashboard} />
         <StationRoute path="/station/releases" component={StationReleases} />
         <StationRoute path="/station/taxes" component={StationTaxes} />
         <StationRoute path="/station/payroll" component={StationPayroll} />
         <StationRoute path="/station/documentation" component={StationDocumentation} />
         <Redirect exact path="/station/reports" to="/station/reports/balancete"/>
         <StationRoute path="/station/reports/balancete" component={StationReports} />
         <StationRoute path="/station/reports/dre" component={StationDre} />
         <StationRoute path="/station/reports/swing" component={StationSwing} />
         <StationRoute path="/station/reports/daily" component={StationDaily} />
         <StationRoute path="/station/reports/reason" component={StationReason} />
         <StationRoute path="/station/reports/revenues" component={StationRevenues} />

         <StationCleanRoute path="/station/print/balancete" component={PrintBalance} />
         <StationCleanRoute path="/station/print/dre/:start/:stop" component={PrintDre} />
         <StationCleanRoute path="/station/print/swing" component={PrintSwing} />
         <StationCleanRoute path="/station/print/daily" component={PrintDaily} />
         <StationCleanRoute path="/station/print/reason/:id" component={PrintReason} />
         <StationCleanRoute path="/station/print/revenues" component={PrintRevenues} />

         <SystemCleanRoute path="/system/print/balancete" component={SystemPrintBalance} />
         <SystemCleanRoute path="/system/print/dre/:start/:stop" component={SystemPrintDre} />
         <SystemCleanRoute path="/system/print/swing" component={SystemPrintSwing} />
         <SystemCleanRoute path="/system/print/daily" component={SystemPrintDaily} />
         <SystemCleanRoute path="/system/print/reason/:id" component={SystemPrintReason} />
         <SystemCleanRoute path="/system/print/revenues" component={SystemPrintRevenues} />

         <SystemRoute path="/system/dashboard" component={SystemDashboard} />
         <SystemRoute path="/system/releases" component={SystemReleases} />
         <SystemRoute path="/system/payroll" component={SystemPayroll} />
         <SystemRoute path="/system/documentation" component={SystemDocumentation} />
         <SystemRoute path="/system/accounts" component={SystemAccounts} />
         <Redirect exact path="/system/reports" to="/system/reports/balancete"/>
         <SystemRoute path="/system/reports/balancete" component={SystemReports} />
         <SystemRoute path="/system/reports/dre" component={SystemDre} />
         <SystemRoute path="/system/reports/swing" component={SystemSwing} />
         <SystemRoute path="/system/reports/daily" component={SystemDaily} />
         <SystemRoute path="/system/reports/reason" component={SystemReason} />
         <SystemRoute path="/system/reports/revenues" component={SystemRevenues} />
         <SystemRoute path="/system/accounting-entries" component={SystemAccountingEntries} />

         <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
   </ConnectedRouter>
);

export default Routes;
