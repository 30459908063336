import React, { Component } from 'react';

import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";

class ReportNavbarSystem extends Component {
   
   render() {
      return (
         <Nav className="reportnavbar" tabs>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/balancete">
                  Balancete
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/dre">
                  DRE
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/swing">
                  Balanço
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/daily">
                  Diário
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/reason">
                  Razão
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/system/reports/revenues">
                  Faturamento
               </NavLink>
            </NavItem>
         </Nav>
      )
   }
   
}

export default ReportNavbarSystem;

