import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TaxesActions }  from '../../store/ducks/taxes';
import api from '../../services/api';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, InputGroup, InputGroupAddon, Input, Spinner, CustomInput } from 'reactstrap';

import NumberFormat from 'react-number-format';

import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import { Tax, TaxTypeOptions } from '../../class/tax';

class CreateTaxes extends Component {
   state = {
      tax: new Tax(),
      value: 0,
      penalty: 0,
      juros: 0,
      loading: true,
      old: null
   }

   componentDidMount() {
      if(this.props.edite) {
         this.setState({ loading: true });

         api.get(`/api/taxes/${this.props.edite}`).then(response => {
            response.data.maturity = new Date (moment(response.data.maturity));
            console.log(response.data.value);
            this.setState({
               tax: new Tax(
                  response.data.type,
                  response.data.title,
                  response.data.maturity,
                  response.data.value,
                  response.data.penalty,
                  response.data.juros,
                  response.data.description,
               ),
               old:  response.data.maturity,
               loading: false
            });

         }).catch(error => {
            console.log('error');
         });

      }

      if(!this.props.edite && !this.props.status) {
         this.setState({
            tax: new Tax(),
            loading: false
         });
      }
   }

   componentDidUpdate() {
      if(this.props.taxes.loading.modal && this.props.status) {
         this.props.taxesModalUpdate();
         this.setState({
            tax: new Tax()
         });
         this.props.toogle();
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();
      if(this.props.edite) {
         this.props.taxUpdate(this.state.old, this.state.tax, this.props.edite);
      } else {
         this.props.taxCreate(this.state.tax, this.props.userSelected.data.id);
      }
   }

   handleCurrencyChange = (name, values) => {
      const { formattedValue, floatValue } = values;
      this.setState({
         tax: {
            ...this.state.tax,
            [name]: floatValue
        }
      });
   }

   handleInputChange = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      this.setState({
         tax: {
            ...this.state.tax,
            [name]: value
         }
      });
   }

   handleSelectChange = (name, event) => {
      this.setState({
         tax: {
            ...this.state.tax,
            [name]: event.value
         }
      });
   }

   handleFileChange = (event) => {
      const { name } = event.target;
      this.setState({
         tax: {
            ...this.state.tax,
            [name]: event.target.files[0]
         }
      });
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         tax: {
            ...this.state.tax,
            [name]: event
         }
      });
   }

   getTotal() {
       return Number(this.state.tax.juros || 0) + Number(this.state.tax.penalty || 0) + Number(this.state.tax.value || 0);
   }

   render() {
      return (
         <Modal key={this.props.key} isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">{this.props.edite ? ('Atualizar lançamento do calendário') : ('Lançar novo calendário')}</h3>
               <p className="m-0">Preencha abaixo com os dados</p>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  {this.state.loading &&
                     <div className="d-flex justify-content-center mt-4 mb-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }
                  {!this.state.loading &&
                  <FormGroup key={1}>
                     <Select
                        options={TaxTypeOptions}
                        placeholder="Escolha o que lançar"
                        value={TaxTypeOptions.filter(item => (item.value === this.state.tax.type))}
                        onChange={this.handleSelectChange.bind(this, 'type')}
                     />
                  </FormGroup>
                  }

                  {this.state.tax.type !== '' &&
                     <div>
                        <FormGroup key={2}>
                           <DayPickerInput
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder="Data de vencimento"
                              value={this.state.tax.maturity}
                              onDayChange={this.handleDayPickerInputChange.bind(this, 'maturity')}
                              dayPickerProps={{
                                 locale: 'pt-br',
                                 localeUtils: MomentLocaleUtils,
                              }}
                              required
                           />
                        </FormGroup>

                        <FormGroup key={3}>
                           <Input
                              type="text"
                              name="title"
                              placeholder="Título "
                              value={this.state.tax.title}
                              onChange={this.handleInputChange.bind(this)}
                              required
                           />
                        </FormGroup>
                     </div>
                  }

                  {(this.state.tax.type === 'tax') &&
                     <div>
                        <FormGroup key={4}>
                           <CustomInput
                              type="file"
                              id="attachment"
                              accept=".png, .jpeg, .jpg, .gif, .doc, .docx, .xlsx, .xls, .zip, .rar, .pdf, .ppt, .pptx, .csv, .txt"
                              name="attachment"
                              className={this.state.tax.attachment ? 'active' : ''}
                              onChange={this.handleFileChange}
                              label={this.state.tax.attachment ? this.state.tax.attachment.name : 'Anexe um arquivo de seu computador'}
                           />
                        </FormGroup>

                        <FormGroup key={5}>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">Valor</InputGroupAddon>
                              <NumberFormat
                                 className="form-control"
                                 decimalSeparator=","
                                 thousandSeparator="."
                                 prefix={"R$ "}
                                 decimalScale={2}
                                 fixedDecimalScale
                                 value={this.state.tax.value || undefined}
                                 onValueChange={this.handleCurrencyChange.bind(this, 'value')}
                              />
                           </InputGroup>
                        </FormGroup>

                        <FormGroup key={6}>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">Multa</InputGroupAddon>
                              <NumberFormat
                                 className="form-control"
                                 decimalSeparator=","
                                 thousandSeparator="."
                                 prefix={"R$ "}
                                 decimalScale={2}
                                 fixedDecimalScale
                                 value={this.state.tax.penalty || undefined}
                                 onValueChange={this.handleCurrencyChange.bind(this, 'penalty')}
                              />
                           </InputGroup>
                        </FormGroup>

                        <FormGroup key={7}>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">Juros</InputGroupAddon>
                              <NumberFormat
                                 className="form-control"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix={"R$ "}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  value={this.state.tax.juros || undefined}
                                  onValueChange={this.handleCurrencyChange.bind(this, 'juros')}
                              />
                           </InputGroup>
                        </FormGroup>

                        <FormGroup key={8}>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">Total</InputGroupAddon>
                              <NumberFormat
                                 className="form-control"
                                 decimalSeparator=","
                                 thousandSeparator="."
                                 prefix={"R$ "}
                                 decimalScale={2}
                                 fixedDecimalScale
                                 disabled
                                 value={this.getTotal()}
                              />
                           </InputGroup>
                        </FormGroup>
                     </div>
                  }

                  {(this.state.tax.type !== '') &&
                     <FormGroup key={9}>
                        <Input
                           type="textarea"
                           name="description"
                           rows="3"
                           placeholder="Descrição"
                           value={this.state.tax.description && (this.state.tax.description !== 'null') ? this.state.tax.description : ''}
                           onChange={this.handleInputChange.bind(this)}
                        />
                     </FormGroup>
                  }
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.props.toogle} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.props.taxes.loading.create && <Spinner color="light" size="sm" className="mr-2" />}
                     {this.props.edite ? ('Atualizar') : ('Adicionar')}
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      )
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   taxes: state.taxes,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(TaxesActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CreateTaxes);
