import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { Card, CardBody, Table } from 'reactstrap';

class Revenues extends Component {
   render() {
      const { data, total } = this.props;

      return (
         <Card className="taxe card-carton">
            <CardBody>
               <Table className="table-revenue">
                  <thead>
                        <tr className="bg-light">
                           <th className="pl-2">Competência</th>
                           <th className="text-right pr-2">Valor</th>
                        </tr>
                     </thead>
                  <tbody>
                     {data.map(item =>
                        <tr key={item.id}>
                           <td className="col-6 pl-2"><Moment format="MMMM / YYYY" date={item.date} /></td>
                           <td className="col-6 text-right pr-2">{item.value}</td>
                        </tr>
                     )}
                     <tr>
                        <td className="col-6 pl-2">Total</td>
                        <td className="col-6 text-right pr-2"><strong>{total}</strong></td>
                     </tr>
                  </tbody>
               </Table>
            </CardBody>
         </Card>
      );
   }
}

export default Revenues;
