import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router';
import moment from 'moment';
import { login, logout, getLogout, fetchUser } from '../services/auth';
import { releasesRequest, releasesByIdRequest, releasesUpdateStatus, releaseCreate } from '../services/releases';
import { fetchUserById, fetchUsers, notificationsRequest } from '../services/user';
import { taxesRequest, taxesPaid, taxesByIdRequest, taxCreate, taxUpdate, calendarRequest, calendarRemove } from '../services/taxes';
import { messagesRequested, messagesSheetRequested, messagesCreate, messagesSheetCreate } from '../services/chat';
import { documentationRequested, documentationCreate, documentationAttachmentCreate, documentationAttachmentUpdate, documentationRemoveAttachment, documentationRemove } from '../services/documentation';
import { sheetReportRequested, sheetDocumentRequested, sheetReportUpdate, sheetReportRemove, sheetDocumentUpdateStatus, sheetReportCreate, sheetDocumentCreate } from '../services/sheet';
import { accountRequest, accountCreate, accountUpdate, accountCreateGroup, accountDelete, groupDelete } from '../services/account';
import { balanceteRequest, swingRequest, dreRequest, dailyRequest, revenuesRequest, reasonRequestAccounts, reasonRequest, accountingEntriesRequest, accountingEntriesDelete, accountingEntriesUpdate } from '../services/reports';

function* balanceteFetchRequested({start, stop, id = null}) {
   try {
      const response = yield call(balanceteRequest, start, stop, id);
      yield put({
         type: "BALANCETE_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "BALANCETE_FETCH_FAILED"
      });
   }
}

function* notificationsFetchRequested() {
   try {
      const response = yield call(notificationsRequest);
      yield put({
         type: "NOTIFICATIONS_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "NOTIFICATIONS_FETCH_FAILED"
      });
   }
}

function* reasonFetchRequestedAccounts({id = null}) {
   try {
      const response = yield call(reasonRequestAccounts, id);
      yield put({
         type: "REASON_FETCH_SUCCEEDED_ACCOUNTS",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "REASON_FETCH_FAILED"
      });
   }
}

function* reasonFetchRequested({start, stop, id, user = null}) {
   try {
      const response = yield call(reasonRequest, start, stop, id, user);
      yield put({
         type: "REASON_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "REASON_FETCH_FAILED"
      });
   }
}

function* revenuesFetchRequested({start, stop, id = null}) {
   try {
      const response = yield call(revenuesRequest, start, stop, id);
      yield put({
         type: "REVENUES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "REVENUES_FETCH_FAILED"
      });
   }
}

function* dailyFetchRequested({start, stop, id = null}) {
   try {
      const response = yield call(dailyRequest, start, stop, id);
      yield put({
         type: "DAILY_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "DAILY_FETCH_FAILED"
      });
   }
}

function* dreFetchRequested({start, stop, id = null}) {
   try {
      const response = yield call(dreRequest, start, stop, id);
      yield put({
         type: "DRE_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "DRE_FETCH_FAILED"
      });
   }
}

function* swingFetchRequested({start, stop, id = null}) {
   try {
      const response = yield call(swingRequest, start, stop, id);
      yield put({
         type: "SWING_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "SWING_FETCH_FAILED"
      });
   }
}

function* accountDeleteRequested({id, group}) {
   try {
      const response = yield call(accountDelete, id);
      yield put({
         type: "ACCOUNT_DELETE_SUCCEEDED",
         data: response.data,
         group
      });
   } catch (error) {}
}

function* groupDeleteRequested({id}) {
   try {
      const response = yield call(groupDelete, id);
      yield put({
         type: "GROUP_DELETE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {}
}

function* accountFetchRequested({id, page = null}) {
   try {
      const response = yield call(accountRequest, id, page);
      yield put({
         type: "ACCOUNT_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "ACCOUNT_FETCH_FAILED"
      });
   }
}

function* accountCreateGroupRequested({data, id}) {
   try {
      const response = yield call(accountCreateGroup, data, id);
      yield put({
         type: "ACCOUNT_CREATE_GROUP_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "ACCOUNT_CREATE_FAILED"
      });
   }
}

function* accountCreateRequested({data, id, user}) {
   try {
      const response = yield call(accountCreate, data, id, user);
      yield put({
         type: "ACCOUNT_CREATE_SUCCEEDED",
         data: response.data.data
      });
   } catch (error) {
      yield put({
         type: "ACCOUNT_CREATE_FAILED"
      });
   }
}

function* accountUpdateRequested({data, id, user, group}) {
   try {
      const response = yield call(accountUpdate, data, id, user);
      yield put({
         type: "ACCOUNT_UPDATE_SUCCEEDED",
         data: response.data,
         group
      });
   } catch (error) {
      yield put({
         type: "ACCOUNT_CREATE_FAILED"
      });
   }
}

function* sheetReportCreateRequested({data, id = null}) {
   try {
      const response = yield call(sheetReportCreate, data, id);
      yield put({
         type: "SHEET_REPORT_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "SHEET_REPORT_CREATE_FAILED"
      });
   }
}

function* sheetReportUpdateRequested({data, id, user = null}) {
   try {
      const response = yield call(sheetReportUpdate, data, id, user);
      yield put({
         type: "SHEET_REPORT_UPDATE_SUCCEEDED",
         data: response.data.data
      });
   } catch (error) {}
}

function* sheetDocumentStatusUpdate({status, id}) {
   try {
      const response = yield call(sheetDocumentUpdateStatus, status, id);
      yield put({
         type: "SHEET_DOCUMENT_STATUS_UPDATE_SUCCEEDED",
         data: response.data,
         id: id
      });
   } catch (error) {

   }
}

function* sheetDocumentCreateRequested({data, id = null}) {
   try {
      const response = yield call(sheetDocumentCreate, data, id);
      yield put({
         type: "SHEET_DOCUMENT_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "SHEET_DOCUMENT_CREATE_FAILED"
      });
   }
}

function* sheetReportFetchRequested({start, stop, id = null, page = null}) {
   try {
      const response = yield call(sheetReportRequested, start, stop, id, page);
      yield put({
         type: "SHEET_REPORT_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "SHEET_REPORT_FETCH_FAILED"
      });
   }
}

function* sheetReportDelete({id, user = null}) {
   try {
      const response = yield call(sheetReportRemove, id, user);
      yield put({
         type: "SHEET_REPORT_DELETE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {}
}

function* sheetDocumentFetchRequested({start, stop, id = null, page = null}) {
   try {
      const response = yield call(sheetDocumentRequested, start, stop, id, page);
      yield put({
         type: "SHEET_DOCUMENT_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "SHEET_DOCUMENT_FETCH_FAILED"
      });
   }
}

function* documentationDeleteAttachment({id, group}) {
   try {
      const response = yield call(documentationRemoveAttachment, id);
      yield put({
         type: "DOCUMENTATION_ATTACHMENT_DELETE_SUCCEEDED",
         data: response.data,
         group
      });
   } catch (error) {}
}

function* documentationDelete({id}) {
   try {
      const response = yield call(documentationRemove, id);
      yield put({
         type: "DOCUMENTATION_DELETE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {}
}

function* documentationFetchRequested({page = null, id = null}) {
   try {
      const response = yield call(documentationRequested, page, id);
      yield put({
         type: "DOCUMENTATION_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "DOCUMENTATION_FETCH_FAILED"
      });
   }
}

function* documentationCreateRequested({id = null, name}) {
   try {
      const response = yield call(documentationCreate, id, name);
      yield put({
         type: "DOCUMENTATION_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "DOCUMENTATION_CREATE_FAILED"
      });
   }
}

function* documentationAttachmentCreateRequested({data, id}) {
   try {
      const response = yield call(documentationAttachmentCreate, data, id);
      yield put({
         type: "DOCUMENTATION_ATTACHMENT_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "DOCUMENTATION_ATTACHMENT_CREATE_FAILED"
      });
   }
}

function* documentationAttachmentUpdateRequested({data, id}) {
   try {
      const response = yield call(documentationAttachmentUpdate, data, id);
      yield put({
         type: "DOCUMENTATION_ATTACHMENT_UPDATE_SUCCEEDED",
         data: response.data.data
      });
   } catch (error) {
      yield put({
         type: "DOCUMENTATION_ATTACHMENT_CREATE_FAILED"
      });
   }
}


function* userFetchByIdRequested({id}) {
   try {
      const response = yield call(fetchUserById, id);
      localStorage.setItem("@airbnb-UserSelected", id);
      yield put({
         type: "SELECT_USER_SUCCEEDED",
         data: response.data
      });

   } catch (error) {
      yield put({
         type: "SELECT_USER_FAILED"
      });
   }
}

//

function* releasesCreateRequested({data}) {
   try {
      const response = yield call(releaseCreate, data);
      yield put({
         type: "RELEASES_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "RELEASES_CREATE_FAILED"
      });
   }
}

function* releasesFetchRequested({start, stop, page = null}) {
   try {
      const response = yield call(releasesRequest, start, stop, page);
      yield put({
         type: "RELEASES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "RELEASES_FETCH_FAILED"
      });
   }
}

function* releasesFetchByIdRequested({start, stop, id, page = null}) {
   try {
      const response = yield call(releasesByIdRequest, start, stop, id, page);
      yield put({
         type: "RELEASES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "RELEASES_FETCH_FAILED"
      });
   }
}

function* releasesStatusUpdate({status, id}) {
   try {
      const response = yield call(releasesUpdateStatus, status, id);
      yield put({
         type: "RELEASES_STATUS_UPDATE_SUCCEEDED",
         data: response.data,
         id: id
      });
   } catch (error) {

   }
}

function* messagesFetchRequested({id}) {
   try {
      const response = yield call(messagesRequested, id);
      yield put({
         type: "MESSAGES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "MESSAGES_FETCH_FAILED"
      });
   }
}

function* messagesFetchSheetRequested({id}) {
   try {
      const response = yield call(messagesSheetRequested, id);
      yield put({
         type: "MESSAGES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "MESSAGES_FETCH_FAILED"
      });
   }
}

function* messagesCreateSheetRequested({message, id}) {
   try {
      const response = yield call(messagesSheetCreate, message, id);
      yield put({
         type: "MESSAGES_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "MESSAGES_CREATE_FAILED"
      });
   }
}
function* messagesCreateRequested({message, id}) {
   try {
      const response = yield call(messagesCreate, message, id);
      yield put({
         type: "MESSAGES_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "MESSAGES_CREATE_FAILED"
      });
   }
}

//

function* taxCreateRequested({data, id}) {
   try {
      const response = yield call(taxCreate, data, id);
      yield put({
         type: "CALENDAR_ADD",
         data: response.data
      });
      yield put({
         type: "TAX_CREATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "TAX_CREATE_FAILED"
      });
   }
}

function* taxUpdateRequested({old, data, id}) {
   try {
      const response = yield call(taxUpdate, data, id);
      yield put({
         type: "CALENDAR_UPP",
         data: response.data,
         old
      });
      yield put({
         type: "TAX_UPDATE_SUCCEEDED"
      });
   } catch (error) {
      yield put({
         type: "TAX_CREATE_FAILED"
      });
   }
}

function* taxPaid({id, data}) {
   try {
      const response = yield call(taxesPaid, id, data);
      yield put({
         type: "TAX_PAID_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "TAX_PAID_FAILED"
      });
   }
}

function* taxesFetchRequested({start, stop, page = null}) {
   try {
      const response = yield call(taxesRequest, start, stop, page);
      yield put({
         type: "TAXES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "TAXES_FETCH_FAILED"
      });
   }
}

function* calendarDelete({old, id}) {
   try {
      const response = yield call(calendarRemove, id);

      yield put({
         type: "CALENDAR_DELETE_SUCCEEDED",
         data: response.data,
         old
      });
   } catch (error) {}
}

function* calendarFetchRequested({id = null}) {
   try {
      const response = yield call(calendarRequest, id);

      let datas = [];

      response.data.data.filter(tax => {
         if(id) {
            return tax;
         }
         return tax.status === 'pending';
      }).map(tax => {
         let date = new Date(moment(tax.maturity));
         datas.push(date);
      });

      yield put({
         type: "CALENDAR_FETCH_SUCCEEDED",
         data: response.data,
         calendar: datas
      });
   } catch (error) {}
}

function* taxesFetchByIdRequested({start, stop, id, page = null}) {
   try {
      const response = yield call(taxesByIdRequest, start, stop, id, page);
      yield put({
         type: "TAXES_FETCH_SUCCEEDED",
         data: response.data
      });

   } catch (error) {
      yield put({
         type: "TAXES_FETCH_FAILED"
      });
   }
}

function* userFetchRequested() {
   try {
      const response = yield call(fetchUser);
      yield put({
         type: "USER_FETCH_SUCCEEDED",
         data: response.data.user
      });
   } catch (error) {
      yield put({
         type: "USER_FETCH_FAILED"
      });
   }
}

function* usersFetchRequested() {
   try {
      const response = yield call(fetchUsers);
      yield put({
         type: "USERS_FETCH_SUCCEEDED",
         data: response.data
      });

      if(response.data.length !== 0) {
         let id = response.data[0]['id'];
         const userSelected = localStorage.getItem("@airbnb-UserSelected");
         if(userSelected !== null) {
            id = userSelected;
         }

         yield put({
            type: "SELECT_USER",
            id
         });
      }
   } catch (error) {
      yield put({
         type: "USERS_FETCH_FAILED"
      });
   }
}

function* accountingEntriesFetchRequested({start, stop, id = null, limit = null}) {
   try {
      const response = yield call(accountingEntriesRequest, start, stop, id, limit);
      yield put({
         type: "ACCOUNTING_ENTRIES_FETCH_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "ACCOUNTING_ENTRIES_FETCH_FAILED"
      });
   }
}

function* accountingEntriesDeleteRequested({id}) {
   try {
      const response = yield call(accountingEntriesDelete, id);
      yield put({
         type: "ACCOUNTING_ENTRIES_DELETE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {}
}

function* accountingEntriesUpdateRequested({data, id}) {
   try {
      const response = yield call(accountingEntriesUpdate, data, id);
      yield put({
         type: "ACCOUNTING_ENTRIES_UPDATE_SUCCEEDED",
         data: response.data
      });
   } catch (error) {
      yield put({
         type: "ACCOUNTING_ENTRIES_CREATE_FAILED"
      });
   }
}

function* authentication({email, password}) {
   try {
      yield call(login, email, password);
      const response = yield call(fetchUser);
      yield put({
         type: "USER_FETCH_SUCCEEDED",
         data: response.data.user
      });
      yield put({
         type: "AUTHENTICATION_SUCCEEDED"
      });
      (response.data.user.type === 'system') ?  yield put(push('/system/dashboard')) : yield put(push('/station/dashboard'))
   } catch (error) {
      yield put({
         type: "AUTHENTICATION_FAILED"
      });
   }
}

function* logoutAuth() {
   try {
      yield call(getLogout);
      yield put(logout());
      yield put(window.location.href = '/account/login');
   } catch (error) {
      logout();
      yield put(window.location.href = '/account/login');
   }
}

function* mySaga() {
   yield takeLatest("AUTHENTICATION", authentication);
   yield takeLatest("LOGOUT", logoutAuth);
   yield takeLatest("USER_FETCH_REQUESTED", userFetchRequested);
   yield takeLatest("USERS_FETCH_REQUESTED", usersFetchRequested);
   yield takeLatest("SELECT_USER", userFetchByIdRequested);
   // Releases
   yield takeLatest("RELEASES_CREATE", releasesCreateRequested);
   yield takeLatest("RELEASES_FETCH_REQUESTED", releasesFetchRequested);
   yield takeLatest("RELEASES_FETCH_BY_ID_REQUESTED", releasesFetchByIdRequested);
   yield takeLatest("RELEASES_STATUS_UPDATE", releasesStatusUpdate);
   yield takeLatest("RELEASES_PAGE", releasesFetchRequested);
   yield takeLatest("RELEASES_PAGE_BY_ID", releasesFetchByIdRequested);
   // Taxes
   yield takeLatest("TAX_CREATE", taxCreateRequested);
   yield takeLatest("TAX_UPDATE", taxUpdateRequested);
   yield takeLatest("TAXES_FETCH_REQUESTED", taxesFetchRequested);
   yield takeLatest("TAXES_FETCH_BY_ID_REQUESTED", taxesFetchByIdRequested);
   yield takeLatest("TAX_PAID", taxPaid);
   yield takeLatest("TAXES_PAGE", taxesFetchRequested);
   yield takeLatest("TAXES_PAGE_BY_ID", taxesFetchByIdRequested);
   // Message
   yield takeLatest("MESSAGES_FETCH_REQUESTED", messagesFetchRequested);
   yield takeLatest("MESSAGES_FETCH_SHEET_REQUESTED", messagesFetchSheetRequested);
   yield takeLatest("MESSAGES_CREATE", messagesCreateRequested);
   yield takeLatest("MESSAGES_SHEET_CREATE", messagesCreateSheetRequested);
   // Calendar
   yield takeLatest("CALENDAR_FETCH_REQUESTED", calendarFetchRequested);
   yield takeLatest("CALENDAR_FETCH_BY_ID_REQUESTED", calendarFetchRequested);
   yield takeLatest("CALENDAR_DELETE", calendarDelete);
   // Documentation
   yield takeLatest("DOCUMENTATION_ATTACHMENT_CREATE", documentationAttachmentCreateRequested);
   yield takeLatest("DOCUMENTATION_ATTACHMENT_UPDATE", documentationAttachmentUpdateRequested);
   yield takeLatest("DOCUMENTATION_CREATE", documentationCreateRequested);
   yield takeLatest("DOCUMENTATION_BY_ID_CREATE", documentationCreateRequested);
   yield takeLatest("DOCUMENTATION_FETCH_REQUESTED", documentationFetchRequested);
   yield takeLatest("DOCUMENTATION_FETCH_BY_ID_REQUESTED", documentationFetchRequested);
   yield takeLatest("DOCUMENTATION_PAGE", documentationFetchRequested);
   yield takeLatest("DOCUMENTATION_PAGE_BY_ID", documentationFetchRequested);
   yield takeLatest("DOCUMENTATION_ATTACHMENT_DELETE", documentationDeleteAttachment);
   yield takeLatest("DOCUMENTATION_DELETE", documentationDelete);
   // Sheet
   yield takeLatest("SHEET_REPORT_CREATE", sheetReportCreateRequested);
   yield takeLatest("SHEET_REPORT_BY_ID_CREATE", sheetReportCreateRequested);
   yield takeLatest("SHEET_REPORT_FETCH_REQUESTED", sheetReportFetchRequested);
   yield takeLatest("SHEET_REPORT_FETCH_BY_ID_REQUESTED", sheetReportFetchRequested);
   yield takeLatest("SHEET_REPORT_PAGE", sheetReportFetchRequested);
   yield takeLatest("SHEET_REPORT_PAGE_BY_ID", sheetReportFetchRequested);
   yield takeLatest("SHEET_REPORT_DELETE_BY_ID", sheetReportDelete);
   yield takeLatest("SHEET_REPORT_DELETE", sheetReportDelete);
   yield takeLatest("SHEET_REPORT_UPDATE", sheetReportUpdateRequested);
   yield takeLatest("SHEET_REPORT_BY_ID_UPDATE", sheetReportUpdateRequested);

   yield takeLatest("SHEET_DOCUMENT_CREATE", sheetDocumentCreateRequested);
   yield takeLatest("SHEET_DOCUMENT_BY_ID_CREATE", sheetDocumentCreateRequested);
   yield takeLatest("SHEET_DOCUMENT_FETCH_REQUESTED", sheetDocumentFetchRequested);
   yield takeLatest("SHEET_DOCUMENT_FETCH_BY_ID_REQUESTED", sheetDocumentFetchRequested);
   yield takeLatest("SHEET_DOCUMENT_PAGE", sheetDocumentFetchRequested);
   yield takeLatest("SHEET_DOCUMENT_PAGE_BY_ID", sheetDocumentFetchRequested);
   yield takeLatest("SHEET_DOCUMENT_STATUS_UPDATE", sheetDocumentStatusUpdate);
   // Account
   yield takeLatest("ACCOUNT_FETCH_REQUESTED", accountFetchRequested);
   yield takeLatest("ACCOUNT_CREATE_GROUP", accountCreateGroupRequested);
   yield takeLatest("ACCOUNT_CREATE", accountCreateRequested);
   yield takeLatest("ACCOUNT_DELETE", accountDeleteRequested);
   yield takeLatest("ACCOUNT_UPDATE", accountUpdateRequested);
   yield takeLatest("GROUP_DELETE", groupDeleteRequested);


   yield takeLatest("BALANCETE_FETCH_REQUESTED", balanceteFetchRequested);
   yield takeLatest("SWING_FETCH_REQUESTED", swingFetchRequested);
   yield takeLatest("DRE_FETCH_REQUESTED", dreFetchRequested);
   yield takeLatest("DAILY_FETCH_REQUESTED", dailyFetchRequested);
   yield takeLatest("REVENUES_FETCH_REQUESTED", revenuesFetchRequested);
   yield takeLatest("REASON_FETCH_REQUESTED_ACCOUNTS", reasonFetchRequestedAccounts);
   yield takeLatest("REASON_FETCH_REQUESTED", reasonFetchRequested);
   yield takeLatest("ACCOUNTING_ENTRIES_FETCH_REQUESTED", accountingEntriesFetchRequested);
   yield takeLatest("ACCOUNTING_ENTRIES_DELETE", accountingEntriesDeleteRequested);
   yield takeLatest("ACCOUNTING_ENTRIES_UPDATE", accountingEntriesUpdateRequested);


   yield takeLatest("NOTIFICATIONS_FETCH_REQUESTED", notificationsFetchRequested);

}

export default mySaga;
