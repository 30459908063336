import axios from "axios";
import { getToken, logout } from "./auth";
import { toast } from "react-toastify";


const api = axios.create({
   //baseURL: "http://127.0.0.1:8000"
   baseURL: "https://app.contabilizaja.com.br/api"
});

api.interceptors.request.use(async config => {
   const token = getToken();
   if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Content-Type'] = 'multipart/form-data';
   }
   return config;
});


api.interceptors.response.use(undefined, error => {
   if(error.response.status === 401) {
      toast("Sua sessão expirou, por favor faça o login novamente.");
      logout();
      return window.location.href = '/account/login';
   }
   return Promise.reject(error);
});


export default api;
