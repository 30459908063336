import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   sheetReportCreate: ['data'],
   sheetReportByIdCreate: ['data', 'id'],
   sheetReportCreateSucceeded: ['data'],
   sheetReportCreateFailed: [],
   sheetReportUpdate: ['data', 'id'],
   sheetReportByIdUpdate: ['data', 'id', 'user'],
   sheetReportUpdateSucceeded: ['data'],
   sheetReportFetchRequested: ['start', 'stop'],
   sheetReportFetchByIdRequested: ['start', 'stop', 'id'],
   sheetReportFetchSucceeded: ['data'],
   sheetReportFetchFailed: [],
   sheetReportPage: ['start', 'stop', 'page'],
   sheetReportDelete: ['id'],
   sheetReportDeleteById: ['id', 'user'],
   sheetReportDeleteSucceeded: ['id'],
   sheetReportPageById: ['start', 'stop', 'id', 'page'],
   sheetReportModalUpdate: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
   },
   error: false
};


const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});


const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true,
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      modal: true,
      create: false,
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const removeSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.filter(item => item.id != data.id)
});

const modalUpdate = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
   }
});

const updateSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item => 
      item.id == data.id 
         ? { ...data } 
         : { ...item }
   ),
   loading: {
      ...state.loading,
      modal: true,
      create: false,
   }
});


/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {

   [Types.SHEET_REPORT_CREATE]: create,
   [Types.SHEET_REPORT_BY_ID_CREATE]: create,
   [Types.SHEET_REPORT_CREATE_SUCCEEDED]: createSucceeded,
   [Types.SHEET_REPORT_CREATE_FAILED]: createFailed,

   [Types.SHEET_REPORT_FETCH_REQUESTED]: requested,
   [Types.SHEET_REPORT_FETCH_BY_ID_REQUESTED]: requested,
   [Types.SHEET_REPORT_FETCH_SUCCEEDED]: succeeded,
   [Types.SHEET_REPORT_FETCH_FAILED]: failed,

   [Types.SHEET_REPORT_MODAL_UPDATE]: modalUpdate,  
   
   [Types.SHEET_REPORT_PAGE]: null,
   [Types.SHEET_REPORT_PAGE_BY_ID]: null,

   [Types.SHEET_REPORT_DELETE]: null,
   [Types.SHEET_REPORT_DELETE_BY_ID]: null,
   [Types.SHEET_REPORT_DELETE_SUCCEEDED]: removeSucceeded,

   [Types.SHEET_REPORT_UPDATE]: create,
   [Types.SHEET_REPORT_BY_ID_UPDATE]: create,
   [Types.SHEET_REPORT_UPDATE_SUCCEEDED]: updateSucceeded,
});
