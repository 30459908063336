import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
   accountingEntriesFetchRequested: ['start', 'stop', 'id', 'limit'],
   accountingEntriesFetchSucceeded: ['data'],
   accountingEntriesFetchFailed: [],
   accountingEntriesDelete: ['id'],
   accountingEntriesDeleteSucceeded: ['data'],
   accountingEntriesUpdate: ['data', 'id'],
   accountingEntriesUpdateSucceeded: ['data'],
});


const INITIAL_STATE = {
   data: [],
   loading: false,
   error: false
};

const requested = (state) => ({
   ...state,
   loading: true,
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data,
   loading: false,
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: false,
   error: true
});

const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
      create: true
   }
});

const updateSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item =>
      item.id == data.id
         ? {...data}
         : {...item}
   ),
   loading: false,
   modal: true,
   create: false
});

const deleteSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.filter(
      item =>
      item.id != data.id
   )
});

export default createReducer(INITIAL_STATE, {
   [Types.ACCOUNTING_ENTRIES_FETCH_REQUESTED]: requested,
   [Types.ACCOUNTING_ENTRIES_FETCH_SUCCEEDED]: succeeded,
   [Types.ACCOUNTING_ENTRIES_FETCH_FAILED]: failed,
   [Types.ACCOUNTING_ENTRIES_DELETE]: null,
   [Types.ACCOUNTING_ENTRIES_DELETE_SUCCEEDED]: deleteSucceeded,
   [Types.ACCOUNTING_ENTRIES_UPDATE]: create,
   [Types.ACCOUNTING_ENTRIES_UPDATE_SUCCEEDED]: updateSucceeded,
});
