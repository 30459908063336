import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import { Creators as UsersActions } from '../../store/ducks/users';

import { NavLink, withRouter } from "react-router-dom";
import classnames from 'classnames';

import {
   Card,
   CardBody,
   Container,
   Nav,
   NavItem,
   Media,
   Input,
   Button,
   Spinner
} from 'reactstrap';

import avatar from '../../images/avatar.png';

import Header from '../../components/Header/System';
import Footer from '../../components/Footer';
import CreateUser from '../../modals/CreateUser';
import CreateTaxes from '../../modals/CreateTaxes';
import CreateSheetDocuments from '../../modals/CreateSheetDocuments';
import CreateSheetReport from '../../modals/CreateSheetReport';
import CreateReport from '../../modals/CreateReport';

class System extends Component {
   state = {
      collapse: false,
      page: this.props.location.pathname,
      letter: 'A',
      search: '',
      alphabet: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      modal: false,
      userCreate: false,
      modalSheetReport: false,
      modalReport: false,
      modalSheetDocument: false,
      edite: ''
   }

   toggleModal = () => {
      this.setState({
         modal: !this.state.modal
      });
   }
   toggleModalUserCreate = (data = null) => {
      if(data) {
         this.setState({
            userCreate: !this.state.userCreate,
            edite: data,
         });
      } else {
         this.setState({
            edite: '',
            userCreate: !this.state.userCreate
         });
      }
   }
   toggleModalSheetReport = () => {
      this.setState({
         modalSheetReport: !this.state.modalSheetReport
      });
   }
   toggleModalReport = () => {
      this.setState({
         modalReport: !this.state.modalReport
      });
   }
   toggleModalSheetDocument = () => {
      this.setState({
         modalSheetDocument: !this.state.modalSheetDocument
      });
   }

   componentDidMount() {
      const letter = localStorage.getItem("@airbnb-Letter");
      if(letter !== null){
         this.setState({ letter });
      }

      if(this.props.user.error) {
         this.props.userFetchRequested();
      }

      this.props.usersFetchRequested();
   }


   findUsersByLetter = (letter) => {
      this.setState({ letter });
      localStorage.setItem("@airbnb-Letter", letter);
   }

   selectUser = (user) => {
      this.props.selectUser(user);
   }

   openCollapse = () => {
      this.setState({ collapse: true });
   }

   closeCollapse = () => {
      if(this.state.collapse) {
         this.setState({ collapse: false });
      }
   }

   loggedUserIsTypeSystem() {
       return this.props.user.data.type === 'system';
   }

   teste = (page) => {
      this.setState({ page });
   }

   render() {
      let filteredUsers;
      if (this.state.search != '') {
         filteredUsers = this.props.users.data.filter(
            user => ((user.social_name ? user.social_name : user.company.social_name).toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
         );
         this.state.letter = '';
      } else {
         if (!localStorage.getItem("@airbnb-Letter") === null) {
            this.state.letter = localStorage.getItem("@airbnb-Letter");
         }
         filteredUsers = this.props.users.data.filter(
            user => ((user.social_name ? user.social_name : user.company.social_name)[0].toLowerCase() === this.state.letter.toLowerCase() &&
            (user.social_name ? user.social_name : user.company.social_name).toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
         );
      }
      return (
         <div>
            <Header />
            <Container>

               <div className={(this.state.collapse) ? ('system active') : ('system')}>
                  <div className="system-navbars">
                     <Card className="panel">

                        <CardBody className="system-navs">
                           <div className="navs-search">
                              <Input type="text" className="search" name="email" autoComplete="off" onChange={e => this.setState({ search: e.target.value })} placeholder="Digite o que busca" />
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                           </div>
                           <button className="btn-createuser" onClick={this.toggleModalUserCreate}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                           </button>
                           <h3 className="navs-heading">Filtro por cliente</h3>
                           <div className="navs-content">
                              <ol className="system-filte">
                                 {this.state.alphabet.map(letter => (
                                    <li className={classnames({ active: this.state.letter === letter })} key={letter}  onClick={this.findUsersByLetter.bind(this, letter)}>{letter}</li>
                                 ))}
                              </ol>
                              <ul className="system-users">
                                 {filteredUsers.map(user => (
                                    <li key={user.id} onClick={this.selectUser.bind(this, user.id)} className={classnames({ active: this.props.userSelected.data.id === user.id })} >
                                       <Media className={user.id === this.props.userSelected.data.id ? 'active' : ''}>
                                          <Media object src={user.avatar ? user.avatar : avatar} alt={user.name} />
                                          <Media body>
                                             <Media heading>{user.social_name ? user.social_name : user.company.social_name}</Media>
                                             <span>{user.name}</span>
                                          </Media>
                                       </Media>
                                    </li>
                                 ))}
                              </ul>
                           </div>

                        </CardBody>

                        <CardBody className={this.props.userSelected.loading ? 'system-user is-loading' : 'system-user'}>
                           {!this.state.collapse &&
                           <button className="button-search" onClick={this.openCollapse}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                           </button>}

                           {this.props.userSelected.loading &&
                              <Spinner style={{ width: '3rem', height: '3rem' }} />
                           }

                           {!this.props.userSelected.loading &&
                              <div>
                                 <Media>
                                    <Media left top href="#">
                                       <Media object src={this.props.userSelected.data.avatar ? this.props.userSelected.data.avatar : avatar} alt={this.props.userSelected.data.name} />
                                       <button className="btn-useredit" onClick={this.toggleModalUserCreate.bind(this, true)}>
                                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                       </button>
                                    </Media>
                                    <Media body>
                                       <Media className="heading" heading>
                                          {this.props.userSelected.data.company.social_name}
                                       </Media>
                                       {this.props.userSelected.data.name}
                                    </Media>
                                 </Media>
                                 <Nav vertical>
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/dashboard')} to="/system/dashboard">Calendário / Impostos</NavLink></NavItem>
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/releases')} to="/system/releases">Arquivos</NavLink></NavItem>
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/reports')} to="/system/reports">Relatórios</NavLink></NavItem>
                                    {this.loggedUserIsTypeSystem() && <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/accounting-entries')} to="/system/accounting-entries">Lançamentos contábeis</NavLink></NavItem>}
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/accounts')} to="/system/accounts">Plano de contas</NavLink></NavItem>
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/payroll')} to="/system/payroll">Folha de pagamento</NavLink></NavItem>
                                    <NavItem><NavLink className="nav-link" activeClassName="active" onClick={this.teste.bind(this, '/system/documentation')} to="/system/documentation">Documentação</NavLink></NavItem>
                                 </Nav>
                                 <div className="text-center">

                                    {this.state.page.indexOf("/system/reports") != -1 &&
                                       <div>
                                          <Button color="primary" onClick={this.toggleModalReport}>Lançamento contábil</Button>
                                          <CreateReport status={this.state.modalReport} toogle={this.toggleModalReport} />
                                       </div>
                                    }

                                    {this.state.page === '/system/dashboard' &&
                                       <div>
                                          <Button color="primary" onClick={this.toggleModal}>Lançar no calendário</Button>
                                          <CreateTaxes status={this.state.modal} toogle={this.toggleModal} />
                                       </div>
                                    }

                                    {this.state.page === '/system/payroll' &&
                                       <div>
                                          <CreateSheetReport status={this.state.modalSheetReport} toogle={this.toggleModalSheetReport}/>
                                          <CreateSheetDocuments status={this.state.modalSheetDocument} toogle={this.toggleModalSheetDocument}/>
                                          <Button color="primary" block onClick={this.toggleModalSheetReport} className="justify-content-center">Enviar relatório</Button>
                                       </div>
                                    }
                                 </div>
                              </div>
                           }
                        </CardBody>
                     </Card>
                  </div>
                  <div className={this.props.userSelected.loading ? 'system-content is-loading' : 'system-content'} onClick={this.closeCollapse}>
                     {this.props.userSelected.loading &&
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     }
                     {!this.props.userSelected.loading &&
                        this.props.children
                     }
                  </div>
               </div>
            </Container>
            <CreateUser status={this.state.userCreate} edite={this.state.edite} toogle={this.toggleModalUserCreate} />
            <Footer />
         </div>
      );
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   user: state.user,
   users: state.users,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...UserActions, ...UsersActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(System));
