import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as RevenuesActions }  from '../../store/ducks/revenues';
import { Link } from 'react-router-dom';

import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, UncontrolledPopover, PopoverBody, Row, Col, Alert, Spinner } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import ReportNavbar from '../../components/ReportNavbar';
import Revenues from '../../components/Revenues';

class StationRevenues extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }

   componentDidMount() {
      if(this.props.revenues.data.length === 0) {
         this.props.revenuesFetchRequested(this.state.start, this.state.stop);
      }
   }

   handleFilter = () => {
      localStorage.setItem('filter-date-start', this.state.start);
      localStorage.setItem('filter-date-stop', this.state.stop);
      document.getElementById('filter').click();
      this.props.revenuesFetchRequested(this.state.start, this.state.stop);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { revenues } = this.props;
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Relatórios</BreadcrumbItem>
            </Breadcrumb>
            <Card className="panel is-marge">
               <CardHeader>
                  <CardTitle className="heading">Relatórios contábeis</CardTitle>
                  <p>Selecione o relatório desejado para obter informações de sua <br />empresa com facilidade.</p>
                  <Link to='/station/print/revenues'>
                     <Button color="primary" size="lg">Imprimir</Button>
                  </Link>

                  <Row>
                     <Col>
                        <ReportNavbar />
                     </Col>
                     <Col xs="auto">
                        <div className="header-content">
                           <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                           <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                              <PopoverBody>
                                 <strong className="title mb-3 d-block">Selecione a competência</strong>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">De</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.start).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">A</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.stop).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <div className="text-center">
                                    <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                                 </div>
                              </PopoverBody>
                           </UncontrolledPopover>
                        </div>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
                  {revenues.loading &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(revenues.data.length === 0 && !revenues.loading && !revenues.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Você ainda não lançou nem um documento.
                     </Alert>
                  }

                  {revenues.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {!revenues.loading &&
                     <div>
                        <Revenues data={revenues.data} total={revenues.total} />
                     </div>
                  }
               </CardBody>
            </Card>
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   revenues: state.revenues
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(RevenuesActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(StationRevenues);
