import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import logo from '../../../images/logo.png';

import Daily from '../../../components/Daily';

class PrintDaily extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }
   render() {
      const { daily } = this.props;

      return (
         <div className="print">
            <Container>
               <Row className="py-3 align-items-center">
                  <Col xs="auto"><img src={logo} alt="logo" /></Col>
                  <Col>
                     <h4 className="m-0">Diário</h4>
                     { this.props.user.data.company.social_name } - { this.props.user.data.company.cnpj }
                  </Col>
                  <Col className="text-right">
                     <Link to="/station/reports/daily">Voltar</Link>
                  </Col>
               </Row>
               <h5 className="mb-2 text-center">Período {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</h5>
               {daily.data.filter(item => item.data.length !== 0).map(item => <Daily collapse={true} key={Math.random()} {...item} />)}
               {daily.months.length !== 0 &&
                  <Card>
                     <CardBody>
                        {daily.months.map(item =>
                           <Row>
                              <Col><strong><Moment format="MMMM / YYYY" date={item.date} /></strong></Col>
                              <Col className="text-right"><strong>{item.value}</strong></Col>
                           </Row>
                        )}
                        <hr/>
                        {daily.years.map(item =>
                           <Row>
                              <Col><strong><Moment format="YYYY" date={item.date} /></strong></Col>
                              <Col className="text-right"><strong>{item.value}</strong></Col>
                           </Row>
                        )}
                     </CardBody>
                  </Card>
               }
            </Container>
         </div>
      );
   }
}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user,
   daily: state.daily
});

export default connect(
   mapStateToProps,
   null
)(PrintDaily);
