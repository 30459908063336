import api from './api';
import moment from 'moment';

export const taxCreate = (data, id) => {
	
	const params = new FormData();
	data.maturity = data.maturity ? moment(data.maturity).format('YYYY-MM-DD') : data.maturity;

	Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);    
	});

   if(data.attachment !== '') {
      params.append('attachment', data.attachment, data.attachment.name);  
   }

	return api.post('/api/taxes/user/'+id, params, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

export const taxesRequest = (start, stop, page) => {
	let date = `?start=${start}&stop=${stop}`;
	if(page) {
		return api.get(`/api/taxes?page=${page}${date}`);
	}
	return api.get(`/api/taxes${date}`);
}

export const taxesByIdRequest = (start, stop, id, page) => {
	let date = `?start=${start}&stop=${stop}`;
	if(page) {
		return api.get(`/api/taxes/user/${id}?page=${page}${date}`);
	}
	return api.get(`/api/taxes/user/${id}${date}`);
}

export const taxesPaid = (id, data) => {
	const params = new FormData();

	if(data !== '') {
      params.append('attachment', data, data.name);  
   }

	return api.post('/api/taxes/'+id+'/paid', params);
}

export const calendarRequest = (id) => {
	if(id) {
		return api.get('/api/calendar/user/'+id);
	}
	return api.get('/api/calendar');
}

export const calendarRemove = (id) => {
  
	return api.delete(`/api/taxes/${id}`);
}

export const taxUpdate = (data, id) => {
	const params = new FormData();
	data.maturity = data.maturity ? moment(data.maturity).format('YYYY-MM-DD') : data.maturity;
	
   params.append('type', data.type); 
   params.append('title', data.title); 
	params.append('maturity', data.maturity); 
	if(data.value) {
		params.append('value', data.value); 
	}
	if(data.total) {
		params.append('total', data.total); 
	}
	if(data.penalty) {
		params.append('penalty', data.penalty); 
	}
	if(data.juros) {
		params.append('juros', data.juros); 
	}
   params.append('description', data.description); 
	 
   if(data.attachment != '') {
      params.append('attachment', data.attachment, data.attachment.name); 
   }

   return api.post('/api/taxes/'+id, params);
}