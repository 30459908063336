import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   messagesCreate: ['message', 'id'],
   messagesSheetCreate: ['message', 'id'],
   messagesCreateSucceeded: ['data'],
   messagesCreateFailed: [],
   messagesFetchRequested: ['id'],
   messagesFetchSheetRequested: ['id'],
   messagesFetchSucceeded: ['data'],
   messagesFetchFailed: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: [],
   loading: {
      request: false,
      create: false,
   },
   error: false
};

// Request
const requested = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});

// Create
const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      ...state.data,
      data.data,
   ],
   loading: {
      ...state.loading,
      create: false
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: false
   }
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.MESSAGES_FETCH_REQUESTED]: requested,
   [Types.MESSAGES_FETCH_SHEET_REQUESTED]: requested,
   [Types.MESSAGES_FETCH_SUCCEEDED]: succeeded,
   [Types.MESSAGES_FETCH_FAILED]: failed,
   [Types.MESSAGES_CREATE]: create,
   [Types.MESSAGES_SHEET_CREATE]: create,
   [Types.MESSAGES_CREATE_SUCCEEDED]: createSucceeded,
   [Types.MESSAGES_CREATE_FAILED]: createFailed,
});

