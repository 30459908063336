import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AccountActions }  from '../../store/ducks/account';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import api from '../../services/api';
import moment from 'moment';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Input, Spinner, CustomInput, Row, Col } from 'reactstrap';

import IntlCurrencyInput from "react-intl-currency-input";
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
 
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import { Report } from '../../class/report';

const currencyConfig = {
   locale: "pt-BR",
   formats: {
      number: {
         BRL: {
         style: "currency",
         currency: "BRL",
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
         },
      },
   },
};

class CreateReport extends Component {
   state = {
      data: new Report(),
      accounts: [],
      //debit: [],
      //credit: [],
      accounts_list: [],
      loading: true,
      create: false
   }

   componentDidMount() {
      this.setState({ loading: true });
      const id = this.props.userSelected.data.id;
      api.get(`/api/account/user/${id}`).then(response => {
         // let debit = []; 
         // let credit = []; 
         let accounts_list = []; 
         response.data.map(item => {
            let label = `${item.code} - ${item.account} - ${item.title}`;
            accounts_list = [...accounts_list, { value: item.id, label }]
            // if(item.type === 'debit') {
            //    let label = `${item.code} - ${item.account}`;
            //    debit = [...debit, { value: item.id, label }]
            // } else {
            //    let label = `${item.code} - ${item.account}`;
            //    credit = [...credit, { value: item.id, label }]
            // }
         });
         this.setState({ 
            accounts: response.data,
            // debit,
            // credit,
            accounts_list,
            loading: false,
         });
      }).catch(error => {});
   }

   handleCancel = () => {
      this.setState({ 
         data: new Report(),
      });
      this.props.toogle();
   }

   handleSubmit = async (event) => {
      event.preventDefault();

      await this.setState({ 
         create: true,
         data: {
            ...this.state.data,
            account_code: this.state.accounts.filter(item => item.id === this.state.data.account_id).map(item => `${item.code}`).toString(),
            contrap_code: this.state.accounts.filter(item => item.id === this.state.data.contrap_id).map(item => `${item.code}`).toString(),
         }
      });
      
      let data = this.state.data;
      const id = this.props.userSelected.data.id;
      const params = new FormData();
      data.date = data.date ? moment(data.date).format('YYYY-MM-DD') : data.date;

      Object.entries(data).forEach(([key, value]) => {
         params.append(key, value);    
      });

      api.post(`/api/report/${id}`, params).then(response => {
         this.setState({ 
            data: new Report(),
            create: false
         });
         this.props.toogle();
      }).catch(error => {
         console.log('error');
      });
   }

   handleCurrencyChange = (name, event, value, maskedValue) => {
      event.preventDefault();
      this.setState({ 
         data: {
            ...this.state.data,
            [name]: value
         }
      });
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ 
         data: { 
            ...this.state.data,
            [name]: value 
         }
      });
   }

   handleSelectChange = (name, event) => {
      this.setState({ 
         data: {
            ...this.state.data,
            [name]: event.value
         }
      });
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({ 
         data: {
            ...this.state.data,
            [name]: event
         }
      });
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">{this.props.edite ? ('Atualizar lançamento do contábil') : ('Lançamento do contábil')}</h3>
               <p className="m-0">Preencha abaixo com os dados de seu lançamento.</p>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  <FormGroup>
                     <Input 
                        type="text"
                        name="sequence"
                        placeholder="Sequência"
                        value={this.state.data.sequence}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder="Data" 
                        value={this.state.data.date}
                        onDayChange={this.handleDayPickerInputChange.bind(this, 'date')}
                        dayPickerProps={{
                           locale: 'pt-br',
                           localeUtils: MomentLocaleUtils,
                        }}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <Row>
                        <Col xs="4">
                           <Input 
                              type="text"
                              name="branch"
                              placeholder="Filial"
                              value={this.state.data.branch}
                              onChange={this.handleInputChange}
                              required
                           />
                        </Col>
                        <Col xs="8">
                           <Input 
                              type="text"
                              name="company"
                              placeholder="Nome da empresa"
                              value={this.state.data.company}
                              onChange={this.handleInputChange}
                              required
                           />
                        </Col>
                     </Row>
                  </FormGroup>
                  <FormGroup>
                     <Row>
                        <Col xs="5">
                           <Select 
                              options={this.state.accounts_list}
                              placeholder="Conta débito" 
                              value={this.state.accounts_list.filter(event => (event.value === this.state.data.account_id))}
                              onChange={this.handleSelectChange.bind(this, 'account_id')} 
                           />
                        </Col>
                        <Col xs="7">
                           <Input
                              type="text"
                              placeholder="Conta débito"
                              value={this.state.accounts.filter(item => item.id === this.state.data.account_id).map(item => `${item.account} - ${item.title}`)}
                              disabled
                           />
                        </Col>
                     </Row>
                  </FormGroup>
                  <FormGroup>
                     <Row>
                        <Col xs="5">
                           <Select 
                              options={this.state.accounts_list}
                              placeholder="Conta crédito" 
                              value={this.state.accounts_list.filter(event => (event.value === this.state.data.contrap_id))}
                              onChange={this.handleSelectChange.bind(this, 'contrap_id')} 
                           />
                        </Col>
                        <Col xs="7">
                           <Input
                              type="text"
                              placeholder="Conta crédito"
                              value={this.state.accounts.filter(item => item.id === this.state.data.contrap_id).map(item => `${item.account} - ${item.title}`)}
                              disabled
                           />
                        </Col>
                     </Row>
                  </FormGroup>
                  <FormGroup>
                     <Input 
                        type="text"
                        name="historic"
                        placeholder="Histórico"
                        value={this.state.data.historic}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <IntlCurrencyInput 
                        autoSelect={true}
                        currency="BRL" 
                        className="form-control"
                        placeholder="Valor"
                        value={this.state.data.value}
                        config={currencyConfig} 
                        onChange={this.handleCurrencyChange.bind(this, 'value')}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <Input 
                        type="textarea"
                        name="complement"
                        rows="3"
                        placeholder="Complemento"
                        value={this.state.data.complement}
                        onChange={this.handleInputChange}
                     />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.handleCancel} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.state.create && <Spinner color="light" size="sm" className="mr-2" />}
                     {this.props.edite ? ('Atualizar') : ('Adicionar')}
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      )
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   account: state.account,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...AccountActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(CreateReport);