import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ReleasesActions }  from '../../store/ducks/releases';

import { isSystem } from "../../services/auth";

import { Badge, Card, CardBody, Collapse, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Slider from "react-slick";

import Chat from '../../modals/Chat';

import './style.sass';

class Release extends Component {
   state = {
      status: {
         homologated: { 
            style: 'success', 
            caption: 'Homologado' 
         },
         analysis: { 
            style: 'info', 
            caption: 'Em análise' 
         },
         refused: { 
            style: 'danger', 
            caption: 'Recusado' 
         }
      },
      type: {
         contabil: {
            style: 'primary', 
            caption: 'Contábil' 
         },
         fiscal: {
            style: 'secondary', 
            caption: 'Fiscal' 
         }
      },
      collapse: false,
      chat: false,
      status_id: null
   }

   toggleChat = (id = null) => {
      this.setState({
         chat: !this.state.chat,
         status_id: id
      });
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   handleStatusClick = (status, id) => {
      this.props.releasesStatusUpdate(status, id);
   }


   render() {
      const { id, title, status, attachment, type, created_at  } = this.props;

      // Status
      let statusKey;
      let statusStyle = 'dark';
      let statusCaption = 'Pendente';
      if(status.length !== 0) {
         statusKey = status[status.length - 1]['status'];
         statusStyle = this.state.status[statusKey].style;
         statusCaption = this.state.status[statusKey].caption;
      }

      const settings = {
         arrows: true,
         dots: false,
         infinite: false,
         slidesToShow: 4,
         slidesToScroll: 1
      };

      return (
         <Card className="release">
            {this.state.chat && <Chat id={this.state.status_id} status={this.state.chat} toogle={this.toggleChat} />}
            <CardBody>
               <Row>
                  <Col xs="2">
                     <Badge className="text-uppercase" color={this.state.type[type].style}>{this.state.type[type].caption}</Badge>
                  </Col>
                  <Col xs="2">
                     <Moment format="DD-MM-YYYY" date={created_at} />
                  </Col>
                  <Col xs="5">
                     <strong className="release-title">{title}</strong>
                  </Col>
                  <Col xs="3" className="d-flex align-items-center justify-content-end">
                     {
                     isSystem() ? (
                        <UncontrolledDropdown>
                           <DropdownToggle className="btn-badge" color={statusStyle} caret>
                              {statusCaption}
                           </DropdownToggle>
                           <DropdownMenu right>                             
                              <DropdownItem onClick={this.handleStatusClick.bind(this, 'analysis', id)}>Em análise</DropdownItem>
                              <DropdownItem onClick={this.handleStatusClick.bind(this, 'refused', id)}>Recusado</DropdownItem>
                              <DropdownItem onClick={this.handleStatusClick.bind(this, 'homologated', id)}>Homologado</DropdownItem>
                           </DropdownMenu>
                        </UncontrolledDropdown>
                     ) : (
                        <Badge color={statusStyle} pill>{statusCaption}</Badge>
                     )
                     }
                     <button className={(this.state.collapse) ? ('ml-3 btn-toggle active') : ('ml-3 btn-toggle')} onClick={this.toggleCollapse}></button>
                  </Col>
               </Row>
            </CardBody>
            <Collapse className="release-collapse" isOpen={this.state.collapse}>
               <CardBody>
                  <Slider {...settings}>
                     <div className="release-item">
                        <div className="item-header">
                           <strong className="title">Enviado</strong>
                           <span className="date"><Moment format="DD-MM-YYYY" date={created_at} /></span>
                        </div>
                        <a href={attachment} className="btn btn-primary btn-xs btn-pill" target="_black">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                           Fazer download
                        </a>
                     </div>
                     {status.map(item => 
                        <div key={item.id} className="release-item">
                           <div className="item-header">
                              <strong className="title">{this.state.status[item.status].caption}</strong>
                              <span className="date"><Moment format="DD-MM-YYYY" date={created_at} /></span>
                           </div>

                           {item.status === 'homologated' &&
                              <div className={"item-content " + this.state.status[item.status].style}>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                              </div>
                           }

                           {item.status === 'analysis' &&
                              <div onClick={this.toggleChat.bind(this, item.id)} className={"item-content " + this.state.status[item.status].style}>
                                 <svg onClick={this.toggleChat} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z"></path></svg>
                              </div>
                           }

                           {item.status === 'refused' &&
                              <div className={"item-content " + this.state.status[item.status].style}>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                              </div>
                           }

                        </div>
                     )}
                     
                  </Slider>
                  
               </CardBody>
            </Collapse>
         </Card>
      )
   }
}

const mapDispatchToProps = dispatch => (
   bindActionCreators(ReleasesActions, dispatch)
);

export default connect(
   false, 
   mapDispatchToProps
)(Release);