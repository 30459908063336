import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import reducers from './ducks';
import mySaga from './sagas';
import history from '../routes/history';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
   sagaMiddleware,
   routerMiddleware(history)
];

const store = createStore(
   reducers(history),
   applyMiddleware(...middlewares)
);

sagaMiddleware.run(mySaga);

export default store;