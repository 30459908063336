import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ReasonActions }  from '../../store/ducks/reason';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { Button, Card, CardBody, CardHeader, Spinner, Row, Col, Alert, UncontrolledPopover, PopoverBody, Table } from 'reactstrap';

import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import ReportNavbarSystem from '../../components/ReportNavbarSystem';

class SystemReason extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      data: '',
      select: [],
      updade: false
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.reasonFetchRequestedAccounts(id);
   }

   componentDidUpdate() {
      if(this.props.reason.accounts.length !== 0 && !this.state.updade) {
         let select = [];
         this.props.reason.accounts.map(item => {
            let label = `${item.code}.${item.account} - ${item.title}`;
            select = [...select, { value: item.id, label }];
         });
         this.setState({
            select,
            updade: true
         });
      }
   }

   handleFilter = () => {
       localStorage.setItem('filter-date-start', this.state.start);
       localStorage.setItem('filter-date-stop', this.state.stop);
       document.getElementById('filter').click();
      if(this.state.data != '') {
         const id = this.props.userSelected.data.id;
         this.props.reasonFetchRequested(this.state.start, this.state.stop, this.state.data, id);
      }
   }

   handleSelectChange = (name, event) => {
      const id = this.props.userSelected.data.id;
      this.setState({
         [name]: event.value
      });
      this.props.reasonFetchRequested(this.state.start, this.state.stop, event.value, id);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { reason } = this.props;
      return (
         <Card className="panel is-reason-relat">
            <CardHeader className="pb-0">
               <Row>
                  <Col>
                     <ReportNavbarSystem />
                  </Col>
                  <Link to={`/system/print/reason/${this.state.data}`}>
                     <Button color="primary">Imprimir</Button>
                  </Link>
                  <Col xs="auto">
                     <div className="header-content">
                        <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                        <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                           <PopoverBody>
                              <strong className="title mb-3 d-block">Selecione a competência</strong>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">De</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.start).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">A</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.stop).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <div className="text-center">
                                 <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                              </div>
                           </PopoverBody>
                        </UncontrolledPopover>
                     </div>
                  </Col>
               </Row>
            </CardHeader>
            <CardBody>
               <Select
                  options={this.state.select}
                  placeholder="Selecione a conta"
                  value={this.state.select.filter(event => (event.value === this.state.data))}
                  onChange={this.handleSelectChange.bind(this, 'data')}
               />

               {reason.loading &&
                  <div className="text-center py-5">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(reason.data.length === 0 && !reason.loading && !reason.error) &&
                  <Alert color="light" className="text-center my-5" >
                     Selecione uma conta.
                  </Alert>
               }

               {reason.error &&
                  <Alert color="light" className="text-center my-5" >
                     Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {(!reason.loading && reason.data.length !== 0) &&
                  <Card className="mt-3">
                     <CardBody className="min-height-relat">
                        {reason.data.data.length === 0 &&
                           <Alert color="light" className="text-center my-4" >
                              Sem dados
                           </Alert>
                        }
                        {reason.data.data.length !== 0 &&
                           <Table>
                              <tbody>
                                 <tr>
                                    <th>Data</th>
                                    <th>Histórico</th>
                                    <th>Contrap</th>
                                    <th>Débito</th>
                                    <th>Crédito</th>
                                    <th className="text-right">Saldo atual</th>
                                 </tr>
                                 {reason.data.data.map(item =>
                                    <tr key={item.id}>
                                       <td><Moment format="DD-MM-YYYY" date={item.date} /></td>
                                       <td>{item.historic}</td>
                                       <td>{item.account_id == this.state.data ? item.contrap_number : item.account_number}</td>
                                       <td>{item.account_id == this.state.data ? item.value : '-'}</td>
                                       <td>{item.account_id == this.state.data ? '-' : item.value}</td>
                                       <td className="text-right">{item.current}</td>
                                    </tr>
                                 )}
                                 <tr>
                                    <th colSpan="4">Saldo anterior: {reason.data.previous}</th>
                                    <th>Total ano:</th>
                                    <th className="text-right">{reason.data.value}</th>
                                 </tr>
                              </tbody>
                           </Table>
                        }
                     </CardBody>
                  </Card>
               }
            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   reason: state.reason,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...ReasonActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemReason);
