import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as releasesActions }  from '../store/ducks/releases';

import { Alert, Card, CardBody, CardFooter, CardTitle, Spinner} from 'reactstrap';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import ReleaseNotify from './ReleaseNotify';

class ReleasesNotify extends Component {
   state = {
      start: moment().add(-1, 'month').format('YYYY-MM-DD'),
      stop: moment().add(+1, 'day').format('YYYY-MM-DD')
   }

   componentDidMount() {
      if(this.props.releases.data.length === 0) {
         this.props.releasesFetchRequested(this.state.start, this.state.stop);
      }
   }

   handlePageChange = (page) => {
      this.props.releasesPage(this.state.start, this.state.stop, page);
   }

   render() {
      const { releases } = this.props;
      return (
         <Card className="panel height-100">
            <CardBody>
               <CardTitle className="heading">Lançamentos</CardTitle>
               <p className="mb-5">Acompanhe os últimos lançamentos.</p>

               {releases.loading.request &&
                  <div className="text-center py-5 releases-notify-alert">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(releases.data.length === 0 && !releases.loading.request && !releases.error) &&
                  <Alert color="light" className="text-center my-5 releases-notify-alert" >
                     Você ainda não lançou nem um documento.
                  </Alert>
               }

               {releases.error &&
                  <Alert color="light" className="text-center my-5 releases-notify-alert" >
                     Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {releases.data.map(release => <ReleaseNotify key={release.id} {...release} />)}

            </CardBody>
            <CardFooter>
               <Pagination
                  onChange={this.handlePageChange}
                  current={releases.current_page}
                  pageSize={releases.per_page}
                  total={releases.total}
                  showLessItems
                  showTitle={true}
               />
            </CardFooter>
         </Card>
      );
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   releases: state.releases
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(releasesActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ReleasesNotify);
