import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   documentationCreate: ['name'],
   documentationByIdCreate: ['name', 'id'],
   documentationCreateSucceeded: ['data'],
   documentationCreateFailed: [],

   documentationFetchRequested: [],
   documentationFetchByIdRequested: ['id'],
   documentationFetchSucceeded: ['data'],
   documentationFetchFailed: [],
   documentationPage: ['page'],
   documentationPageById: ['page', 'id'],
   documentationAttachmentCreate: ['data', 'id'],
   documentationAttachmentCreateSucceeded: ['data'],
   documentationAttachmentCreateFailed: [],

   documentationAttachmentUpdate: ['data', 'id'],
   documentationAttachmentUpdateSucceeded: ['data'],

   documentationAttachmentDelete: ['id', 'group'],
   documentationAttachmentDeleteSucceeded: ['data', 'group'],

   documentationDelete: ['id'],
   documentationDeleteSucceeded: ['data'],

   documentationModalUpdate: [],

});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
      attachment: false
   },
   error: false
};


const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});

const createAttachment = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
      attachment: true,
   }
});

const createAttachmentSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item =>
      item.id == data.data.documentation_group_id
         ? {...item, attachments: [ data.data, ...item.attachments ] }
         : {...item}
   ),
   loading: {
      ...state.loading,
      modal: true,
      attachment: false,
   }
});

const createAttachmentFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: true,
      attachment: false,
   }
});

const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true,
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      create: false,
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: false
   }
});

const modalUpdate = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
   }
});

const removeAttachmentSucceeded = (state, {data, group}) => ({
   ...state,
   data: state.data.map(
      item =>
      item.id == group
         ? {...item, attachments: item.attachments.filter(attachment => attachment.id != data.id) }
         : {...item}
   )
});

const removeSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.filter(
      item =>
      item.id != data.id
   )
});

const updateAttachmentSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item =>
      item.id == data.documentation_group_id
         ? {
            ...item,
            attachments: item.attachments.map(attachment => attachment.id == data.id ? { ...data } : { ...attachment })
         }
         : {
            ...item
         }
   ),
   loading: {
      ...state.loading,
      modal: true,
      attachment: false,
   }
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.DOCUMENTATION_ATTACHMENT_CREATE]: createAttachment,
   [Types.DOCUMENTATION_ATTACHMENT_CREATE_SUCCEEDED]: createAttachmentSucceeded,
   [Types.DOCUMENTATION_ATTACHMENT_CREATE_FAILED]: createAttachmentFailed,
   [Types.DOCUMENTATION_CREATE]: create,
   [Types.DOCUMENTATION_BY_ID_CREATE]: create,
   [Types.DOCUMENTATION_CREATE_SUCCEEDED]: createSucceeded,
   [Types.DOCUMENTATION_CREATE_FAILED]: createFailed,

   [Types.DOCUMENTATION_FETCH_REQUESTED]: requested,
   [Types.DOCUMENTATION_FETCH_BY_ID_REQUESTED]: requested,
   [Types.DOCUMENTATION_FETCH_SUCCEEDED]: succeeded,
   [Types.DOCUMENTATION_FETCH_FAILED]: failed,

   [Types.DOCUMENTATION_MODAL_UPDATE]: modalUpdate,

   [Types.DOCUMENTATION_PAGE]: null,
   [Types.DOCUMENTATION_PAGE_BY_ID]: null,

   [Types.DOCUMENTATION_ATTACHMENT_UPDATE]: createAttachment,
   [Types.DOCUMENTATION_ATTACHMENT_UPDATE_SUCCEEDED]: updateAttachmentSucceeded,

   [Types.DOCUMENTATION_ATTACHMENT_DELETE]: null,
   [Types.DOCUMENTATION_ATTACHMENT_DELETE_SUCCEEDED]: removeAttachmentSucceeded,

   [Types.DOCUMENTATION_DELETE]: null,
   [Types.DOCUMENTATION_DELETE_SUCCEEDED]: removeSucceeded,
});
