export function Release() {

   this.title = '';
   this.type = 'fiscal';
	this.date = '';
	this.attachment = '';
	this.attachment_type = '';
	this.payment = 'cash';
	this.value = '';
	this.description = '';

}

export const ReleaseTypeOptions = [
   { value: 'fiscal', label: 'Documento fiscal' },
   { value: 'contabil', label: 'Documento contábil' }
];

export const ReleasePaymentOptions = [
   { value: 'cash', label: 'Á vista' },
   { value: 'installment', label: 'Parcelado' }
];

export const ReleaseAttachmentTypeOptions = [
   { value: 'type1', label: 'Tipo 1' },
   { value: 'type2', label: 'Tipo 2' }
];
