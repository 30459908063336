import React, { Component } from 'react';

class Recover extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      return (
         <h1>Recover</h1>
      );
   }
}

export default Recover;