import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";

import logo from '../../images/logo.png';
import Avatar from './Avatar';
import Notify from './Notify';

class Station extends Component {
   
   render() {
      const { name, avatar } = this.props.user.data;
      return (
         <Navbar expand="xs">
            <Container>
               <NavbarBrand href="/"><img src={logo} alt="logo" /></NavbarBrand>
               
               <Nav className="ml-auto" navbar>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/dashboard">Meu painel</NavLink></NavItem>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/taxes">Impostos</NavLink></NavItem>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/releases">Lançamentos</NavLink></NavItem>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/reports">Relatórios</NavLink></NavItem>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/payroll">Folha de pagamento</NavLink></NavItem>
                  <NavItem><NavLink className="nav-link" activeClassName="active" to="/station/documentation">Documentação</NavLink></NavItem>
               </Nav>
              
               <div className="navbar-user">
                  <Avatar name={name} avatar={avatar} />
                  <Notify />
               </div>
            </Container>
         </Navbar>
      )
   }

}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user
});

export default connect(
   mapStateToProps
)(Station);

