import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AuthenticationActions }  from '../../../store/ducks/authentication';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import avatarDefault from '../../../images/avatar.png';

class Avatar extends Component {
   logout = () => {
      this.props.logout();
   }

   render() {
      const { name, avatar } = this.props;
      return (
         <UncontrolledDropdown className="navbar-avatar">
            <DropdownToggle>
               <div className="avatar" aria-haspopup="true" aria-expanded="false" >
                  <img src={avatar ? avatar : avatarDefault} title={name} alt="" />
               </div>
            </DropdownToggle>
            <DropdownMenu right>
               <a onClick={this.logout} className="dropdown-item" >Sair</a>
            </DropdownMenu>
         </UncontrolledDropdown>
      )
   }
}

//  mapDispatchToProps
const mapDispatchToProps = dispatch => (
   bindActionCreators(AuthenticationActions, dispatch)
);

export default connect(
   null, 
   mapDispatchToProps
)(Avatar);


