import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   sheetDocumentCreate: ['data'],
   sheetDocumentByIdCreate: ['data', 'id'],
   sheetDocumentCreateSucceeded: ['data'],
   sheetDocumentCreateFailed: [],
   sheetDocumentFetchRequested: ['start', 'stop'],
   sheetDocumentFetchByIdRequested: ['start', 'stop', 'id'],
   sheetDocumentFetchSucceeded: ['data'],
   sheetDocumentFetchFailed: [],
   sheetDocumentPage: ['start', 'stop', 'page'],
   sheetDocumentStatusUpdate: ['status', 'id'],
   sheetDocumentStatusUpdateSucceeded: ['data'],
   sheetDocumentPageById: ['start', 'stop', 'id', 'page'],
   sheetDocumentModalUpdate: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
   },
   error: false
};


const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});


const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true,
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      modal: true,
      create: false,
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const modalUpdate = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
   }
});

const statusUpdate = (state) => ({
   ...state
});

const statusUpdateSucceeded = (state, {data, id}) => ({
   ...state,
   data: state.data.map(
      item => 
      item.id === id 
         ? {...item, status: data.data } 
         : {...item}
   )
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {

   [Types.SHEET_DOCUMENT_CREATE]: create,
   [Types.SHEET_DOCUMENT_BY_ID_CREATE]: create,
   [Types.SHEET_DOCUMENT_CREATE_SUCCEEDED]: createSucceeded,
   [Types.SHEET_DOCUMENT_CREATE_FAILED]: createFailed,

   [Types.SHEET_DOCUMENT_FETCH_REQUESTED]: requested,
   [Types.SHEET_DOCUMENT_FETCH_BY_ID_REQUESTED]: requested,
   [Types.SHEET_DOCUMENT_FETCH_SUCCEEDED]: succeeded,
   [Types.SHEET_DOCUMENT_FETCH_FAILED]: failed,

   [Types.SHEET_DOCUMENT_STATUS_UPDATE]: statusUpdate,
   [Types.SHEET_DOCUMENT_STATUS_UPDATE_SUCCEEDED]: statusUpdateSucceeded,
   [Types.SHEET_DOCUMENT_MODAL_UPDATE]: modalUpdate,  
   
   [Types.SHEET_DOCUMENT_PAGE]: null,
   [Types.SHEET_DOCUMENT_PAGE_BY_ID]: null,

});
