import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import logo from '../../images/logo.png';

class Header extends Component {
  
   render() {
      return (
         <Navbar expand="lg">
            <Container>
               <NavbarBrand href="/"><img src={logo} alt="logo" /></NavbarBrand>
               
            </Container>
         </Navbar>
      );
   }

}

export default Header;