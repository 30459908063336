import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as DocumentationActions }  from '../../store/ducks/documentation';

import { Alert, Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardFooter, CardTitle, Collapse, Form, Row, Col, Input, Spinner } from 'reactstrap';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import Documentation from '../../components/Documentation';

class StationDocumentation extends Component {
   state = {
      collapse: false ,
      groupName: '',
   }

   componentDidMount() {
      if(this.props.documentation.data.length === 0) {
         this.props.documentationFetchRequested();
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();
      this.props.documentationCreate(this.state.groupName);
      this.setState({ collapse: false, groupName: '' });
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   handlePageChange = (page) => {
      this.props.documentationPage(page);
   }

   render() {
      const { documentation } = this.props;
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Documentação</BreadcrumbItem>
            </Breadcrumb>
            <Card className="panel is-marge">
               <CardHeader>
                  <CardTitle className="heading">Documentação</CardTitle>
                  <p>Envie e visualize a documentação da sua empresa sempre que precisar.</p>
                  <div className="header-content justify-content-end">
                     <Button color="link" onClick={this.toggleCollapse}>
                        {this.props.documentation.loading.create && <Spinner size="sm" className="mr-1" color="primary" />}
                        ADICIONAR GRUPO
                     </Button>
                  </div>
               </CardHeader>
               <CardBody>
                  <Collapse isOpen={this.state.collapse}>
                     <Card>
                        <CardBody>
                           <Form onSubmit={this.handleSubmit}>
                              <Row>
                                 <Col>
                                    <Input type="text" name="groupName" className="is-border" placeholder="Nome do grupo de documentos" value={this.state.groupName} onChange={this.handleInputChange} required />
                                 </Col>
                                 <Col xs="auto">
                                    <Button type="submit" color="primary">Salvar</Button>
                                 </Col>
                              </Row>
                           </Form>
                        </CardBody>
                     </Card>
                  </Collapse>

                  {documentation.loading.request &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(documentation.data.length === 0 && !documentation.loading.request && !documentation.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Você ainda não possui nem um grupo.
                     </Alert>
                  }

                  {documentation.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar seus documentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {documentation.data.map(item => <Documentation {...this.props} key={item.id} {...item} isStation={true} />)}

               </CardBody>
               <CardFooter>
                  <Pagination
                     onChange={this.handlePageChange}
                     current={documentation.current_page}
                     pageSize={documentation.per_page}
                     total={documentation.total}
                     showLessItems
                     showTitle={true}
                  />
               </CardFooter>
            </Card>
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   documentation: state.documentation
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(DocumentationActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(StationDocumentation);
