import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { Card, CardBody, Collapse,  Badge, Row, Col, Table } from 'reactstrap';
// Button,  Popover,  PopoverHeader,  PopoverBody,
import UpdateReceipt from '../../modals/UpdateReceipt';

class Taxe extends Component {
   state = {
      status: {
         pending: {
            style: 'dark',
            caption: 'Pendente'
         },
         paid: {
            style: 'success',
            caption: 'Pago'
         },
      },
      collapse: false,
      popoverOpen: false,
      modal: false
   }

   toggleModal = () => {
      this.setState({
         modal: !this.state.modal
      });
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   togglePopover = () => {
      this.setState({ popoverOpen: !this.state.popoverOpen });
   }

   render() {
      const { id, maturity, attachment, receipt, value, total, penalty, juros, status, created_at  } = this.props;

      // Status
      let statusStyle = this.state.status[status].style;
      let statusCaption = this.state.status[status].caption;

      return (
         <Card className="taxe card-carton">
            <CardBody>
               <UpdateReceipt id={id} maturity={maturity} status={this.state.modal} toogle={this.toggleModal} />
               <Row>
                  <Col sm="3" lg="2"><Moment format="DD-MM-YYYY" date={created_at} /></Col>

                  {/* <Col sm="3" lg="2">
                     <span className="taxe-question">
                        <strong>IRPJ</strong>
                        <svg id="popover1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path></svg>
                     </span>

                     <Popover placement="top" isOpen={this.state.popoverOpen} trigger="legacy" target="popover1" toggle={this.togglePopover}>
                        <PopoverHeader>Popover Title</PopoverHeader>
                        <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody>
                     </Popover>
                  </Col> */}

                  <Col sm="6" lg="6">
                     <span className="taxe-maturity">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
                        Vencimento dia <Moment className="ml-1" format="DD-MM" date={maturity} />
                     </span>
                  </Col>

                  <Col xs="4" lg="2">
                     <Badge className="mr-3" color={statusStyle} pill>{statusCaption}</Badge>
                  </Col>

                  <Col xs="8"  lg="2" className="d-flex align-items-center justify-content-end">
                     <strong className="mr-3">{total}</strong>
                     <button className={(this.state.collapse) ? ('btn-toggle active') : ('btn-toggle')} onClick={this.toggleCollapse}></button>
                  </Col>
               </Row>
            </CardBody>

            <Collapse isOpen={this.state.collapse}>
               <div className="bg-light text-center">
                  <a href={attachment} className="btn btn-primary btn-sm btn-pill" target="_black">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                     Fazer download
                  </a>
                  {receipt &&
                     <a href={receipt} className="btn btn-primary btn-sm btn-pill ml-2" target="_black">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                        Comprovante
                     </a>
                  }
                  {status !== 'paid' &&
                     <a onClick={this.toggleModal} className="btn btn-primary btn-sm btn-pill ml-2">
                        Paguei
                     </a>
                  }

               </div>
               <CardBody>
                  <Table>
                     <tbody>
                        <tr>
                           <th scope="row">Valor</th>
                           <td className="text-right">{value}</td>
                        </tr>
                        <tr>
                           <th scope="row">Multa</th>
                           <td className="text-right">{penalty}</td>
                        </tr>
                        <tr>
                           <th scope="row">Juros</th>
                           <td className="text-right">{juros}</td>
                        </tr>
                        <tr>
                           <th scope="row">Total</th>
                           <th className="text-right">{total}</th>
                        </tr>
                     </tbody>
                  </Table>
               </CardBody>
            </Collapse>
         </Card>
      );
   }
}

export default Taxe;
