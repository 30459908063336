export function Tax
	(
		type = '',
		title = '',
		maturity = '',
		value = 0,
		penalty = 0,
		juros = 0,
		description = '',
	) {

   this.type = type;
	this.title = title;
	this.maturity = maturity;
	this.attachment = '';
	this.value = value;
	this.penalty = penalty;
	this.juros = juros;
	this.description = description;

}

export const TaxTypeOptions = [
	{ value: 'tax', label: 'Imposto para pagar' },
	{ value: 'reminder', label: 'Lembrete' }
];
