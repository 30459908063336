import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as DreActions }  from '../../store/ducks/dre';
import { Link } from 'react-router-dom';

import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, UncontrolledPopover, PopoverBody, Row, Col, Alert, Spinner } from 'reactstrap';
import Select from 'react-select';

import ReportNavbar from '../../components/ReportNavbar';
import Dre from '../../components/Dre';

class StationDre extends Component {
   state = {
      start: moment().add(-1, 'year').format('YYYY'),
      stop: moment().format('YYYY'),
      years: []
   }

   componentDidMount() {
      if(this.props.dre.data.length === 0) {
         this.props.dreFetchRequested(this.state.start, this.state.stop);
      }
      let years = [];
      for(let i = 0; i < 10; i++) {
         let year = moment().add(5-i, 'year').format('YYYY');
         years = [
            { value: year, label: year },
            ...years
         ]
      }
      this.setState({
         years
      });
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      this.props.dreFetchRequested(this.state.start, this.state.stop);
   }

   handleSelectChange = (name, event) => {
      this.setState({
         [name]: event.value
      });
   }

   render() {
      const { dre } = this.props;
      var key = dre.data.length;

      var result = dre.data[key-1];
      var result_name = '';
      var result_start_color = '';
      var result_stop_color = '';
      var result_start_value = 0;
      var result_stop_value = 0;
      if (typeof result != "undefined") {
         result_name = result['final_result_name']
         result_start_value = result['final_result_start'];
         result_stop_value = result['final_result_stop'];
         result_start_color = result['final_result_start_color'] + ' p-0 text-right pr-4';
         result_stop_color = result['final_result_stop_color'] + ' pl-0 d-flex align-items-center justify-content-end dre-result-margin';
      }
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Relatórios</BreadcrumbItem>
            </Breadcrumb>
            <Card className="panel is-marge">
               <CardHeader>
                  <CardTitle className="heading">Relatórios contábeis</CardTitle>
                  <p>Selecione o relatório desejado para obter informações de sua <br />empresa com facilidade.</p>
                  <Link to={`/station/print/dre/${this.state.start}/${this.state.stop}`}>
                     <Button color="primary" size="lg">Imprimir</Button>
                  </Link>

                  <Row>
                     <Col>
                        <ReportNavbar />
                     </Col>
                     <Col xs="auto">
                        <div className="header-content">
                           <span id="filter">{this.state.start} x {this.state.stop}</span>
                           <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                              <PopoverBody>
                                 <strong className="title mb-3 d-block">Selecione a competência</strong>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">De</Col>
                                    <Col>
                                       <Select
                                          options={this.state.years}
                                          placeholder="Conta débito"
                                          value={this.state.years.filter(event => (event.value === this.state.start))}
                                          onChange={this.handleSelectChange.bind(this, 'start')}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">A</Col>
                                    <Col>
                                       <Select
                                          options={this.state.years}
                                          placeholder="Conta débito"
                                          value={this.state.years.filter(event => (event.value === this.state.stop))}
                                          onChange={this.handleSelectChange.bind(this, 'stop')}
                                       />
                                    </Col>
                                 </Row>
                                 <div className="text-center">
                                    <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                                 </div>
                              </PopoverBody>
                           </UncontrolledPopover>
                        </div>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
                  {dre.loading &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(dre.data.length === 0 && !dre.loading && !dre.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Você ainda não lançou nem um documento.
                     </Alert>
                  }

                  {dre.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {!dre.loading &&
                     <div>
                        {dre.data.filter(item => item.accounts.length !== 0).map(item => <Dre key={item.id} year1={this.state.start} year2={this.state.stop} {...item} />)}
                     </div>
                  }
                  {(dre.data.length !== 0 && !dre.loading && !dre.error) &&
                <Card className="taxe card-carton">
                  <CardBody>
                     <Row>
                        <Col xs="8" className="pr-0"><strong>{result_name}</strong></Col>
                        <Col xs="2" className={result_start_color}><strong>{result_start_value}</strong></Col>
                        <Col xs="2" className={result_stop_color}>
                           <strong className="mr-2">{result_stop_value}</strong>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            }
               </CardBody>
            </Card>
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   dre: state.dre
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(DreActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(StationDre);
