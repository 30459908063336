import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TaxesActions }  from '../../store/ducks/taxes';

import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardFooter, CardHeader, CardTitle, Spinner, Alert, Row, Col, UncontrolledPopover, PopoverBody} from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import Taxe from '../../components/Taxe';

class StationTaxes extends Component {
   state = {
      start: moment().add(-1, 'month').format('YYYY-MM-DD'),
      stop: moment().add(+1, 'day').format('YYYY-MM-DD')
   }

   componentDidMount() {
      if(this.props.taxes.data.length === 0) {
         this.props.taxesFetchRequested(this.state.start, this.state.stop);
      }
   }

   handlePageChange = (page) => {
      this.props.taxesPage(this.state.start, this.state.stop, page);
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      this.props.taxesFetchRequested(this.state.start, this.state.stop);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { taxes } = this.props;
      return (
         <div>
            <Breadcrumb>
               <BreadcrumbItem active>Impostos</BreadcrumbItem>
            </Breadcrumb>
            <Card className="panel is-marge">
               <CardHeader>
                  <CardTitle className="heading">Impostos</CardTitle>
                  <p>Pague seus impostos de maneira simples, selecione o mês, verifique os impostos abertos e <br/> baixe os boletos, pague e envie os comprovantes, tudo em um só lugar.</p> 
                  <p>Você também pode verificar no calendário os impostos a vencer.</p>

                  <Row>
                     <Col>
                        <div className="header-content">
                           <span>Impostos enviados</span>
                        </div>
                     </Col>
                     <Col xs="auto">
                        <div className="header-content">
                           <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                           <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                              <PopoverBody>
                                 <strong className="title mb-3 d-block">Selecione a competência</strong>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">De</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.start).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="align-items-center mb-2">
                                    <Col xs="2">A</Col>
                                    <Col>
                                       <DayPickerInput
                                          formatDate={formatDate}
                                          parseDate={parseDate}
                                          placeholder="Data"
                                          value={moment(this.state.stop).format('DD/MM/YYYY')}
                                          onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                          dayPickerProps={{
                                             locale: 'pt-br',
                                             localeUtils: MomentLocaleUtils,
                                          }}
                                          required
                                       />
                                    </Col>
                                 </Row>
                                 <div className="text-center">
                                    <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                                 </div>
                              </PopoverBody>
                           </UncontrolledPopover>
                        </div>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
                  {taxes.loading.request &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(taxes.data.length === 0 && !taxes.loading.request && !taxes.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Não há novos impostos
                     </Alert>
                  }

                  {taxes.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar seus lançamentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {!taxes.loading.request && taxes.data.filter(tax => (tax.type === 'tax')).map(tax => <Taxe key={tax.id} {...tax} />)}
               </CardBody>
               <CardFooter>
                  <Pagination
                     onChange={this.handlePageChange}
                     current={taxes.current_page}
                     pageSize={taxes.per_page}
                     total={taxes.total}
                     showLessItems
                     showTitle={true}
                  />
               </CardFooter>
            </Card>
         </div>
      );
   }
}


// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   taxes: state.taxes
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(TaxesActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(StationTaxes);
