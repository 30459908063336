import { createActions, createReducer } from 'reduxsauce';
import moment from 'moment';


/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   calendarFetchRequested: [],
   calendarFetchByIdRequested: ['id'],
   calendarFetchSucceeded: ['data'],
   calendarPaid: ['id'],
   calendarRemove: ['data'],
   calendarAdd: ['data'],
   calendarUpp: ['data'],
   calendarUpdate: ['data'],
   calendarDelete: ['old', 'id'],
   calendarDeleteSucceeded: ['data']
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: [],
   calendar: []
};


const succeeded = (state, {data, calendar}) => ({
   ...state,
   data: data.data,
   calendar
});

const add = (state, {data}) => ({
   ...state,
   data: [
      ...state.data,
      data.data
   ],
   calendar: [
      //new Date(data.data.maturity),
      new Date(moment(data.data.maturity)),
      ...state.calendar
   ],
});

const upp = (state, {old, data}) => {
   let loop = true;
   return {
      ...state,
      data: state.data.map(
         tax => 
         tax.id == data.data.id 
            ? { ...data.data } 
            : { ...tax }
      ),
      calendar: state.calendar.map(item => {
         if(moment(item).format('YYYY-MM-DD') === moment(old).format('YYYY-MM-DD') && loop) {
            loop = false;
            return new Date(moment(data.data.maturity));
         } else {
            return item
         }
      })
   }
}

const taxPaid = (state, {id}) => ({
   ...state,
   data: state.data.map(
      tax => 
      tax.id === id 
         ? {...tax, status: 'paid' } 
         : {...tax}
   )
});

const update = (state, {data}) => ({
   ...state,
   data: state.data.map(
      tax => 
      tax.id == data.id 
         ? { ...data } 
         : { ...tax }
   )
});

const remove = (state, {data}) => ({
   ...state,
   calendar: state.calendar.filter(date => moment(date).format('YYYY-MM-DD') != moment(data).format('YYYY-MM-DD') )
});

const deleteSucceeded = (state, {old, data}) => {
   let loop = true;
   return {
      ...state,
      data: state.data.filter(item => item.id != data.id),
      calendar: state.calendar.map(item => {
         if(moment(item).format('YYYY-MM-DD') === moment(old).format('YYYY-MM-DD') && loop) {
            loop = false;
         } else {
            return item
         }
      })
   }
};

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.CALENDAR_FETCH_REQUESTED]: null,
   [Types.CALENDAR_FETCH_BY_ID_REQUESTED]: null,
   [Types.CALENDAR_FETCH_SUCCEEDED]: succeeded,
   [Types.CALENDAR_PAID]: taxPaid,
   [Types.CALENDAR_REMOVE]: remove,
   [Types.CALENDAR_ADD]: add,
   [Types.CALENDAR_UPP]: upp,
   [Types.CALENDAR_UPDATE]: update,
   [Types.CALENDAR_DELETE]: null,
   [Types.CALENDAR_DELETE_SUCCEEDED]: deleteSucceeded,
});

