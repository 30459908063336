import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as SheetReportActions }  from '../../store/ducks/sheetReport';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { isSystem } from "../../services/auth";

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, InputGroup, InputGroupAddon, Input, Spinner, CustomInput } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import { SheetReport } from '../../class/sheetReport';

let update = false;
let submit = false;

class CreateSheetReport extends Component {
   state = {
      data: new SheetReport()
   }

   componentWillReceiveProps() {
      if(!submit) {
         update = false;
      }
   }

   handleCancel = () => {
      this.setState({ 
         data: new SheetReport()
      });
      this.props.toogle();
   }

   componentDidUpdate() {
      if(this.props.sheetReport.loading.modal && this.props.status) {
         submit = false;
         this.props.sheetReportModalUpdate();
         this.setState({ 
            data: new SheetReport()
         });
         this.props.toogle();
      }

      if(this.props.edite && !update) {
         update = true;
         const { title, date } = this.props.edite;
         this.setState({ 
            data: new SheetReport(title, new Date (moment(date)))
         });
      }  
      
      if(!this.props.edite && !update && !this.props.status) {
         update = true;
         this.setState({ 
            data: new SheetReport()
         });
      }  
   }

   handleSubmit = (event) => {
      event.preventDefault();
      submit = true;
      if(this.props.edite) {
         if(isSystem) {
            const id = this.props.userSelected.data.id;
            this.props.sheetReportByIdUpdate(this.state.data, this.props.edite.id, id);
         } else {
            this.props.sheetReportUpdate(this.state.data, this.props.edite.id);
         }
      } else {
         if(isSystem) {
            const id = this.props.userSelected.data.id;
            this.props.sheetReportByIdCreate(this.state.data, id);
         } else {
            this.props.sheetReportCreate(this.state.data);
         }
      }
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ 
         data: { 
            ...this.state.data,
            [name]: value 
         }
      });
   }

   handleFileChange = (event) => {
      const { name } = event.target;
      this.setState({ 
         data: {
            ...this.state.data,
            [name]: event.target.files[0]
         }
      });
   }

   handleDayPickerInputChange = (event) => {
      this.setState({ 
         data: {
            ...this.state.data,
            date: event
         }
      });
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.handleCancel}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">{this.props.edite ? ('Atualizar Upload') : ('Upload')}</h3>
               <p className="m-0">Importe um arquivo para suas documentações</p>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  <FormGroup>
                     <Input 
                        type="text"
                        name="title"
                        placeholder="Nome do documento"
                        value={this.state.data.title}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>

                  <FormGroup>
                     <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder="Data" 
                        value={this.state.data.date}
                        onDayChange={this.handleDayPickerInputChange}
                        dayPickerProps={{
                           locale: 'pt-br',
                           localeUtils: MomentLocaleUtils,
                        }}
                        required
                     />
                  </FormGroup>

                  <FormGroup>
                     <CustomInput 
                        type="file" 
                        id="file" 
                        accept=".png, .jpeg, .jpg, .gif, .doc, .docx, .xlsx, .xls, .zip, .rar, .pdf, .ppt, .pptx, .csv, .txt"
                        name="attachment"
                        className={this.state.data.attachment ? 'active' : ''}
                        onChange={this.handleFileChange}
                        label={this.state.data.attachment ? this.state.data.attachment.name : 'Anexe um arquivo de seu computador'}
                     />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.handleCancel} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.props.sheetReport.loading.create && <Spinner color="light" size="sm" className="mr-2" />}
                     {this.props.edite ? ('Atualizar') : ('Adicionar')}
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      );
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   sheetReport: state.sheetReport,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...SheetReportActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(CreateSheetReport);