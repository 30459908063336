export function Account(
   title = '',
   code = '',
   account = '',
   synthetic = '',
   balancete = 0,
   swing = 0,
   daily = 0,
   reason = 0,
   dre = 0,
   dafuramento = 0,
   dre_calc_formula_auto = 0,
   dre_grupo1 = 0,
   dre_grupo2 = 0,
   dre_operador = 0
) {

   this.title = title;
	this.code = code;
	this.account = account;
   this.synthetic = Number(synthetic);
   this.balancete = Number(balancete);
   this.swing = Number(swing);
   this.daily = Number(daily);
   this.reason = Number(reason);
   this.dre = Number(dre);
   this.dafuramento = Number(dafuramento);
   this.dre_calc_formula_auto = Number(dre_calc_formula_auto);
   this.dre_grupo1 = Number(dre_grupo1);
   this.dre_grupo2 = Number(dre_grupo2);
   this.dre_operador = Number(dre_operador);

}

export const AccountTypeOptions = [
   { value: 'debit', label: 'Debito' },
   { value: 'credit', label: 'Crédito' }
];

export const TypeOptions = [
   { value: 2, label: 'Resultado DRE' },
   { value: 1, label: 'Sintétíca' },
   { value: 0, label: 'Analitica' }
];
