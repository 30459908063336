import React, { Component } from 'react';

import { Nav, NavItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";

class ReportNavbar extends Component {

   render() {
      return (
         <Nav className="card-header-tabs" tabs>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/balancete">
                  Balancete
                  <FontAwesomeIcon id="doubt1" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt1">

                     <PopoverBody>Demonstração mais completa que inclui todas as contas que depois são separadas em Balanço e DRE. Onde é possível fazer uma análise geral da situação da empresa.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/dre">
                  DRE
                  <FontAwesomeIcon id="doubt2" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt2">

                     <PopoverBody>Demonstração do Resultado do exercício. Relatório que demonstra o efetivo resultado da empresa em um determinado período.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/swing">
                  Balanço
                  <FontAwesomeIcon id="doubt3" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt3">

                     <PopoverBody>Balanço patrimonial composto pelas contas de patrimônio, ativo, passivo, patrimônio líquido, é uma demonstração contábil que evidencia os bens, direitos e deveres da empresa em um determinado período.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/daily">
                  Diário
                  <FontAwesomeIcon id="doubt4" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt4">

                     <PopoverBody>Consulte os Lançamentos contábeis por dia sem separação de conta, todos os lançamentos efetuados em uma determinada data.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/reason">
                  Razão
                  <FontAwesomeIcon id="doubt5" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt5">

                     <PopoverBody>Consulte os Lançamentos contábeis por conta classificados por ordem de dia.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink className="nav-link" activeClassName="active" to="/station/reports/revenues">
                  Faturamento
                  <FontAwesomeIcon id="doubt6" icon={faQuestionCircle} />
                  {
                  <UncontrolledPopover trigger="hover" placement="top" target="doubt6">

                     <PopoverBody>Relatório que demostra o faturamento (vendas ou serviços prestados) em um determinado período.</PopoverBody>
                  </UncontrolledPopover>  }
               </NavLink>
            </NavItem>
         </Nav>
      )
   }

}

export default ReportNavbar;

