import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Navbar, NavbarBrand } from 'reactstrap';

import logo from '../../images/logo.png';
import Avatar from './Avatar';
import Notify from './Notify';

class System extends Component {
   
   render() {
      const { name, avatar } = this.props.user.data;
      return (
         <Navbar expand="xs">
            <Container>
               <NavbarBrand href="/"><img src={logo} alt="logo" /></NavbarBrand>
               
              
               <div className="navbar-user">
                  <Avatar name={name} avatar={avatar} />
                  <Notify />
               </div>
            </Container>
         </Navbar>
      )
   }

}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user
});

export default connect(
   mapStateToProps
)(System);

