import React, { Component } from 'react';
import Moment from 'react-moment';
import { Button, Card, CardBody, Collapse,  Badge, Row, Col, Table, UncontrolledPopover, PopoverBody } from 'reactstrap';
import 'moment/locale/pt';
import CurrencyFormat from 'react-currency-format';

import EditReport from '../../modals/EditReport';

class AccountingEntry extends Component {
    state = {
        collapse: false,
        modalReport: false,
        delete: false
    }

    componentDidMount() {
        if(this.props.collapse) {
            this.setState({ collapse: this.props.collapse });
        }
    }

    toggleCollapse() {
        this.setState({ collapse: !this.state.collapse });
    }

   handleDelete(id) {
       this.props.accountingEntriesDelete(id);
   }

   toggleModalReport() {
      this.setState({
         modalReport: !this.state.modalReport
      });
   }

   toggleDelete() {
       this.setState({delete: !this.state.delete});
   }

   render() {
       return (
           <Card className="panel" key={this.props.data.id}>
                {this.state.modalReport && <EditReport {...this.props} edite data={this.props.data} status={this.state.modalReport} toogle={this.toggleModalReport.bind(this)} />}
                <CardBody className="body-accounts-entries">
                    <Row>
                      <div  className="px-1 width-10">{this.props.data.accounts_group_title}</div>
                      <div  className="px-1 width-12">{this.props.data.account_title}</div>
                      <div  className="px-1 width-17">{this.props.data.account_code}</div>
                      <div  className="px-1 width-12">{this.props.data.account_contrap_title}</div>
                      <div  className="px-1 width-17">{this.props.data.contrap_code}</div>
                      <div  className="px-2 text-center width-3">{this.props.data.sequence}</div>
                      <div  className="px-1 width-15">

                            <CurrencyFormat
                                value={this.props.data.value}
                                displayType={'text'}
                                fixedDecimalScale
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'R$ '} />

                      </div>
                      <div  className="p-0">
                        <Moment format="DD/MM/YYYY" date={this.props.data.date} />
                        <Button color="" id={`popover-${this.props.data.id}`} className="btn-popover ml-2">
                        <svg aria-hidden="true" height="13" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"></path></svg>
                        </Button>
                        <UncontrolledPopover trigger="legacy" placement="top" target={`popover-${this.props.data.id}`}>
                            {!this.state.delete && <PopoverBody>
                                <Button color="primary" outline className="mr-2" onClick={this.toggleModalReport.bind(this, this.props.data)}>
                                <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                Editar
                                </Button>
                                <Button color="primary" outline onClick={this.toggleDelete.bind(this)}>
                                <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                                Apagar
                                </Button>
                            </PopoverBody>}
                            {this.state.delete && <PopoverBody>
                                <Button color="primary" outline className="mr-2" onClick={this.toggleDelete.bind(this)}>Cancelar</Button>
                                <Button color="danger" outline onClick={this.handleDelete.bind(this, this.props.data.id)}>Confirmar</Button>
                            </PopoverBody>}
                        </UncontrolledPopover>
                    </div>
                   </Row>
                </CardBody>
            </Card>
        );
    }
}

export default AccountingEntry;
