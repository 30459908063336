import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ReleasesActions } from '../../store/ducks/releases';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { Button, Card, CardBody, Spinner, Alert, Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import Release from '../../components/Release';

class SystemReleases extends Component {
   state = {
      start: moment().add(-1, 'month').format('YYYY-MM-DD'),
      stop: moment().add(+1, 'day').format('YYYY-MM-DD'),
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.releasesFetchByIdRequested(this.state.start, this.state.stop, id);
   }

   handlePageChange = (page) => {
      const id = this.props.userSelected.data.id;
      this.props.releasesFetchByIdRequested(this.state.start, this.state.stop, id, page);
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      const id = this.props.userSelected.data.id;
      this.props.releasesFetchByIdRequested(this.state.start, this.state.stop, id);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { releases } = this.props;

      return (
         <Card className="panel">

            <div className="text-center pt-4">
               <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
               <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                  <PopoverBody>
                     <strong className="title mb-3 d-block">Selecione a competência</strong>
                     <Row className="align-items-center mb-2">
                        <Col xs="2">De</Col>
                        <Col>
                           <DayPickerInput
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder="Data"
                              value={moment(this.state.start).format('DD/MM/YYYY')}
                              onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                              dayPickerProps={{
                                 locale: 'pt-br',
                                 localeUtils: MomentLocaleUtils,
                              }}
                              required
                           />
                        </Col>
                     </Row>
                     <Row className="align-items-center mb-2">
                        <Col xs="2">A</Col>
                        <Col>
                           <DayPickerInput
                              formatDate={formatDate}
                              parseDate={parseDate}
                              placeholder="Data"
                              value={moment(this.state.stop).format('DD/MM/YYYY')}
                              onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                              dayPickerProps={{
                                 locale: 'pt-br',
                                 localeUtils: MomentLocaleUtils,
                              }}
                              required
                           />
                        </Col>
                     </Row>
                     <div className="text-center">
                        <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                     </div>
                  </PopoverBody>
               </UncontrolledPopover>
            </div>

            <CardBody>
               {releases.loading.request &&
                  <div className="text-center py-5">
                     <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
               }

               {(releases.data.length === 0 && !releases.loading.request && !releases.error) &&
                  <Alert color="light" className="text-center my-5" >
                     Usuário não relizou nem um lançamento.
                  </Alert>
               }

               {releases.error &&
                  <Alert color="light" className="text-center my-5" >
                     Por favor verifique sua conexão com a internet ou tente mais tarde.
                  </Alert>
               }

               {!releases.loading.request && releases.data.map(release => <Release key={release.id} {...release} />)}


               <Pagination
                  onChange={this.handlePageChange}
                  current={releases.current_page}
                  pageSize={releases.per_page}
                  total={releases.total}
                  showLessItems
                  showTitle={true}
               />
            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   releases: state.releases,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...ReleasesActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemReleases);
