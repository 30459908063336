import React, { Component } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/pt';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CalendarActions }  from '../../store/ducks/calendar';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { 
   Card,
   CardBody,
   CardHeader,
   Spinner,
   Alert
} from 'reactstrap';

import Calendar from '../../components/Calendar';
import TaxeSystem from '../../components/TaxeSystem';

class SystemDashboard extends Component {
   state = {
      day: new Date(),
      loading: true
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;
      this.props.calendarFetchByIdRequested(id);
      this.setDayTaxes();
   }

   componentDidUpdate() {
      this.setDayTaxes();
   }

   setDayTaxes = () => {
      if(this.props.calendar.calendar.length !== 0 && this.state.loading) {
         this.setState({ day: this.props.calendar.calendar[0], loading: false });
      } 
   }

   handleDayClick = (day) => {
      let date = this.props.calendar.calendar.filter(date => moment(date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'))[0];

      if(date) {
         this.setState({ day: date }); 
      }
   }

   render() {
      let calendar = this.props.calendar.data.filter(item => 
         moment(item.maturity).format('YYYY-MM-DD') === moment(this.state.day).format('YYYY-MM-DD')
      );

      if(calendar.length === 0 && moment(this.state.day).format('YYYY-MM-DD') !== moment(new Date()).format('YYYY-MM-DD')){
         this.setState({ day: new Date() });
      } 

      return (
         <div className="d-flex flex-column height-100">
            <Card className="panel mb-4">
               <CardBody>
                  <Calendar datas={this.props.calendar.calendar} handleDayClick={this.handleDayClick} />
               </CardBody>
            </Card>

            {!this.state.day &&
               <div className="system-taxes-spinner">            
                  <Spinner style={{ width: '3rem', height: '3rem' }} />
               </div>
            }

            {this.state.day && 
               <Card className="panel is-taxes flex-grow-1">
                  <CardHeader>
              
                     <h2 className="heading"><Moment format="DD \d\e MMMM" date={this.state.day} /></h2>
                  </CardHeader>
                  <CardBody>
                     {calendar.length === 0 && 
                        <Alert color="light" className="text-center my-5" >
                           Não há lembretes ou impostos lançados.
                        </Alert>
                     }
                     {calendar.map(tax => <TaxeSystem key={tax.id} {...tax} />)}
                  </CardBody>
               </Card>
            }
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   calendar: state.calendar,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...CalendarActions, ...UserSelectedActions}, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(SystemDashboard);