import api from './api';

export const balanceteRequest = (start, stop, id) => {
   let page = `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/report/user/${id}/balancete${page}`);
   }
   return api.get(`/api/report/balancete${page}`);
}

export const swingRequest = (start, stop, id) => {
   let page = `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/report/user/${id}/swing${page}`);
   }
   return api.get(`/api/report/swing${page}`);
}

export const dreRequest = (start, stop, id) => {
   let page = `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/report/user/${id}/dre${page}`);
   }
   return api.get(`/api/report/dre${page}`);
}

export const dailyRequest = (start, stop, id) => {
   let page = `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/report/user/${id}/daily${page}`);
   }
   return api.get(`/api/report/daily${page}`);
}

export const revenuesRequest = (start, stop, id) => {
   let page = `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/report/user/${id}/revenues${page}`);
   }
   return api.get(`/api/report/revenues${page}`);
}

export const reasonRequestAccounts = (id) => {
   if(id) {
      return api.get(`/api/report/user/${id}/accounts`);
   }
   return api.get(`/api/report/accounts`);
}

export const reasonRequest = (start, stop, id, user) => {
   let page = `?start=${start}&stop=${stop}`;
   if(user) {
      return api.get(`/api/report/reason/${id}/user/${user}${page}`);
   }
   return api.get(`/api/report/reason/${id}${page}`);
}

export const accountingEntriesRequest = (start, stop, id, limit) => {
   let page = `?start=${start}&stop=${stop}&limit=${limit}`;
   if (id) {
      return api.get(`/api/report/user/${id}/accounting-entries${page}`);
   }
}

export const accountingEntriesDelete = (id) => {
   return api.delete(`/api/report/${id}`);
}

export const accountingEntriesUpdate = (data, id) => {
	const params = new FormData();

	Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);
	});

	return api.post(`/api/report/${id}/update`, params);
}
