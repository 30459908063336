import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CalendarActions }  from '../store/ducks/calendar';

import Moment from 'react-moment';

import { Card, CardBody, Badge, Button, Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';

import CreateTaxes from '../modals/CreateTaxes';

class TaxeSystem extends Component {
   state = {
      status: {
         pending: {
            style: 'dark',
            caption: 'Pendente'
         },
         paid: {
            style: 'success',
            caption: 'Pago'
         },
      },
      modal: false,
      edite: '',
      delete: false
   }

   toggleModal = (id = null) => {
      if(id) {
         this.setState({
            modal: !this.state.modal,
            edite: id,
         });
      } else {
         this.setState({
            edite: '',
            modal: !this.state.modal
         });
      }
   }

   handleDelete = (old, id) => {
      this.props.calendarDelete(old, id);
   }

  toggleDelete() {
      this.setState({delete: !this.state.delete});
  }


   render() {
      const { id, title, maturity, total, status, type, created_at, receipt  } = this.props;

      // Status
      let statusStyle = this.state.status[status].style;
      let statusCaption = this.state.status[status].caption;

      return (
         <div>
         {this.state.modal && <CreateTaxes key={`modal-${id}`} id={id} edite={this.state.edite} status={this.state.modal} toogle={this.toggleModal}/>}
         {type === 'reminder' ? (
            <Card className="taxe card-carton">
               <CardBody>
                  <Row>
                     <Col xs="2"><Moment format="DD-MM-YYYY" date={created_at} /></Col>
                     <Col xs="9"><strong>{title}</strong></Col>
                     <Col xs="1">
                        <Button color="" id={'popover-'+id} className="btn-popover ml-2">
                           <svg aria-hidden="true" height="13" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"></path></svg>
                        </Button>
                        <UncontrolledPopover trigger="legacy" placement="top" target={'popover-'+id}>
                           {!this.state.delete && <PopoverBody>
                              <Button color="primary" outline className="mr-2" onClick={this.toggleModal.bind(this, id)}>
                                 <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                 Editar
                              </Button>
                              <Button color="primary" outline onClick={this.toggleDelete.bind(this)}>
                                 <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                                 Apagar
                              </Button>
                           </PopoverBody>}
                           {this.state.delete && <PopoverBody>
                               <Button color="primary" outline className="mr-2" onClick={this.toggleDelete.bind(this)}>Cancelar</Button>
                               <Button color="danger" outline onClick={this.handleDelete.bind(this, maturity, id)}>Confirmar</Button>
                           </PopoverBody>}
                        </UncontrolledPopover>
                     </Col>
                  </Row>
               </CardBody>
            </Card>
         ) : (
            <Card className="taxe card-carton">
               <CardBody>
                  <Row>
                     <Col xs="2"><Moment format="DD-MM-YYYY" date={created_at} /></Col>

                     <Col xs="6">
                        <span className="taxe-maturity d-inline">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
                           Vencimento dia <Moment className="ml-1" format="DD-MM" date={maturity} />
                        </span>

                        {receipt &&
                           <a href={receipt} className="btn btn-primary btn-sm btn-pill ml-5 d-inline" target="_black">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                                 Comprovante
                           </a>
                        }

                     </Col>

                     <Col xs="4" className="d-flex align-items-center justify-content-end">
                        <strong className="mr-3">{total}</strong>
                        <Badge className="mr-3" color={statusStyle} pill>{statusCaption}</Badge>
                        <Button color="" id={'popover-'+id} className="btn-popover ml-2">
                           <svg aria-hidden="true" height="13" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"></path></svg>
                        </Button>
                        <UncontrolledPopover trigger="legacy" placement="top" target={'popover-'+id}>
                           {!this.state.delete && <PopoverBody>
                              <Button color="primary" outline className="mr-2" onClick={this.toggleModal.bind(this, id)}>
                                 <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                 Editar
                              </Button>
                              <Button color="primary" outline onClick={this.toggleDelete.bind(this)}>
                                 <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                                 Apagar
                              </Button>
                           </PopoverBody>}
                           {this.state.delete && <PopoverBody>
                               <Button color="primary" outline className="mr-2" onClick={this.toggleDelete.bind(this)}>Cancelar</Button>
                               <Button color="danger" outline onClick={this.handleDelete.bind(this, maturity, id)}>Confirmar</Button>
                           </PopoverBody>}
                        </UncontrolledPopover>
                     </Col>
                  </Row>
               </CardBody>
            </Card>
         )
         }
         </div>
      );
   }
}

//  mapDispatchToProps
const mapDispatchToProps = dispatch => (
   bindActionCreators(CalendarActions, dispatch)
);

export default connect(
   null,
   mapDispatchToProps
)(TaxeSystem);
