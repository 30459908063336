import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Card, CardBody, CardHeader, Spinner, Row, Col, Alert, UncontrolledPopover, PopoverBody, Container} from 'reactstrap';

import logo from '../../../images/logo.png';

import Dre from '../../../components/Dre';

class SystemPrintDre extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }
   render() {
      const { dre, match } = this.props;
      var key = dre.data.length;

      var result = dre.data[key-1];
      var result_name = '';
      var result_start_value = 0;
      var result_stop_value = 0;
      if (typeof result != "undefined") {
         result_name = result['final_result_name']
         result_start_value = result['final_result_start'];
         result_stop_value = result['final_result_stop'];
      }
      return (
         <div className="print">
            <Container>
               <Row className="py-3 align-items-center">
                  <Col xs="auto"><img src={logo} alt="logo" /></Col>
                  <Col>
                     <h4 className="m-0">Dre</h4>
                     { this.props.userSelected.data.company.social_name } - { this.props.userSelected.data.company.cnpj }
                  </Col>
                  <Col className="text-right">
                     <Link to="/system/reports/dre">Voltar</Link>
                  </Col>
               </Row>
               {dre.data.filter(item => item.accounts.length !== 0).map(item => <Dre collapse={true} key={item.id} year1={match.params.start} year2={match.params.stop} {...item} />)}
               <Card className="taxe card-carton">
                  <CardBody>
                     <Row>
                        <Col xs="8" className="pr-0"><strong>{result_name}</strong></Col>
                        <Col xs="2" className="p-0 text-right pr-4"><strong>{result_start_value}</strong></Col>
                        <Col xs="2" className="pl-0 d-flex align-items-center justify-content-end dre-result-margin">
                           <strong className="mr-2">{result_stop_value}</strong>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Container>
         </div>
      );
   }
}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user,
   dre: state.dre,
   userSelected: state.userSelected
});

export default connect(
   mapStateToProps,
   null
)(SystemPrintDre);
