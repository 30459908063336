import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   userFetchRequested: [],
   userFetchSucceeded: ['data'],
   userFetchFailed: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: {},
   error: true
}

const requested = (state) => ({
   ...state,
   error: false
});

const succeeded = (state, {data}) => ({
   data: data,
   error: false
});

const failed = (state) => ({
   data: {},
   error: true
});


/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.USER_FETCH_REQUESTED]: requested,
   [Types.USER_FETCH_SUCCEEDED]: succeeded,
   [Types.USER_FETCH_FAILED]: failed
});