import { createActions, createReducer} from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   releasesCreate: ['data'],
   releasesCreateSucceeded: ['data'],
   releasesCreateFailed: [],
   releasesFetchRequested: ['start', 'stop'],
   releasesPage: ['start', 'stop', 'page'],
   releasesPageById: ['start', 'stop', 'id', 'page'],
   releasesFetchSucceeded: ['data'],
   releasesFetchFailed: [],
   releasesFetchByIdRequested: ['start', 'stop', 'id', 'page'],
   releasesModalUpdate: [],
   releasesStatusUpdate: ['status', 'id'],
   releasesStatusUpdateSucceeded: ['data'],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
   },
   error: false
};

const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
      create: true,
   }
});

const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      modal: true,
      create: false,
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: false,
   }
});


const modalUpdate = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
   }
});

const statusUpdate = (state) => ({
   ...state
});

const statusUpdateSucceeded = (state, {data, id}) => ({
   ...state,
   data: state.data.map(
      release => 
      release.id === id 
         ? {...release, status: data.data } 
         : {...release}
   )
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.RELEASES_CREATE]: create,
   [Types.RELEASES_CREATE_SUCCEEDED]: createSucceeded,
   [Types.RELEASES_CREATE_FAILED]: createFailed,
   [Types.RELEASES_FETCH_REQUESTED]: requested,
   [Types.RELEASES_FETCH_SUCCEEDED]: succeeded,
   [Types.RELEASES_FETCH_FAILED]: failed,
   [Types.RELEASES_FETCH_BY_ID_REQUESTED]: requested,
   [Types.RELEASES_MODAL_UPDATE]: modalUpdate,   
   [Types.RELEASES_STATUS_UPDATE]: statusUpdate,
   [Types.RELEASES_STATUS_UPDATE_SUCCEEDED]: statusUpdateSucceeded,
   [Types.RELEASES_PAGE]: null,
   [Types.RELEASES_PAGE_BY_ID]: null,
});