import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   accountFetchRequested: ['id'],
   accountFetchSucceeded: ['data'],
   accountFetchFailed: [],
   accountCreate: ['data', 'id', 'user'],
   accountCreateSucceeded: ['data'],
   accountCreateFailed: [],
   accountCreateGroup: ['data', 'id'],
   accountCreateGroupSucceeded: ['data'],
   accountDelete: ['id', 'group'],
   accountDeleteSucceeded: ['data', 'group'],
   accountUpdate: ['data', 'id', 'user', 'group'],
   accountUpdateSucceeded: ['data', 'group'],
   accountModalUpdate: [],
   groupDelete: ['id'],
   groupDeleteSucceeded: ['id'],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
   },
   error: false
};

// Request
const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});

// Create
const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
      create: true
   }
});

const createSucceededGroup = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item => 
      item.id == data.accounts_group_id 
         ? {...item, accounts: [ ...item.accounts, { ...data } ] } 
         : {...item}
   ),
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

// Update
const updateSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      item => 
      item.id == data.accounts_group_id
         ? {
            ...item,
            accounts: item.accounts.map(
               account => 
               account.id === data.id
               ? {...data} 
               : {...account}
            )
         }
         : {...item}
   ),
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const modalUpdate = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false,
   }
});

const deleteSucceeded = (state, {data, group}) => ({
   ...state,
   data: state.data.map(
      item => 
      item.id == group 
         ? { ...item, accounts: item.accounts.filter(account => account.id != data.id) } 
         : { ...item }
   )
});

const groupDeleteSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.filter(item => item.id != data.id)  
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.ACCOUNT_FETCH_REQUESTED]: requested,
   [Types.ACCOUNT_FETCH_SUCCEEDED]: succeeded,
   [Types.ACCOUNT_FETCH_FAILED]: failed,
   [Types.ACCOUNT_CREATE]: create,
   [Types.ACCOUNT_CREATE_GROUP]: create,
   [Types.ACCOUNT_CREATE_GROUP_SUCCEEDED]: createSucceededGroup,
   [Types.ACCOUNT_CREATE_SUCCEEDED]: createSucceeded,
   [Types.ACCOUNT_CREATE_FAILED]: createFailed,
   [Types.ACCOUNT_DELETE]: null,
   [Types.ACCOUNT_DELETE_SUCCEEDED]: deleteSucceeded,
   [Types.ACCOUNT_UPDATE]: create,
   [Types.ACCOUNT_UPDATE_SUCCEEDED]: updateSucceeded,
   [Types.ACCOUNT_MODAL_UPDATE]: modalUpdate, 
   [Types.GROUP_DELETE_SUCCEEDED]: groupDeleteSucceeded, 
});

