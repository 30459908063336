export function User(
   name = '',
	cpf = '',
	phone_primary = '',
	phone_secondary = '',
	email = '',
	email_guides = '',
	cep = '',
   city = '',
   address = '',
   number = '',
   state = '',
   district = '',
   complement = '',
   social_name = '',
   fantasy_name = '',
   cnpj = '',
   cnae_primary = '',
   cnae_secondary = '',
   payment_type = '',
   payment_date = '',
   contract_term = '',
   contract_start = '',
   contract_finish = '',
   comments = '',
   status = 'active',
) {

   this.name = name;
	this.cpf = cpf;
	this.phone_primary = phone_primary;
	this.phone_secondary = phone_secondary;
	this.email = email;
	this.password = '';
	this.email_guides = email_guides;
	this.cep = cep;
   this.city = city;
   this.address = address;
   this.number = number;
   this.state = state;
   this.district = district;
   this.complement = complement;
   this.social_name = social_name;
   this.fantasy_name = fantasy_name;
   this.cnpj = cnpj;
   this.cnae_primary = cnae_primary;
   this.cnae_secondary = cnae_secondary;
   this.payment_type = payment_type;
   this.payment_date = payment_date;
   this.contract_term = contract_term;
   this.contract_start = contract_start;
   this.contract_finish = contract_finish;
   this.comments = comments;
   this.status = status;
   this.avatar = '';

}

export const UserPaymentOptions = [
   { value: 'ticket', label: 'Boleto' },
   { value: 'deposit', label: 'Depósito' },
   { value: 'card', label: 'Cartão' }
]

export const UserStatesOptions = [
   { value: 'AC', label: 'Acre' },
   { value: 'AL', label: 'Alagoas' },
   { value: 'AP', label: 'Amapá' },
   { value: 'AM', label: 'Amazonas' },
   { value: 'BA', label: 'Bahia' },
   { value: 'CE', label: 'Ceará' },
   { value: 'DF', label: 'Distrito Federal' },
   { value: 'ES', label: 'Espírito Santo' },
   { value: 'GO', label: 'Goiás' },
   { value: 'MA', label: 'Maranhão' },
   { value: 'MT', label: 'Mato Grosso' },
   { value: 'MS', label: 'Mato Grosso do Sul' },
   { value: 'MG', label: 'Minas Gerais' },
   { value: 'PA', label: 'Pará' },
   { value: 'PB', label: 'Paraíba' },
   { value: 'PR', label: 'Paraná' },
   { value: 'PE', label: 'Pernambuco' },
   { value: 'PI', label: 'Piauí' },
   { value: 'RJ', label: 'Rio de Janeiro' },
   { value: 'RN', label: 'Rio Grande do Norte' },
   { value: 'RS', label: 'Rio Grande do Sul' },
   { value: 'RO', label: 'Rondônia' },
   { value: 'RR', label: 'Roraima' },
   { value: 'SC', label: 'Santa Catarina' },
   { value: 'SP', label: 'São Paulo' },
   { value: 'SE', label: 'Sergipe' },
   { value: 'TO', label: 'Tocantins' },
];
