import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as NotificationsActions }  from '../../../store/ducks/notifications';
import Parser from 'html-react-parser';

import { Card, CardBody, CardTitle, ButtonDropdown, DropdownToggle, DropdownMenu, Alert, Spinner } from 'reactstrap';

class Notify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dropdown: false
      };
   }

   toggleDropdown = () => {
      this.setState({ dropdown: !this.state.dropdown });
   }

   componentDidMount() {
      this.props.notificationsFetchRequested();
   }

   render() {
      const { notifications } = this.props;
      return (
         <div>
            <ButtonDropdown className="navbar-notifications" isOpen={this.state.dropdown} toggle={this.toggleDropdown}>
               <DropdownToggle>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"></path></svg>
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-card" right>
                  <CardTitle className="heading">Notificações</CardTitle>
                  <p>Acompanhe as últimas notificaçóes de seus clientes.</p>

                  {notifications.loading &&
                     <div className="text-center py-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                     </div>
                  }

                  {(notifications.data.length === 0 && !notifications.loading && !notifications.error) &&
                     <Alert color="light" className="text-center my-5" >
                        Não há novas notificações
                     </Alert>
                  }

                  {notifications.error &&
                     <Alert color="light" className="text-center my-5" >
                        Encontramos dificuldades em recuperar suas notificações, por favor verifique sua conexão com a internet ou tente mais tarde.
                     </Alert>
                  }

                  {!notifications.loading &&
                     <div>
                        {notifications.data.map(item =>
                           <Card key={item.id}>
                              <CardBody className="notifications-text">
                                 {Parser(item.message)}
                                 <Moment className="notifications-time" format="DD-MM-YYYY" date={item.created_at} />
                              </CardBody>
                           </Card>
                        )}
                     </div>
                  }
               </DropdownMenu>
            </ButtonDropdown>
            <div className={(this.state.dropdown) ? ('bg-overlay active') : ('bg-overlay')}></div>
         </div>
      );
   }
};

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   notifications: state.notifications
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(NotificationsActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Notify);
