import api from './api';

export const TOKEN_KEY = "@airbnb-Token";
export const TYPER_KEY = "@airbnb-Typer";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const isStation = () => localStorage.getItem(TYPER_KEY) === 'station';
export const isSystem = () => localStorage.getItem(TYPER_KEY) === 'system';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getTyper = () => localStorage.getItem(TYPER_KEY);

export const login = (email, password) => {
	return api.post("/api/auth", { email, password }).then(response => {
		localStorage.setItem(TOKEN_KEY, response.data.token);
	});
};

export const fetchUser = () => {
	return api.get('/api/user').then(response => {
		localStorage.setItem(TYPER_KEY, response.data.user.type);
		return response;
	});
}

export const setTyper = typer => {
  localStorage.setItem(TYPER_KEY, typer);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TYPER_KEY);
};

export const getLogout = () => {
	return api.get('/api/logout');
}