import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/pt';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TaxesActions }  from '../../store/ducks/taxes';
import { Creators as CalendarActions }  from '../../store/ducks/calendar';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Spinner, CustomInput } from 'reactstrap';

class UpdateReceipt extends Component {
   state = {
      data: ''
   }

   componentDidUpdate() {
      if(this.props.taxes.loading.modal && this.props.status) {
         this.props.taxesModalUpdate();
         this.handleCancel();
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();
   
      this.handleTaxPaid();
   }

   handleCancel = () => {
      this.setState({ data: '' });
      this.props.toogle();
   }

   handleFileChange = (event) => {
      this.setState({ 
         data: event.target.files[0]
      });
   }

   handleTaxPaid = () => {
      this.props.taxPaid(this.props.id, this.state.data);
      this.props.calendarPaid(this.props.id);
      
      let calendarcount = this.props.calendar.data.filter(item => 
         (moment(this.props.maturity).format('YYYY-MM-DD') === moment(item.maturity).format('YYYY-MM-DD')) && (item.status === 'pending'));

      if(calendarcount.length === 1) {
         this.props.calendarRemove(this.props.maturity);
         if(this.props.dayToogle){
            this.props.dayToogle();
         }
      }
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">Upload do comprovante</h3>
               <p className="m-0">Importe o comprovante do pagamento.</p>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  <FormGroup>
                     <CustomInput 
                        type="file" 
                        accept=".png, .jpeg, .jpg, .gif, .doc, .docx, .xlsx, .xls, .zip, .rar, .pdf, .ppt, .pptx, .csv, .txt"
                        id="file" 
                        name="attachment"
                        className={this.state.data ? 'active' : ''}
                        onChange={this.handleFileChange}
                        label={this.state.data ? this.state.data.name : 'Anexe seu comprovante'}
                     />
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.handleCancel} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.props.taxes.loading.create && <Spinner color="light" size="sm" className="mr-2" />}

                     Enviar
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   calendar: state.calendar,
   taxes: state.taxes
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({...TaxesActions, ...CalendarActions}, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(UpdateReceipt);