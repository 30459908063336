import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as DocumentationActions }  from '../../store/ducks/documentation';

import { isSystem } from "../../services/auth";

import { Button, Card, CardBody, Collapse, Col, Row, Table, UncontrolledPopover, PopoverBody } from 'reactstrap';

import CreateDocumentation from '../../modals/CreateDocumentation';

class Documentation extends Component {
   state = {
      modal: false,
      edite: ''
   }

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   toggleModal = (data = null) => {
      if(data) {
         this.setState({
            modal: !this.state.modal,
            edite: data,
         });
      } else {
         this.setState({
            edite: '',
            modal: !this.state.modal
         });
      }
   }

   handleDelete = (id, group) => {
      this.props.documentationAttachmentDelete(id, group);
   }

   toggleDelete() {
       this.setState({delete: !this.state.delete});
   }

   toggleDeleteGroup = () => {
       this.setState({
           deleteGroup: !this.state.deleteGroup
       });
   }

   handleDeleteGroup(id) {
       this.props.documentationDelete(id);
   }

   render() {
      const { id, title, attachments } = this.props;

      return (
         <Card>
            <CardBody>
               <Row>
                  <Col>
                     <strong>{title}</strong>
                  </Col>
                  {!this.props.isStation && <Col xs="auto">
                      <Button id={"popover-"+id} color="primary" outline size={"sm"} onClick={this.toggleDeleteGroup}>
                          <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                          Apagar
                      </Button>
                      <UncontrolledPopover trigger="legacy" placement="top" target={`popover-${id}`}>
                        {this.state.deleteGroup && <PopoverBody>
                              <Button color="primary" outline className="mr-2" onClick={() => { document.getElementById(`popover-${id}`).click(); }}>Cancelar</Button>
                              <Button color="danger" outline onClick={this.handleDeleteGroup.bind(this, id)}>Confirmar</Button>
                        </PopoverBody>}
                      </UncontrolledPopover>
                  </Col>}
                  <Col xs="auto">
                      <button className={(this.state.collapse) ? ('btn-toggle active mt-1') : ('btn-toggle mt-1')} onClick={this.toggleCollapse}></button>
                  </Col>
               </Row>
            </CardBody>
            <Collapse isOpen={this.state.collapse}>
               <div className="bg-light text-center py-2">
                  <Button color="primary" className="btn-sm btn-pill" onClick={this.toggleModal}>
                     Fazer upload
                  </Button>
               </div>
               <CardBody>
                  <Table>
                     <tbody>
                        {attachments.map(item =>
                           <tr key={item.id}>
                              <td scope="row" className="font12">
                                 {item.title}
                              </td>
                              <td className="text-right">
                                 <a href={item.attachment} className="btn btn-primary btn-xs btn-pill" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                                    Fazer download
                                 </a>
                                 <Button color="" id={'popover-'+item.id} className="btn-popover ml-2">
                                    <svg aria-hidden="true" height="13" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"></path></svg>
                                 </Button>
                                 <UncontrolledPopover trigger="legacy" placement="top" target={'popover-'+item.id}>
                                    {!this.state.delete && <PopoverBody>
                                       <Button color="primary" outline className="mr-2" onClick={this.toggleModal.bind(this, item)}>
                                          <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                          Editar
                                       </Button>
                                       <Button color="primary" outline onClick={this.toggleDelete.bind(this, item.id, id)}>
                                          <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                                          Apagar
                                       </Button>
                                    </PopoverBody>}
                                    {this.state.delete && <PopoverBody>
                                        <Button color="primary" outline className="mr-2" onClick={this.toggleDelete.bind(this)}>Cancelar</Button>
                                        <Button color="danger" outline onClick={this.handleDelete.bind(this, item.id, id)}>Confirmar</Button>
                                    </PopoverBody>}
                                 </UncontrolledPopover>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </Table>

               </CardBody>
            </Collapse>
            <CreateDocumentation id={id} edite={this.state.edite} status={this.state.modal} toogle={this.toggleModal}/>
         </Card>
      )
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   documentation: state.documentation,
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(DocumentationActions, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Documentation);
