import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Container, Row, Col, Table, Card, CardBody } from 'reactstrap';

import logo from '../../../images/logo.png';

class PrintReason extends Component {
   state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }
   render() {
      const { reason } = this.props;
      return (
         <div className="print">
            <Container>
               <Row className="py-3 align-items-center">
                  <Col xs="auto"><img src={logo} alt="logo" /></Col>
                  <Col>
                     <h4 className="m-0">Razão</h4>
                     { this.props.user.data.company.social_name } - { this.props.user.data.company.cnpj }
                  </Col>
                  <Col className="text-right">
                     <Link to="/station/reports/reason">Voltar</Link>
                  </Col>
               </Row>
               <Row className="py-3 align-items-center">
                  <Col xs="auto">Relatório da conta: { reason.data.data[0].account_code } - { reason.data.data[0].title }</Col>
                </Row>
                <h5 className="m-0 text-center">Período {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</h5>
               {reason.data.length !== 0 &&
               <Card className="mt-3">
                  <CardBody>
                     {reason.data.data.length !== 0 &&
                        <Table>
                           <tbody>
                              <tr>
                                 <th>Data</th>
                                 <th>Histórico</th>
                                 <th>Contrap</th>
                                 <th>Débito</th>
                                 <th>Crédito</th>
                                 <th className="text-right">Saldo atual</th>
                              </tr>
                              {reason.data.data.map(item =>
                                 <tr key={item.id}>
                                    <td><Moment format="DD-MM-YYYY" date={item.date} /></td>
                                    <td>{item.historic}</td>
                                    <td>{item.account_id == this.props.match.params.id ? item.contrap_number : item.account_number}</td>
                                    <td>{item.account_id == this.props.match.params.id ? item.value : '-'}</td>
                                    <td>{item.account_id == this.props.match.params.id ? '-' : item.value}</td>
                                    <td className="text-right">{item.current}</td>
                                 </tr>
                              )}
                              <tr>
                                 <th colSpan="4">Saldo anterior: {reason.data.previous}</th>
                                 <th>Total ano:</th>
                                 <th className="text-right">{reason.data.value}</th>
                              </tr>
                           </tbody>
                        </Table>
                     }
                  </CardBody>
               </Card>
               }
            </Container>
         </div>
      );
   }
}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user,
   reason: state.reason
});

export default connect(
   mapStateToProps,
   null
)(PrintReason);
