import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AccountActions }  from '../../store/ducks/account';
import api from '../../services/api';

import { Card, CardBody, Button, Row, Col, Collapse, Table, UncontrolledPopover, PopoverBody } from 'reactstrap';

import CreateAccount from '../../modals/CreateAccount';

class AccountGroup extends Component {
   state = {
      collapse: false,
      modal: false,
      edite: '',
      delete: false
   }

   toggleModal = (data = null) => {
      if(data && data.id) {
         this.setState({
            modal: !this.state.modal,
            edite: data,
         });
      } else {
         this.setState({
            edite: '',
            modal: !this.state.modal
         });
      }
   }

  /* componentDidMount() {
      //var user_id = this.state.user_id;
      //var groups = api.get('/api/account/group/user/${user_id}');

      //var groups = this.props;
      var groups = {id : this.props.title}
      console.log(groups);
      this.setState({ groups: groups});

   }*/

   toggleCollapse = () => {
      this.setState({ collapse: !this.state.collapse });
   }

   handleDelete = (id, group) => {
      this.props.accountDelete(id, group);
   }

   handleDeleteGroup = (id) => {
      this.props.groupDelete(id);
   }

   toggleDelete() {
      this.setState({delete: !this.state.delete});
   }

   render() {
      let { id, title, code, ranking, accounts } = this.props;
      return (
         <div>
            <CreateAccount id={id} edite={this.state.edite} status={this.state.modal} toogle={this.toggleModal}/>
            <Card className="taxe card-carton">
               <CardBody>
                  <Row>
                     <Col xs="2">{code}</Col>
                     <Col xs="2">{ranking}</Col>
                     <Col xs="4"><strong>{title}</strong></Col>
                     <Col xs="4" className="d-flex align-items-center justify-content-end">
                        <Button id={`popover-${id}`} color="dark" className="btn-delete mr-2">Deletar</Button>
                        <button className={(this.state.collapse) ? ('btn-toggle active') : ('btn-toggle')} onClick={this.toggleCollapse}></button>
                     </Col>
                     <UncontrolledPopover trigger="legacy" placement="top" target={`popover-${id}`}>
                         <PopoverBody>
                             <Button color="primary" outline className="mr-2" onClick={() => { document.getElementById(`popover-${id}`).click(); }}>Cancelar</Button>
                             <Button color="danger" outline onClick={this.handleDeleteGroup.bind(this, id)}>Confirmar</Button>
                         </PopoverBody>
                     </UncontrolledPopover>
                  </Row>
               </CardBody>

               <Collapse isOpen={this.state.collapse}>
                  <div className="bg-light text-center">
                     <Button color="primary" size="sm" className="btn-pill" onClick={this.toggleModal}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                        Conta
                     </Button>
                  </div>
                  <CardBody>
                     <Table className="mytable fixed">
                        <tbody>
                           {accounts.map(item =>
                           <tr key={item.id}>
                              <td className="table-medium">{item.code}</td>
                              <td className="table-medium">{item.account}</td>
                              <td className="table-big-account"><strong>{item.title}</strong></td>
                              <td className="table-medium text-right">
                                 <Button color="" id={`popover-${id}-${item.id}`} className="btn-popover ml-2">
                                    <svg aria-hidden="true" height="13" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"></path></svg>
                                 </Button>
                                 <UncontrolledPopover trigger="legacy" placement="top" target={`popover-${id}-${item.id}`}>
                                    {!this.state.delete && <PopoverBody>
                                       <Button color="primary" outline className="mr-2" onClick={this.toggleModal.bind(this, item)}>
                                          <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                                          Editar
                                       </Button>
                                       <Button color="primary" outline onClick={this.toggleDelete.bind(this)}>
                                          <svg aria-hidden="true" height="13" focusable="false" data-prefix="fas" data-icon="ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg>
                                          Apagar
                                       </Button>
                                   </PopoverBody>}
                                   {this.state.delete && <PopoverBody>
                                       <Button color="primary" outline className="mr-2" onClick={this.toggleDelete.bind(this)}>Cancelar</Button>
                                       <Button color="danger" outline onClick={this.handleDelete.bind(this, item.id, id)}>Confirmar</Button>
                                   </PopoverBody>}
                                 </UncontrolledPopover>
                              </td>
                           </tr>
                           )}
                        </tbody>
                     </Table>
                  </CardBody>
               </Collapse>
            </Card>
         </div>
      );
   }
}

// mapDispatchToProps

const mapDispatchToProps = dispatch => (
   bindActionCreators(AccountActions, dispatch)
);

export default connect(
   null,
   mapDispatchToProps
)(AccountGroup);
