import api from './api';
import moment from 'moment';

export const sheetReportCreate = (data, id) => {
   const params = new FormData();
   data.date = data.date ? moment(data.date).format('YYYY-MM-DD') : data.date;
   
   Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);    
	});

   params.append('attachment', data.attachment, data.attachment.name); 

   if(id) {
      return api.post('/api/sheet/report/user/'+id, params);
   }
	return api.post('/api/sheet/report', params);
}

export const sheetDocumentCreate = (data, id) => {
   const params = new FormData();
   data.date = data.date ? moment(data.date).format('YYYY-MM-DD') : data.date;
   
   Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);    
	});

   params.append('attachment', data.attachment, data.attachment.name); 

   if(id) {
      return api.post('/api/sheet/document/user/'+id, params);
   }
	return api.post('/api/sheet/document', params);
}

export const sheetReportRequested = (start, stop, id, page) => {
   page = page ? `?page=${page}&start=${start}&stop=${stop}` : `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/sheet/report/user/${id}${page}`);
   }
	return api.get(`/api/sheet/report${page}`);
}

export const sheetReportRemove = (id, user) => {
   if(user) {
      return api.delete(`/api/sheet/report/${id}/user/${user}`);
   }
	return api.delete(`/api/sheet/report/${id}`);
}

export const sheetDocumentRequested = (start, stop, id, page) => {
   page = page ? `?page=${page}&start=${start}&stop=${stop}` : `?start=${start}&stop=${stop}`;
   if(id) {
      return api.get(`/api/sheet/document/user/${id}${page}`);
   }
   return api.get(`/api/sheet/document${page}`);
}

export const sheetDocumentUpdateStatus = (status, id) => {
   const params = new FormData();
	params.append('status', status); 
	return api.post('/api/sheet/document/'+id+'/status', params);
}

export const sheetReportUpdate = (data, id, user) => {
   const params = new FormData();

   data.date = data.date ? moment(data.date).format('YYYY-MM-DD') : data.date;

   params.append('title', data.title); 
   params.append('date', data.date); 

   if(data.attachment != '') {
      params.append('attachment', data.attachment, data.attachment.name); 
   }

   if(user) {
      return api.post(`/api/sheet/report/${id}/user/${user}`, params);
   
   }
   return api.post(`/api/sheet/report/${id}`, params);
}