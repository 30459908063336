import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AccountActions }  from '../../store/ducks/account';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Input, Spinner, CustomInput, Row, Col } from 'reactstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';

//import api from '../../services/api';

import { Account, AccountTypeOptions, TypeOptions } from '../../class/account';

let update = false;
let submit = false;

class CreateAccount extends Component {
   state = {
      data: new Account()
   }

   componentWillReceiveProps() {
      if(!submit) {
         update = false;
      }
   }

   componentDidUpdate() {
      if(this.props.account.loading.modal && this.props.status) {
         submit = false;
         this.props.accountModalUpdate();
         this.setState({
            data: new Account()
         });
         this.props.toogle();
      }

      if(this.props.edite && !update) {
         update = true;
         const { title, code, account, synthetic, balancete, swing, daily, reason, dre, dafuramento, dre_calc_formula_auto, dre_grupo1, dre_grupo2, dre_operador } = this.props.edite;
         this.setState({
            data: new Account(title, code, account, synthetic, balancete, swing, daily, reason, dre, dafuramento, dre_calc_formula_auto, dre_grupo1, dre_grupo2, dre_operador)
         });
      }

      if(!this.props.edite && !update && !this.props.status) {
         update = true;
         this.setState({
            data: new Account()
         });
      }

      if (this.state.data.synthetic == 2) {
         document.getElementById("hide-on-result").style.display = "none";
         document.getElementById("hide-on-result2").style.display = "none";
         document.getElementById("show-on-result").style.display = "flex";
         document.getElementById("show-on-result2").style.display = "block";
      } else {
         document.getElementById("hide-on-result").style.display = "flex";
         document.getElementById("hide-on-result2").style.display = "block";
         document.getElementById("show-on-result").style.display = "none";
         document.getElementById("show-on-result2").style.display = "none";
      }

      if (this.state.data.dre_calc_formula_auto == 0) {
         document.getElementById("custom-operador").style.display = "none";
         document.getElementById("dre_grupo1").style.display = "none";
         document.getElementById("dre_grupo2").style.display = "none";
      } else {
         document.getElementById("custom-operador").style.display = "flex";
         document.getElementById("dre_grupo1").style.display = "block";
         document.getElementById("dre_grupo2").style.display = "block";
      }
   }

   handleSubmit = (event) => {
      event.preventDefault();
      submit = true;
      const id = this.props.userSelected.data.id;

      if(this.props.edite) {
         this.props.accountUpdate(this.state.data, this.props.edite.id, id, this.props.id);
      } else {
         this.props.accountCreate(this.state.data, this.props.id, id);
      }
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({
         data: {
            ...this.state.data,
            [name]: value
         }
      });
   }

   handleSelectChangeAndHide = (name, event) => {
      this.setState({
         data: {
            ...this.state.data,
            [name]: event.value
         }
      });

      if (event.value == 2) {
         document.getElementById("hide-on-result").style.display = "none";
         document.getElementById("hide-on-result2").style.display = "none";
         document.getElementById("show-on-result").style.display = "flex";
         document.getElementById("show-on-result2").style.display = "block";
      } else {
         document.getElementById("hide-on-result").style.display = "flex";
         document.getElementById("hide-on-result2").style.display = "block";
         document.getElementById("show-on-result").style.display = "none";
         document.getElementById("show-on-result2").style.display = "none";
      }
   }

   handleSelectChange = (name, event) => {
      this.setState({
         data: {
            ...this.state.data,
            [name]: event.value
         }
      });
   }

   handleCheckboxChange = (name, event) => {
      let value = Boolean(event.currentTarget.checked);
      this.setState({
         data: {
            ...this.state.data,
            [name]: value
         }
      });

   }

   handleRadioChange = (name, event) => {
      let value = event.currentTarget.value;
      this.setState({
         data: {
            ...this.state.data,
            [name]: value
         }
      });

      if (value == 0) {
         document.getElementById("custom-operador").style.display = "none";
         document.getElementById("dre_grupo1").style.display = "none";
         document.getElementById("dre_grupo2").style.display = "none";
      } else {
         document.getElementById("custom-operador").style.display = "flex";
         document.getElementById("dre_grupo1").style.display = "block";
         document.getElementById("dre_grupo2").style.display = "block";
      }
   }

   handleCancel = () => {
      this.setState({
         data: new Account()
      });
      this.props.toogle();
   }


   render() {
      //console.log(this.props);
      return (
         <Modal isOpen={this.props.status} toggle={this.props.toogle} className="modal-dialog-centered">
            <button className="modal-close" onClick={this.props.toogle}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            <div className="modal-header">
               <h3 className="heading">{this.props.edite ? ('Atualizar conta') : ('Nova conta')}</h3>
               <p className="m-0">Preencha abaixo com os dados de seu lançamento.</p>
            </div>

            <Form onSubmit={this.handleSubmit}>
               <ModalBody>
                  <strong className="pb-3 d-block">Dados sobre conta</strong>
                  <FormGroup>
                     <Input
                        name="code"
                        placeholder="Classificação"
                        value={this.state.data.code}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <InputMask
                        mask="9999999999"
                        name="account"
                        className="form-control"
                        placeholder="Código conta"
                        maskChar={null}
                        value={this.state.data.account}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <Input
                        type="text"
                        name="title"
                        placeholder="Titulo"
                        value={this.state.data.title}
                        onChange={this.handleInputChange}
                        required
                     />
                  </FormGroup>
                  <FormGroup>
                     <Select
                        options={TypeOptions}
                        placeholder="Tipo de conta"
                        value={TypeOptions.filter(event => (event.value === this.state.data.synthetic))}
                        onChange={this.handleSelectChangeAndHide.bind(this, 'synthetic')}
                     />
                  </FormGroup>
                  <FormGroup>
                     <strong id="hide-on-result2" className="pb-2">Relatórios vinculados</strong>
                     <Row id="hide-on-result">
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox1"
                              label="Balancete"
                              checked={Number(this.state.data.balancete)}
                              onChange={this.handleCheckboxChange.bind(this, 'balancete')}
                           />
                        </Col>
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox2"
                              label="Diário"
                              checked={Number(this.state.data.daily)}
                              onChange={this.handleCheckboxChange.bind(this, 'daily')}
                           />
                        </Col>
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox3"
                              label="DRE"
                              checked={Number(this.state.data.dre)}
                              onChange={this.handleCheckboxChange.bind(this, 'dre')}
                           />
                        </Col>
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox4"
                              name="customCheckbox4"
                              label="Balanço"
                              checked={Number(this.state.data.swing)}
                              onChange={this.handleCheckboxChange.bind(this, 'swing')}
                           />
                        </Col>
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox5"
                              label="Razão"
                              checked={Number(this.state.data.reason)}
                              onChange={this.handleCheckboxChange.bind(this, 'reason')}
                           />
                        </Col>
                        <Col xs="4" className="my-2">
                           <CustomInput
                              type="checkbox"
                              id="customCheckbox6"
                              label="Faturamento"
                              checked={Number(this.state.data.dafuramento)}
                              onChange={this.handleCheckboxChange.bind(this, 'dafuramento')}
                           />
                        </Col>

                     </Row>
                   </FormGroup>
                  <FormGroup>
                     <strong id="show-on-result2" className="pb-2">Cálculo totalizador DRE</strong>
                     <Row id="show-on-result">
                     <Col xs="6" className="my-2">
                           <CustomInput
                              type="radio"
                              id="customRadio1"
                              name="dre_calc_formula_auto"
                              value="0"
                              label="Cálculo Automático"
                              checked={Number(this.state.data.dre_calc_formula_auto == 0)}
                              onChange={this.handleRadioChange.bind(this, 'dre_calc_formula_auto')}
                           />
                        </Col>
                        <Col xs="6" className="my-2">
                           <CustomInput
                              type="radio"
                              id="customRadio2"
                              label="Definir Fórmula"
                              value="1"
                              name="dre_calc_formula_auto"
                               checked={Number(this.state.data.dre_calc_formula_auto == 1)}
                              onChange={this.handleRadioChange.bind(this, 'dre_calc_formula_auto')}
                           />
                        </Col>
                        <Col xs="5" className="my-2">
                           <Select id="dre_grupo1"
                              options={this.props.account.data.map((item) => { return { 'value' : item.id, 'label' : item.title } })}
                              placeholder="Grupo 1"
                              value={this.props.account.data.map((item) => { return { 'value' : item.id, 'label' : item.title } }).filter(event => (event.value === this.state.data.dre_grupo1))}
                              onChange={this.handleSelectChange.bind(this, 'dre_grupo1')}
                           />
                        </Col>
                        <Col xs="2" id="custom-operador" className="my-2 custom-operador">
                           <CustomInput
                              type="checkbox"
                              id="dre_operador"
                              checked={Number(this.state.data.dre_operador)}
                              onChange={this.handleCheckboxChange.bind(this, 'dre_operador')}
                           />
                        </Col>
                        <Col xs="5" className="my-2">
                           <Select id="dre_grupo2"
                              options={this.props.account.data.map((item) => { return { 'value' : item.id, 'label' : item.title } })}
                              placeholder="Grupo 2"
                              value={this.props.account.data.map((item) => { return { 'value' : item.id, 'label' : item.title } }).filter(event => (event.value === this.state.data.dre_grupo2))}
                              onChange={this.handleSelectChange.bind(this, 'dre_grupo2')}
                           />
                        </Col>
                     </Row>
                  </FormGroup>
               </ModalBody>
               <ModalFooter>
                  <Button className="mr-4" color="primary" onClick={this.props.toogle} outline>Cancelar</Button>
                  <Button color="primary" type="submit">
                     {this.props.account.loading.create && <Spinner color="light" size="sm" className="mr-2" />}
                     {this.props.edite ? ('Atualizar') : ('Adicionar')}
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      );
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   account: state.account,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...AccountActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CreateAccount);
