import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   dreFetchRequested: ['start', 'stop', 'id'],
   dreFetchSucceeded: ['data'],
   dreFetchFailed: [],
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: [],
   loading: false,
   error: false
};

// Request
const requested = (state) => ({
   ...state,
   loading: true,
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data,
   loading: false,
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: false,
   error: true
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.DRE_FETCH_REQUESTED]: requested,
   [Types.DRE_FETCH_SUCCEEDED]: succeeded,
   [Types.DRE_FETCH_FAILED]: failed,
});