import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Card, CardBody, CardTitle, Badge } from 'reactstrap';


class ReleaseNotify extends Component {

   state = {
      status: {
         homologated: { 
            style: 'success', 
            caption: 'Homologado' 
         },
         analysis: { 
            style: 'info', 
            caption: 'Em análise' 
         },
         refused: { 
            style: 'danger', 
            caption: 'Recusado' 
         }
      }
   }

   render() {
      const { title, status  } = this.props;

      // Status
      let statusKey;
      let statusStyle = 'dark';
      let statusCaption = 'Pendente';
      if(status.length !== 0) {
         statusKey = status[status.length - 1]['status'];
         statusStyle = this.state.status[statusKey].style;
         statusCaption = this.state.status[statusKey].caption;
      }
      return (
         <Card className="release-notify card-carton">
            <CardBody>
               <CardTitle>{title}</CardTitle>
               <div className="d-flex align-items-center">
                  <Badge className="mr-3" color={statusStyle} pill>{statusCaption}</Badge>
                  <Link to="/station/releases" className="btn-cicle ml-2">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>
                  </Link>
               </div>
            </CardBody>
         </Card>
      );
   }
}

export default ReleaseNotify;