import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated, isSystem } from "../services/auth";

import System from '../pages/System';

const SystemRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() && isSystem() ? (
				<System>
					<Component {...props} />
				</System>
			) : (
				<Redirect to={{ pathname: "/", state: { from: props.location } }} />
			)
		}
	/>
);

export default SystemRoute;
