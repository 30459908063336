import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as SheetReportActions }  from '../../store/ducks/sheetReport';
import { Creators as SheetDocumentActions }  from '../../store/ducks/sheetDocument';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';

import {
   Button,
   Card,
   CardBody,
   CardHeader,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
   Spinner,
   Alert,
   Row,
   Col,
   UncontrolledPopover,
   PopoverBody
} from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import SheetReport from '../../components/SheetReport';
import SheetDocument from '../../components/SheetDocument';

class SystemPayroll extends Component {

   state = {
      start: moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      activeTab: '1',
      modalSheetReport: false,
      modalSheetDocument: false
   }

   componentDidMount() {
      const id = this.props.userSelected.data.id;

      this.props.sheetReportFetchByIdRequested(this.state.start, this.state.stop, id);
      this.props.sheetDocumentFetchByIdRequested(this.state.start, this.state.stop, id);
   }

   tabToogle = (tab) => {
      if (this.state.activeTab !== tab) {
         this.setState({ activeTab: tab });
      }
   }

   handlePageChangeReport = (page) => {
      const id = this.props.userSelected.data.id;
      this.props.sheetReportPageById(this.state.start, this.state.stop, id, page);
   }

   handlePageChangeDocument = (page) => {
      const id = this.props.userSelected.data.id;
      this.props.sheetDocumentPageById(this.state.start, this.state.stop, id, page);
   }

   handleFilter = () => {
      document.getElementById('filter').click();
      const id = this.props.userSelected.data.id;

      this.props.sheetReportFetchByIdRequested(this.state.start, this.state.stop, id);
      this.props.sheetDocumentFetchByIdRequested(this.state.start, this.state.stop, id);
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         [name]: moment(event).format('YYYY-MM-DD')
      });
   }

   render() {
      const { sheetReport, sheetDocument } = this.props;

      return (
         <Card className="panel is-taxes">
            <CardHeader className="pb-0 px-3">

               <Row>
                  <Col>
                     <Nav tabs>
                        <NavItem>
                           <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.tabToogle('1'); }}>
                              Documentos de funcionários
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.tabToogle('2'); }}>
                              Relatório do mês
                           </NavLink>
                        </NavItem>
                     </Nav>
                  </Col>
                  <Col xs="auto">
                     <div className="header-content">
                        <span id="filter">de {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</span>
                        <UncontrolledPopover className="filter" trigger="legacy"placement="top" target="filter">
                           <PopoverBody>
                              <strong className="title mb-3 d-block">Selecione a competência</strong>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">De</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.start).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'start')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <Row className="align-items-center mb-2">
                                 <Col xs="2">A</Col>
                                 <Col>
                                    <DayPickerInput
                                       formatDate={formatDate}
                                       parseDate={parseDate}
                                       placeholder="Data"
                                       value={moment(this.state.stop).format('DD/MM/YYYY')}
                                       onDayChange={this.handleDayPickerInputChange.bind(this, 'stop')}
                                       dayPickerProps={{
                                          locale: 'pt-br',
                                          localeUtils: MomentLocaleUtils,
                                       }}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <div className="text-center">
                                 <Button color="primary" onClick={this.handleFilter} size="sm">Filtrar</Button>
                              </div>
                           </PopoverBody>
                        </UncontrolledPopover>
                     </div>
                  </Col>
               </Row>
            </CardHeader>
            <CardBody>
               <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                     {sheetDocument.loading.request &&
                        <div className="text-center py-5">
                           <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                     }

                     {(sheetDocument.data.length === 0 && !sheetDocument.loading.request && !sheetDocument.error) &&
                        <Alert color="light" className="text-center my-5" >
                           Não há documentos.
                        </Alert>
                     }

                     {sheetDocument.error &&
                        <Alert color="light" className="text-center my-5" >
                           Encontramos dificuldades em recuperar seus documentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                        </Alert>
                     }

                     {!sheetDocument.loading.request && sheetDocument.data.map(item => <SheetDocument key={item.id} {...item} />)}

                     <Pagination
                        onChange={this.handlePageChangeDocument}
                        current={sheetDocument.current_page}
                        pageSize={sheetDocument.per_page}
                        total={sheetDocument.total}
                        showLessItems
                        showTitle={true}
                     />
                  </TabPane>
                  <TabPane tabId="2">
                     {sheetReport.loading.request &&
                        <div className="text-center py-5">
                           <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                     }

                     {(sheetReport.data.length === 0 && !sheetReport.loading.request && !sheetReport.error) &&
                        <Alert color="light" className="text-center my-5" >
                           Não há relátorios.
                        </Alert>
                     }

                     {sheetReport.error &&
                        <Alert color="light" className="text-center my-5" >
                           Encontramos dificuldades em recuperar seus documentos, por favor verifique sua conexão com a internet ou tente mais tarde.
                        </Alert>
                     }

                     {!sheetReport.loading.request && sheetReport.data.map(item => <SheetReport key={item.id} {...item} />)}

                     <Pagination
                        onChange={this.handlePageChangeReport}
                        current={sheetReport.current_page}
                        pageSize={sheetReport.per_page}
                        total={sheetReport.total}
                        showLessItems
                        showTitle={true}
                     />
                  </TabPane>
               </TabContent>
            </CardBody>
         </Card>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   sheetReport: state.sheetReport,
   sheetDocument: state.sheetDocument,
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...SheetReportActions, ...SheetDocumentActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SystemPayroll);
