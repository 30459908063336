import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AuthenticationActions} from '../../store/ducks/authentication';

import {Button, Container, Card, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { toast } from "react-toastify";

import picture from '../../images/login.png';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './style.sass';

class Login extends Component {
   state = {
      email: "",
      password: "",
      toast: null
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
   }

   handleSubmit = (event) => {
      event.preventDefault();
      const { email, password } = this.state;

      if(!email || !password) {
         if (!toast.isActive(this.state.toast)) {
            this.state.toast = toast.error("Por favor entre com E-mail e Senha");
         }
         return false;
      }
      this.props.authentication(email, password);
   }

   render() {
      return (
         <div>
            <Header />
            <Container>
               <Card className="login panel">
                  <div className="login-picture">
                     <img src={picture} title="Login" alt="login" />
                  </div>
                  <div className="login-content">
                     <h1 className="login-title">Preencha com seus dados</h1>
                     <Form onSubmit={this.handleSubmit} noValidate>
                        <FormGroup>
                           <label htmlFor="email">E-mail</label>
                           <Input type="email" name="email" id="email" onChange={this.handleInputChange} required />
                        </FormGroup>

                        <FormGroup>
                           <label htmlFor="password">Senha</label>
                           <Input type="password" name="password" id="password" onChange={this.handleInputChange} required />
                        </FormGroup>

                        <div className="text-center mt-4">
                           <Button color="primary" size="lg" type="submit" disabled={this.props.auth.loading ? true : false}>
                              {this.props.auth.loading && <Spinner color="light" size="sm" className="mr-2" />}
                              Entrar
                           </Button>
                        </div>
                     </Form>
                  </div>
               </Card>
            </Container>
            <Footer />
         </div>
      );
   }
}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   auth: state.authentication
});

const mapDispatchToProps = dispatch => (
   bindActionCreators(AuthenticationActions, dispatch)
);

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(Login);

