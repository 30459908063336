import api from './api';
import moment from 'moment';

export const releaseCreate = (data) => {
	
	const params = new FormData();
	data.date = data.date ? moment(data.date).format('YYYY-MM-DD') : data.date;

	Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);    
	});

   params.append('attachment', data.attachment, data.attachment.name);  

	return api.post('/api/releases', params, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

export const releasesRequest = (start, stop, page) => {
	let date = `?start=${start}&stop=${stop}`;
	if(page) {
		return api.get(`/api/releases?page=${page}${date}`);
	}
	return api.get(`/api/releases${date}`);
}

export const releasesByIdRequest = (start, stop, id, page) => {
	let date = `?start=${start}&stop=${stop}`;
	if(page) {
		return api.get(`/api/releases/${id}?page=${page}${date}`);
	}
	return api.get(`/api/releases/${id}${date}`);
}

export const releasesUpdateStatus = (status, id) => {
	const params = new FormData();
	params.append('status', status); 
	return api.post('/api/releases/'+id+'/status', params);
}