import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as UsersActions } from '../../store/ducks/users';
import { Creators as UserSelectedActions } from '../../store/ducks/userSelected';
import classnames from 'classnames';
import { userCreate, userUpdate } from '../../services/user';

import {
   Button,
   Modal,
   ModalBody,
   ModalFooter,
   Form,
   FormGroup,
   Input,
   Spinner,
   TabContent,
   TabPane,
   Nav,
   NavItem,
   NavLink,
   Row,
   Col,
   CustomInput,
   Media
} from 'reactstrap';


import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import InputMask from 'react-input-mask';

import avatar from '../../images/avatar.png';

import {User, UserPaymentOptions, UserStatesOptions } from '../../class/user';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import './style.sass';

let update = false;
let submit = false;

class CreateUser extends Component {
   state = {
      user: new User(),
      loading: false,
      activeTab: '1'
   }

   componentDidUpdate() {
      if(this.props.edite === true && !update) {
         update = true;

         this.setState({
            user: new User(
               this.props.userSelected.data.name,
               this.props.userSelected.data.cpf,
               this.props.userSelected.data.phone_primary,
               this.props.userSelected.data.phone_secondary ? this.props.userSelected.data.phone_secondary : '',
               this.props.userSelected.data.email,
               this.props.userSelected.data.email_guides,
               this.props.userSelected.data.address.cep,
               this.props.userSelected.data.address.city,
               this.props.userSelected.data.address.address,
               this.props.userSelected.data.address.number,
               this.props.userSelected.data.address.state,
               this.props.userSelected.data.address.district,
               this.props.userSelected.data.address.complement ? this.props.userSelected.data.address.complement : '',
               this.props.userSelected.data.company.social_name,
               this.props.userSelected.data.company.fantasy_name,
               this.props.userSelected.data.company.cnpj,
               this.props.userSelected.data.company.cnae_primary ? this.props.userSelected.data.company.cnae_primary : '',
               this.props.userSelected.data.company.cnae_secondary ? this.props.userSelected.data.company.cnae_secondary : '',
               this.props.userSelected.data.company.payment_type,
               this.props.userSelected.data.company.payment_date ? this.props.userSelected.data.company.payment_date : '',
               this.props.userSelected.data.company.contract_term ? this.props.userSelected.data.company.contract_term : '',
               this.props.userSelected.data.company.contract_start ? this.props.userSelected.data.company.contract_start : '',
               this.props.userSelected.data.company.contract_finish ? this.props.userSelected.data.company.contract_finish : '',
               this.props.userSelected.data.company.comments ? this.props.userSelected.data.company.comments : '',
               this.props.userSelected.data.company.status,
            )
         });
      }
   }

   tabToogle = (tab) => {
       let form = document.getElementById('new-user'), valid = form.checkValidity();

       if (tab == '2' && !valid) {
           form.reportValidity();
           return;
       }

       if (!this.state.user.state) {
           this.refs.state.focus();
           return;
       }
      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab
         });
      }
   }

   handleSubmit = (event) => {
       event.preventDefault();
       let form = document.getElementById('new-user'), valid = form.checkValidity();

       if (!valid) {
           form.reportValidity();
           return;
       }

       if (!this.state.user.payment_type) {
           this.refs.payment_type.focus();
           return;
       }

       if (!this.state.user.status) {
           this.refs.status.focus();
           return;
       }

      this.setState({
         loading: true
      });
      if(this.props.edite === true) {
         userUpdate(this.state.user, this.props.userSelected.data.id).then(response => {
            this.setState({
               user: new User(),
               activeTab: '1',
               loading: false
            });
            this.props.selectUser(this.props.userSelected.data.id);
            this.props.updateUser(response.data);

            update = false;
            this.props.toogle();
         }).catch(error => {
            this.setState({ loading: false });
         });
      } else {
         userCreate(this.state.user).then(response => {
            this.setState({
               user: new User(),
               activeTab: '1',
               loading: false
            });
            this.props.userAdd(response.data);
            this.props.toogle();
         }).catch(error => {
            this.setState({ loading: false });
         });
      }
   }

   handleCancel = () => {
      update = false;
      this.setState({
         user: new User(),
         activeTab: '1'
      });
      this.props.toogle();
   }

   handleInputChange = (event) => {
      const { name, value } = event.target;
      this.setState({
         user: {
            ...this.state.user,
            [name]: value
         }
      });
   }

   handleFileChange = (event) => {
      const { name } = event.target;
      this.setState({
         user: {
            ...this.state.user,
            [name]: event.target.files[0]
         }
      });
   }

   handleSelectChange = (name, event) => {
      this.setState({
         user: {
            ...this.state.user,
            [name]: event.value
         }
      });
   }

   handleDayPickerInputChange = (name, event) => {
      this.setState({
         user: {
            ...this.state.user,
            [name]: event
         }
      });
   }

   handleRadioChange = (name, event) => {
      this.setState({
         user: {
            ...this.state.user,
            [name]: event.currentTarget.value
         }
      });
   }

   render() {
      return (
         <Modal isOpen={this.props.status} toggle={this.handleCancel} className="modal-dialog-centered modal-create-user">
            <button className="modal-close" onClick={this.handleCancel}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </button>

            {this.props.edite !== true &&
               <div className="modal-header">
                  <h3 className="heading">Novo cliente</h3>
                  <p className="m-0">Preencha abaixo para criar novo cliente.</p>
               </div>
            }

            {this.props.edite === true &&
               <Media className="modal-media">
                  <Media left top href="#">
                     <Media object src={this.props.userSelected.data.avatar ? this.props.userSelected.data.avatar : avatar} alt={this.props.userSelected.data.name} />
                  </Media>
                  <Media body>
                     <Media className="heading" heading>
                        {this.props.userSelected.data.name}
                     </Media>
                     {this.props.userSelected.data.company.social_name}
                  </Media>
               </Media>
            }

            <Nav className="modal-tabs" tabs>
               <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.tabToogle('1'); }}>
                     Dados pessoais
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.tabToogle('2'); }}>
                     Dados da empresa
                  </NavLink>
               </NavItem>
            </Nav>

            <Form id={"new-user"} onSubmit={this.handleSubmit}>
               <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                     <ModalBody>

                        <FormGroup>
                           <Input
                              type="text"
                              name="name"
                              placeholder="Nome"
                              value={this.state.user.name}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <InputMask
                              mask="999.999.999-99"
                              name="cpf"
                              className="form-control"
                              placeholder="CPF"
                              maskChar={null}
                              value={this.state.user.cpf}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <CustomInput
                              type="file"
                              id="file"
                              accept=".png, .jpeg, .jpg, .gif, .doc, .docx, .xlsx, .xls, .zip, .rar, .pdf, .ppt, .pptx, .csv, .txt"
                              className={this.state.user.avatar ? 'active' : ''}
                              name="avatar"
                              onChange={this.handleFileChange}
                              label={this.state.user.avatar ? this.state.user.avatar.name : 'Insira um arquivo de imagem'}
                           />
                        </FormGroup>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <InputMask
                                    mask="(99) 99999-9999"
                                    name="phone_primary"
                                    className="form-control"
                                    placeholder="Telefone 1"
                                    maskChar={null}
                                    value={this.state.user.phone_primary}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <InputMask
                                    mask="(99) 99999-9999"
                                    name="phone_secondary"
                                    className="form-control"
                                    placeholder="Telefone 2"
                                    maskChar={null}
                                    value={this.state.user.phone_secondary}
                                    onChange={this.handleInputChange}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <FormGroup>
                           <Input
                              type="email"
                              name="email"
                              placeholder="E-mail"
                              value={this.state.user.email}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <Input
                              type="password"
                              name="password"
                              placeholder={this.props.edite === true ? 'Nova senha' : 'Senha' }
                              value={this.state.user.password}
                              onChange={this.handleInputChange}
                              required={this.props.edite === true ? false : true }
                           />
                        </FormGroup>

                        <FormGroup>
                           <Input
                              type="email"
                              name="email_guides"
                              placeholder="E-mail guias"
                              value={this.state.user.email_guides}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <InputMask
                                    mask="99999-999"
                                    name="cep"
                                    className="form-control"
                                    placeholder="CEP"
                                    maskChar={null}
                                    value={this.state.user.cep}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <Input
                                    type="text"
                                    name="city"
                                    placeholder="Cidade"
                                    value={this.state.user.city}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col xs="8">
                              <FormGroup>
                                 <Input
                                    type="text"
                                    name="address"
                                    placeholder="Endereço"
                                    value={this.state.user.address}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                           <Col xs="4">
                              <FormGroup>
                                 <InputMask
                                    mask="9999"
                                    name="number"
                                    className="form-control"
                                    placeholder="Número"
                                    maskChar={null}
                                    value={this.state.user.number}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Input
                                    type="text"
                                    name="district"
                                    placeholder="Bairro"
                                    value={this.state.user.district}
                                    onChange={this.handleInputChange}
                                    required
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <Select
                                    options={UserStatesOptions}
                                    placeholder="Estado"
                                    ref='state'
                                    value={UserStatesOptions.filter(item => (item.value === this.state.user.state))}
                                    onChange={this.handleSelectChange.bind(this, 'state')}
                                 />
                              </FormGroup>

                           </Col>
                        </Row>

                        <FormGroup>
                           <Input
                              type="text"
                              name="complement"
                              placeholder="Complemento"
                              value={this.state.user.complement}
                              onChange={this.handleInputChange}
                           />
                        </FormGroup>

                     </ModalBody>
                     <ModalFooter>
                        <Button className="mr-4" color="primary" onClick={this.handleCancel} outline>Cancelar</Button>
                        <Button className="mr-4" color="primary" onClick={() => { this.tabToogle('2'); }}>Próxima</Button>
                     </ModalFooter>

                  </TabPane>
                  <TabPane tabId="2">
                     {this.state.activeTab == '2' && <ModalBody>
                        <FormGroup>
                           <Input
                              type="text"
                              name="social_name"
                              placeholder="Razão social"
                              value={this.state.user.social_name}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <Input
                              type="text"
                              name="fantasy_name"
                              placeholder="Nome fantasia"
                              value={this.state.user.fantasy_name}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <InputMask
                              mask="99.999.999/9999-99"
                              name="cnpj"
                              className="form-control"
                              placeholder="CNPJ"
                              maskChar={null}
                              value={this.state.user.cnpj}
                              onChange={this.handleInputChange}
                              required
                           />
                        </FormGroup>

                        <FormGroup>
                           <Input
                              type="text"
                              name="cnae_primary"
                              placeholder="CNAE principal"
                              value={this.state.user.cnae_primary}
                              onChange={this.handleInputChange}
                           />
                        </FormGroup>

                        <FormGroup>
                           <Input
                              type="text"
                              name="cnae_secondary"
                              placeholder="CNAE secundário"
                              value={this.state.user.cnae_secondary}
                              onChange={this.handleInputChange}
                           />
                        </FormGroup>

                        <FormGroup>
                           <Select
                              options={UserPaymentOptions}
                              placeholder="Forma de pagamento"
                              ref="payment_type"
                              value={UserPaymentOptions.filter(item => (item.value === this.state.user.payment_type))}
                              onChange={this.handleSelectChange.bind(this, 'payment_type')}
                              required
                           />
                        </FormGroup>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <DayPickerInput
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Dia do pagamento"
                                    value={this.state.user.payment_date}
                                    onDayChange={this.handleDayPickerInputChange.bind(this, 'payment_date')}
                                    dayPickerProps={{
                                       locale: 'pt-br',
                                       localeUtils: MomentLocaleUtils,
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <DayPickerInput
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Data início de contrato"
                                    value={this.state.user.contract_start}
                                    onDayChange={this.handleDayPickerInputChange.bind(this, 'contract_start')}
                                    dayPickerProps={{
                                       locale: 'pt-br',
                                       localeUtils: MomentLocaleUtils,
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <DayPickerInput
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Validade contrato"
                                    value={this.state.user.contract_finish}
                                    onDayChange={this.handleDayPickerInputChange.bind(this, 'contract_finish')}
                                    dayPickerProps={{
                                       locale: 'pt-br',
                                       localeUtils: MomentLocaleUtils,
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <DayPickerInput
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Prazo contrato"
                                    value={this.state.user.contract_term}
                                    onDayChange={this.handleDayPickerInputChange.bind(this, 'contract_term')}
                                    dayPickerProps={{
                                       locale: 'pt-br',
                                       localeUtils: MomentLocaleUtils,
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <FormGroup>
                           <Input
                              type="text"
                              name="comments"
                              placeholder="Observação"
                              value={this.state.user.comments}
                              onChange={this.handleInputChange}
                           />
                        </FormGroup>

                        <FormGroup>
                           <div className="group-input">
                              <div>
                                 <CustomInput
                                    type="radio"
                                    id="exampleCustomRadio1"
                                    name="customRadio"
                                    label="Ativo"
                                    value="active"
                                    ref="status"
                                    checked={this.state.user.status === 'active'}
                                    onChange={this.handleRadioChange.bind(this, 'status')}
                                 />
                              </div>
                              <div>
                                 <CustomInput
                                    type="radio"
                                    id="exampleCustomRadio2"
                                    name="customRadio"
                                    label="Inativo"
                                    value="inactive"
                                    checked={this.state.user.status === 'inactive'}
                                    onChange={this.handleRadioChange.bind(this, 'status')}
                                 />
                              </div>
                           </div>
                        </FormGroup>

                    </ModalBody>}
                     <ModalFooter>
                        <Button className="mr-4" color="primary" onClick={this.handleCancel} outline>Cancelar</Button>
                        <Button color="primary" type="submit">
                           {this.state.loading && <Spinner color="light" size="sm" className="mr-2" />}
                           {this.props.edite === true ? 'Atualizar' : 'Adicionar' }
                        </Button>
                     </ModalFooter>
                  </TabPane>
               </TabContent>
            </Form>
         </Modal>
      )
   }

}

// mapStateToProps & mapDispatchToProps
const mapStateToProps = state => ({
   userSelected: state.userSelected
});

const mapDispatchToProps = dispatch => (
   bindActionCreators({ ...UsersActions, ...UserSelectedActions }, dispatch)
);

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CreateUser);
