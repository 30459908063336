import api from './api';

export const documentationCreate = (id, name) => {
	const params = new FormData();
   params.append('title', name);
   if(id) {
      return api.post('/api/documentation/user/'+id, params);
   }
	return api.post('/api/documentation', params);
}

export const documentationAttachmentCreate = (data, id) => {
   const params = new FormData();
   params.append('title', data.title);
   params.append('attachment', data.attachment, data.attachment.name);
   return api.post('/api/documentation/'+id+'/attachment', params);
}

export const documentationAttachmentUpdate = (data, id) => {
   const params = new FormData();
   params.append('title', data.title);

   if(data.attachment != '') {
      params.append('attachment', data.attachment, data.attachment.name);
   }

   return api.post('/api/documentation/attachment/'+id, params);
}

export const documentationRequested = (page, id) => {
   page = page ? '?page='+page : '';
   if(id) {
      return api.get('/api/documentation/user/'+id+page);
   }
	return api.get('/api/documentation'+page);
}

export const documentationRemoveAttachment = (id) => {

	return api.delete(`/api/documentation/attachment/${id}`);
}

export const documentationRemove = (id) => {
	return api.delete(`/api/documentation/${id}`);
}
