import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated, isStation } from "../services/auth";

const LoggedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
		isAuthenticated() ? (
			isStation() ? (
				<Redirect to={{ pathname: "/station/dashboard", state: { from: props.location } }} />
			) : (
				<Redirect to={{ pathname: "/system/dashboard", state: { from: props.location } }} />
			)
		) : (
			<Component {...props} />
		)
		}
	/>
);

export default LoggedRoute;