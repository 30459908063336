import api from './api';

export const accountCreate = (data, id, user) => {
	const params = new FormData();

	data['balancete'] = data['balancete'] ? '1' : '0';
	data['swing'] = data['swing'] ? '1' : '0';
	data['daily'] = data['daily'] ? '1' : '0';
	data['reason'] = data['reason'] ? '1' : '0';
	data['dre'] = data['dre'] ? '1' : '0';
	data['dafuramento'] = data['dafuramento'] ? '1' : '0';
   data['dre_operador'] = data['dre_operador'] ? '1' : '0';

	Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);
	});

	return api.post(`/api/account/group/${id}/user/${user}`, params);
}

export const accountUpdate = (data, id, user) => {
	const params = new FormData();

	data['balancete'] = data['balancete'] ? '1' : '0';
	data['swing'] = data['swing'] ? '1' : '0';
	data['daily'] = data['daily'] ? '1' : '0';
	data['reason'] = data['reason'] ? '1' : '0';
	data['dre'] = data['dre'] ? '1' : '0';
	data['dafuramento'] = data['dafuramento'] ? '1' : '0';
   data['dre_operador'] = data['dre_operador'] ? '1' : '0';

	Object.entries(data).forEach(([key, value]) => {
      params.append(key, value);
	});

	return api.post(`/api/account/${id}/user/${user}`, params);
}

export const accountCreateGroup = (data, id) => {
	const params = new FormData();
	Object.entries(data).forEach(([key, value]) => {
      	params.append(key, value);
	});

	return api.post(`/api/account/user/${id}/group`, params);
}

export const accountRequest = (id, page) => {
   page = page ? `?page=${page}` : '';
   return api.get(`/api/account/group/user/${id}${page}`);
}

export const accountDelete = (id) => {
   return api.delete(`/api/account/${id}`);
}

export const groupDelete = (id) => {
   return api.delete(`/api/account/group/${id}`);
}

