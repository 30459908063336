import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   userAdd: ['data'],
   updateUser: ['data'],
   usersFetchRequested: []
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   data: [],
   error: true
}

const add = (state, {data}) => ({
   ...state,
   data: [
      data,
      ...state.data
   ]
});

const update = (state, {data}) => ({
   ...state,
   data: state.data.map(
      user => 
      user.id == data.id 
         ? {...data} 
         : {...user}
   )
});

const requested = (state) => ({
   ...state,
   error: false
});

const succeeded = (state, {data}) => ({
   data: data,
   error: false
});

const failed = (state) => ({
   data: [],
   error: true
});


/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.USERS_FETCH_REQUESTED]: requested,
   [Types.USER_ADD]: add,
   [Types.UPDATE_USER]: update,
   ['USERS_FETCH_SUCCEEDED']: succeeded,
   ['USERS_FETCH_FAILED']: failed
});