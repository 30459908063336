import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Container, Row, Col } from 'reactstrap';

import logo from '../../../images/logo.png';

import Balancete from '../../../components/Balancete';

class SystemPrintSwing extends Component {
    state = {
      start: localStorage.getItem('filter-date-start') || moment().subtract(1, 'months').date(1).format('YYYY-MM-DD'),
      stop: localStorage.getItem('filter-date-stop') || moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
   }

   render() {
      const { swing } = this.props;
      return (
         <div className="print">
            <Container>
               <Row className="py-3 align-items-center">
                  <Col xs="auto"><img src={logo} alt="logo" /></Col>
                  <Col>
                     <h4 className="m-0">Balanço</h4>
                     { this.props.userSelected.data.company.social_name } - { this.props.userSelected.data.company.cnpj }
                  </Col>
                  <Col className="text-right">
                     <Link to="/system/reports/swing">Voltar</Link>
                  </Col>
               </Row>
               <h5 className="mb-2 text-center">Período {moment(this.state.start).format('DD/MM/YYYY')} a {moment(this.state.stop).format('DD/MM/YYYY')}</h5>
               {swing.data.filter(item => item.accounts.length !== 0).map(item => <Balancete collapse={true} key={item.id} {...item} />)}
            </Container>
         </div>
      );
   }
}

// mapStateToProps
const mapStateToProps = state => ({
   user: state.user,
   swing: state.swing,
   userSelected: state.userSelected
});

export default connect(
   mapStateToProps,
   null
)(SystemPrintSwing);
