import { createActions, createReducer } from 'reduxsauce';

/*
*  Criando action types & creators.
*/
export const { Types, Creators } = createActions({
   taxCreate: ['data', 'id'],
   taxesPage: ['start', 'stop', 'page'],
   taxesPageById: ['start', 'stop', 'page', 'id'],
   taxPaid: ['id', 'data'],
   taxPaidSucceeded: ['data'],
   taxPaidFailed: [],
   taxCreateSucceeded: ['data'],
   taxCreateFailed: [],
   taxesFetchRequested: ['start', 'stop'],
   taxesFetchSucceeded: ['data'],
   taxesFetchFailed: [],
   taxesFetchByIdRequested: ['start', 'stop', 'id'],
   taxesModalUpdate: [],
   taxUpdate: ['old', 'data', 'id'],
   taxUpdateSucceeded: []
});

/*
*  Criando reducer handlers.
*/
const INITIAL_STATE = {
   current_page: null,
   data: [],
   per_page: null,
   total: null,
   loading: {
      modal: false,
      request: false,
      create: false,
   },
   error: false
};

// Request
const requested = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      request: true
   },
   error: false
});

const succeeded = (state, {data}) => ({
   ...state,
   data: data.data,
   current_page: data.current_page,
   per_page: data.per_page,
   total: data.total,
   loading: {
      ...state.loading,
      request: false
   },
   error: false
});

const failed = (state) => ({
   ...state,
   data: [],
   loading: {
      ...state.loading,
      request: false
   },
   error: true
});

// Create
const create = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true,
      modal: false
   }
});

const createSucceeded = (state, {data}) => ({
   ...state,
   data: [
      data.data,
      ...state.data
   ],
   loading: {
      ...state.loading,
      create: false,
      modal: true
   }
});

const createFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: false
   }
});

// Modal
const modal = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: false
   }
});

const taxPaidSucceeded = (state, {data}) => ({
   ...state,
   data: state.data.map(
      tax => 
      tax.id == data.id 
         ? {...tax, status: 'paid', receipt: data.receipt } 
         : {...tax}
   ),
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

const taxPaid = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: true
   }
});

const taxPaidFailed = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      create: false
   }
});

const updateSucceeded = (state) => ({
   ...state,
   loading: {
      ...state.loading,
      modal: true,
      create: false
   }
});

/*
*  Criando reducer.
*/
export default createReducer(INITIAL_STATE, {
   [Types.TAX_CREATE]: create,
   [Types.TAX_PAID]: taxPaid,
   [Types.TAX_PAID_SUCCEEDED]: taxPaidSucceeded,
   [Types.TAX_PAID_FAILED]: taxPaidFailed,
   [Types.TAX_CREATE_SUCCEEDED]: createSucceeded,
   [Types.TAX_CREATE_FAILED]: createFailed,
   [Types.TAXES_FETCH_REQUESTED]: requested,
   [Types.TAXES_FETCH_SUCCEEDED]: succeeded,
   [Types.TAXES_FETCH_FAILED]: failed,
   [Types.TAXES_FETCH_BY_ID_REQUESTED]: requested,
   [Types.TAXES_MODAL_UPDATE]: modal,
   [Types.TAXES_PAGE]: null,
   [Types.TAXES_PAGE_BY_ID]: null,
   [Types.TAX_UPDATE]: create,
   [Types.TAX_UPDATE_SUCCEEDED]: updateSucceeded,
});

