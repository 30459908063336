import React, { Component } from 'react';
import DayPicker from 'react-day-picker';

class Calendar extends Component {

   render() {

      return (
         <DayPicker
            showOutsideDays 
            months={['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro',]}
            weekdaysShort={['Dom','Seg','Ter','Qua','Qui','Sex','Sab']}
            selectedDays={this.props.datas}
            onDayClick={this.props.handleDayClick}
         />
      );
   }
}

export default Calendar;
